import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import users from 'redux/reducers/users/reducer';
import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import error from 'redux/reducers/errors/reducer';
import settings from 'redux/reducers/settings/reducer';
import popUp from 'redux/reducers/popUp/reducer';
import twoFa from 'redux/reducers/twoFa/reducer';
import transactions from 'redux/reducers/transactions/reducer';
import confirmation from 'redux/reducers/confirmation/reducer';
import bankAccounts from 'redux/reducers/bankAccounts/reducer';
import walletAddresses from 'redux/reducers/walletAddresses/reducer';
import fee from 'redux/reducers/fee/reducer';
import referrals from 'redux/reducers/referrals/reducer';
import transactionsFilters from 'redux/reducers/transactionsFilters/reducer';
import currency from 'redux/reducers/currency/reducer';
import sidebarStates from 'redux/reducers/sidebarStates/reducer';
import walletBalance from 'redux/reducers/walletBalance/reducer';
import dashboard from 'redux/reducers/dashboard/reducer';
import admins from 'redux/reducers/admins/reducer';
import download from 'redux/reducers/download/reducer';
import detailedView from 'redux/reducers/detailedView/reducer';
import messages from 'redux/reducers/messages/reducer';

// ==========================================:
const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		app,
		auth,
		error,
		settings,
		popUp,
		twoFa,
		transactions,
		users,
		confirmation,
		bankAccounts,
		walletAddresses,
		fee,
		referrals,
		transactionsFilters,
		currency,
		sidebarStates,
		walletBalance,
		dashboard,
		admins,
		download,
		detailedView,
		messages,
	});

export default createRootReducer;
