import { store } from 'redux/store';
import { getAuth } from 'redux/reducers/auth/selectors';
import { refreshTokenRequest } from 'redux/reducers/auth/reducer';

const getFormatTime = (plusHours = 0) => {
	const date = new Date();
	const UTCMonth = date.getUTCMonth() + 1;
	const UTCHours = date.getUTCHours() + plusHours;
	return `${date.getUTCFullYear()}-${UTCMonth}-${date.getUTCDate()} ${UTCHours}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
};

export const getTokenRefreshTime = () => {
	return getFormatTime(1);
};

const matchFormatTime = () => {
	const state = store.getState();
	const auth = getAuth(state);
	const date = auth.tokenRefreshTime as string;
	return (
		auth && auth.accessToken && new Date(getFormatTime()) > new Date(date) && !auth.tokenUpdating
	);
};

export const checkAndRefreshToken = () => {
	matchFormatTime() && store.dispatch(refreshTokenRequest());
};
