// import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const WalletBalanceTabs = () => {
	// const { url } = useRouteMatch();

	return (
		<div className="tabs-buttons-nav tabs-buttons-nav--liquidity-management">
			<NavLink to={ROUTES.walletsManagement.LIQUIDITY}>Liquidity</NavLink>
			<NavLink to={ROUTES.walletsManagement.PLATFORM_BALANCES}>Platform Balance</NavLink>
			<NavLink to={ROUTES.walletsManagement.DEPOSIT_WALLETS}>Deposit Wallets</NavLink>
			<NavLink to={ROUTES.walletsManagement.PENDING_FEES}>Pending Fees</NavLink>
			<NavLink to={ROUTES.walletsManagement.USER_BALANCES}>User Balances</NavLink>
		</div>
	);
};

export default WalletBalanceTabs;
