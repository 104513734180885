/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IUserDataFees, IUserFeesUpdteBody } from 'redux/reducers/users/types';
import { PencilSmallIcon, CheckIcon, CloseIcon } from 'assets/inline-svg';
import { numberInputFix } from 'services/utils/numberInputFix';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { toMaxDecimals } from 'services/utils/numbers';
import { roundingNumber } from '../../../../../../services/utils/roundingNumber';

export interface IFeeAssetItem {
	asset_id: number;
	deposit_fee_percent: number | string;
	deposit_fee_fixed: number | string;
}
export interface IFeeManagementDepositItem {
	el: IUserDataFees;
	updateHandler: (obj: IFeeAssetItem) => void;
}

const FeeManagementDepositItem: FC<IFeeManagementDepositItem> = ({ el, updateHandler }) => {
	const { code, fee, type } = el;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		deposit_fee_percent: fee.deposit_fee_percent || null || '',
		deposit_fee_fixed: fee.deposit_fee_fixed || null || '',
	};

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_id: fee.asset_id,
							deposit_fee_percent: Number(values?.deposit_fee_percent),
							deposit_fee_fixed: Number(values?.deposit_fee_fixed),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<div className="td__wrap">
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									<img src={`/img/currencies/${code}.svg`} alt={code} />
									<p>{code?.toUpperCase()}</p>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Fee %</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											type="number"
											onKeyDown={(event) => numberInputFix(event)}
											name="deposit_fee_percent"
											onChange={(event) =>
												handleFeeFixedChange(
													event,
													props.setFieldValue,
													'deposit_fee_percent',
													'percent',
												)
											}
											onBlur={props.handleBlur}
											onWheel={(event) => event.currentTarget.blur()}
											value={props?.values?.deposit_fee_percent}
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Fee Fixed</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											name="deposit_fee_fixed"
											onKeyDown={(event) => numberInputFix(event)}
											onChange={(event) =>
												handleFeeFixedChange(event, props.setFieldValue, 'deposit_fee_fixed', type)
											}
											onBlur={props.handleBlur}
											onWheel={(event) => event.currentTarget.blur()}
											value={props.values.deposit_fee_fixed}
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon-danger btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon-success btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
							<img src={`/img/currencies/${code}.svg`} alt={code} />
							<p>{code?.toUpperCase()}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee %</p>
						<div className="td__wrap">
							<p>{fee.deposit_fee_percent}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee Fixed</p>
						<div className="td__wrap">
							<p>{roundingNumber(fee.deposit_fee_fixed, code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={switchOnChangeableMode}
								className="btn--icon btn--edit"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default FeeManagementDepositItem;
