import { ChangeEvent, useRef, useState, FC, useLayoutEffect, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useDebounce } from 'hooks/useDebounce';
import useClickOutside from 'hooks/useClickOutside';
import { ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersRequest } from 'redux/reducers/users/reducer';
import { getUsersData } from 'redux/reducers/users/selectors';
import { IUser } from 'redux/reducers/users/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import useOutsideClick from 'hooks/useOutsideClick';

interface IUserSearch {
	handleChangeUserId: (userId: string) => void;
	resetForm?: boolean;
	handleResetForm?: () => void | undefined;
}

interface IFiltersSearchArrayItem {
	name: string;
	value: ETransactionsFilters;
}

const FiltersSearchArray: IFiltersSearchArrayItem[] = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
];

const UserSearch: FC<IUserSearch> = ({ handleChangeUserId, resetForm, handleResetForm }) => {
	const dispatch = useDispatch();
	const users = useSelector(getUsersData);
	const [filteredUsers, setFilteredUsers] = useState<any>();
	// console.log(users);

	const searchEnable = useRef(false);
	const searchDropdownRef = useRef(null);
	const optionSelectDropdownRef = useRef(null);
	const inputWrapperRef = useRef<HTMLDivElement | null>(null);

	const handleFilterOptionSelectArray = (newOptionSelectValue: IFiltersSearchArrayItem) => {
		return FiltersSearchArray.filter(({ value }) => value !== newOptionSelectValue.value);
	};

	const [optionSelectOpened, setOptionSelectOpened] = useState(false);
	const [optionSelectValue, setOptionSelectValue] = useState(FiltersSearchArray[0]);
	const [optionSelectArray, setOptionSelectArray] = useState(
		handleFilterOptionSelectArray(FiltersSearchArray[0]),
	);
	const [searchDropdownOpened, setSearchDropdownOpened] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [selectedUser, setSelectedUser] = useState('');
	const [errorText, setErrorText] = useState('');

	useEffect(() => {
		if (users?.data) {
			setFilteredUsers(
				users?.data.filter((user) => {
					return Number(user.blocked) === 0 && Number(user.is_archived) === 0;
				}),
			);
		}
	}, [users?.data]);

	useLayoutEffect(() => {
		if (resetForm && handleResetForm) {
			setSearchValue('');
			handleResetForm();
		}
	}, [resetForm, handleResetForm]);

	useLayoutEffect(() => {
		dispatch(
			getUsersRequest({
				permission: true,
			}),
		);
	}, [dispatch]);

	const handleToggleOptionSelect = () => setOptionSelectOpened(!optionSelectOpened);
	const handleCloseOptionSelect = () => setOptionSelectOpened(false);

	useOutsideClick(inputWrapperRef, () => {
		setSearchValue(selectedUser);
		if (!selectedUser) setErrorText('Please, select User!');
	});

	useClickOutside(optionSelectDropdownRef, handleCloseOptionSelect);
	const handleOpenSearchDropdown = () => setSearchDropdownOpened(true);
	const handleCloseSearchDropdown = () => setSearchDropdownOpened(false);
	useClickOutside(searchDropdownRef, handleCloseSearchDropdown);

	const handleChanOptionSelectValue = (newOptionSelectValue: IFiltersSearchArrayItem) => {
		searchEnable.current = false;
		const newFiltersSearchArray = handleFilterOptionSelectArray(newOptionSelectValue);
		handleToggleOptionSelect();
		setOptionSelectArray(newFiltersSearchArray);
		setOptionSelectValue(newOptionSelectValue);
		setSearchValue('');
		setSelectedUser('');
	};

	const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const handleSearch = (value: string) => {
		// if (searchEnable.current && value) {
		if (value) {
			dispatch(
				getUsersRequest({
					// search_field: optionSelectValue.value,
					// search_value: value,
					[optionSelectValue.value]: value,
					permission: true,
				}),
			);
			// handleOpenSearchDropdown();
		} else {
			dispatch(
				getUsersRequest({
					permission: true,
				}),
			);
		}
		searchEnable.current = true;
	};
	useDebounce(searchValue, 500, handleSearch);

	const handleChooseSearchItem = (user: IUser) => {
		searchEnable.current = false;
		!!handleChangeUserId && handleChangeUserId(user.id.toString());
		let initValue: string;
		switch (optionSelectValue.value) {
			case ETransactionsFilters.USER_ID: {
				// setSearchValue(user?.id?.toString());
				initValue = user?.id?.toString();
				break;
			}
			case ETransactionsFilters.ONEIFY_ID: {
				// setSearchValue(user?.id?.toString());
				initValue = user?.oneify_id?.toString() || '';
				break;
			}
			case ETransactionsFilters.EMAIL: {
				// setSearchValue(user?.email);
				initValue = user?.email;
				break;
			}
			case ETransactionsFilters.COMPANY_NAME: {
				// setSearchValue(user?.data?.company_name);
				initValue = user?.data?.company_name;
				break;
			}
			case ETransactionsFilters.FULL_NAME: {
				// setSearchValue(user?.data?.first_name);
				initValue = `${user?.data?.first_name} ${user?.data?.last_name}`;
				break;
			}
			default: {
				// setSearchValue(user?.id?.toString());
				initValue = user?.id?.toString();
				break;
			}
		}

		setSearchValue(initValue);
		setSelectedUser(initValue);
		setErrorText('');

		handleCloseSearchDropdown();
	};

	return (
		<div ref={inputWrapperRef} className="search-input-wrapper">
			<div className="input input--user-search input--mb-0">
				<div className="input-wrapper">
					<div
						ref={optionSelectDropdownRef}
						className={cn('select', { active: optionSelectOpened })}
					>
						<button type="button" className="select__current " onClick={handleToggleOptionSelect}>
							{optionSelectValue?.name || ''}
							<span className="select__current-arrow">
								<svg
									width="9"
									height="5"
									viewBox="0 0 9 5"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1.5 1L4.5 4L7.5 1" stroke="#173B58" strokeLinecap="square" />
								</svg>
							</span>
						</button>
						<div className="select__drop select__drop--transaction">
							<div className={cn('select__drop-scroll', { active: searchDropdownOpened })}>
								<div className="select__drop-item">
									<ul>
										{!!optionSelectArray?.length &&
											optionSelectArray.map((el) => (
												<li key={el.value}>
													<button onClick={() => handleChanOptionSelectValue(el)} type="button">
														{el.name}
													</button>
												</li>
											))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<input
						type="text"
						value={searchValue}
						onChange={handleSearchValueChange}
						onClick={handleOpenSearchDropdown}
						className="input-item input-item--type2 input-item--pr50"
						placeholder={optionSelectValue ? `Search by ${optionSelectValue?.name}` : 'Search...'}
					/>
					<div className="input-icon input-icon--auto input-icon--right">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								opacity="0.5"
								d="M23.1998 23.2008L16.7998 16.8008M10.3998 20.0008C5.09787 20.0008 0.799805 15.7027 0.799805 10.4008C0.799805 5.09885 5.09787 0.800781 10.3998 0.800781C15.7017 0.800781 19.9998 5.09885 19.9998 10.4008C19.9998 15.7027 15.7017 20.0008 10.3998 20.0008Z"
								stroke="#BFBFBF"
							/>
						</svg>
					</div>
				</div>
				<div
					ref={searchDropdownRef}
					className={cn('user-search__dropdown', {
						active: searchDropdownOpened,
					})}
				>
					<div className="select__drop-scroll">
						{filteredUsers && filteredUsers?.length ? (
							filteredUsers.map((user: any) => (
								<div
									key={user?.id}
									className="user-search__dropdown-item"
									onClick={() => handleChooseSearchItem(user)}
								>
									<div className="dropdown-item__block">
										<div className="dropdown-item__key">User Id</div>
										<div className="dropdown-item__value">{user?.id || '-'}</div>
									</div>
									<div className="dropdown-item__block">
										<div className="dropdown-item__key">Email</div>
										<div className="dropdown-item__value">{user?.email || '-'}</div>
									</div>
									<div className="dropdown-item__block">
										<div className="dropdown-item__key">Full Name</div>
										<div className="dropdown-item__value">
											{user?.data?.first_name && user?.data?.last_name
												? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												  `${user?.data.first_name} ${user?.data.last_name}`
												: '-'}
										</div>
									</div>
									<div className="dropdown-item__block">
										<div className="dropdown-item__key">Company Name</div>
										<div className="dropdown-item__value">{user?.data?.company_name || '-'}</div>
									</div>
								</div>
							))
						) : (
							<TableBodyNoData />
						)}
					</div>
				</div>
				{errorText && (
					<div className="input-notify">
						<span className="input-notify__text">{errorText}</span>
					</div>
				)}
			</div>
		</div>
	);
};

UserSearch.defaultProps = { resetForm: false, handleResetForm: () => {} };

export default UserSearch;
