import React, { FC, useLayoutEffect, useMemo, useState } from 'react';

import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';

import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import { IUsersTableProps } from './types';
import AdminsTableHeader from './AdminsTableHeader';
import AdminsTableRow from './AdminsTableRow';
import TradeHistoryRow from '../../AccountDetails/TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import Loading from '../../../layouts-elements/Loading/Loading';

const AdminsTable: FC<IUsersTableProps & { permission: boolean }> = ({
	users,
	loading,
	pages,
	query,
	itemsPerPage,
	setItemsPerPage,
	setCurrentPage,
	permission,
}) => {
	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--admin-management">
						<AdminsTableHeader />
						<div className="table-body">
							{loading ? (
								<Loading />
							) : (
								<>
									{users?.length ? (
										users?.map((user: any) => (
											<AdminsTableRow
												key={user.id}
												user={user}
												query={query}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
						</div>

						<div className="table-footer">
							{!!users && (
								<div className="pagination-block pagination-block--full">
									<PerPageDropdown
										options={[10, 20, 50, 100]}
										setPerPage={setItemsPerPage}
										perPage={itemsPerPage}
									/>
									{/* {pages > 1 && (
										<ReactPaginate
											breakLabel="..."
											pageCount={pages}
											forcePage={Number(query.current_page) - 1}
											onPageChange={handlePageClick}
											className="pagination"
											activeClassName="active"
											previousClassName="arrow"
											nextClassName="arrow"
											previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
											nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
										/>
									)} */}
									<ReactPaginate
										breakLabel="..."
										pageCount={pages}
										forcePage={Number(query.current_page) - 1}
										onPageChange={handlePageClick}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel=""
										nextLabel=""
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminsTable;
