import React, { FC } from 'react';

import Popup from 'reactjs-popup';

import { IAdminStatusUpdaterProps } from './types';

const AdminStatusUpdater: FC<IAdminStatusUpdaterProps> = ({
	open,
	closeModal,
	event = 'block',
	onConfirm,
}) => {
	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">
						<span className="capitalize">{event}</span> Admin
					</p>
				</div>
				<div className="popup-body">
					<div className="popup-icon popup-icon--status">
						{event === 'block' ? (
							<>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="58"
									height="58"
									viewBox="0 0 58 58"
									fill="none"
								>
									<path
										d="M29 54C42.7591 54 54 42.7591 54 29C54 15.2409 42.7591 4 29 4C15.2409 4 4 15.2409 4 29C4 42.7591 15.2409 54 29 54Z"
										fill="#0071AB"
										stroke="#F1F7FC"
										strokeWidth="8"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}
								>
									<path
										d="M16.6667 9.16406H3.33333C2.8731 9.16406 2.5 9.53716 2.5 9.9974V17.4974C2.5 17.9576 2.8731 18.3307 3.33333 18.3307H16.6667C17.1269 18.3307 17.5 17.9576 17.5 17.4974V9.9974C17.5 9.53716 17.1269 9.16406 16.6667 9.16406Z"
										stroke="white"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M5.83398 9.16406V5.83073C5.83398 3.52954 7.69948 1.66406 10.0007 1.66406C12.3018 1.66406 14.1673 3.52954 14.1673 5.83073V9.16406"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10 12.5V15"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</>
						) : (
							<>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="58"
									height="58"
									viewBox="0 0 58 58"
									fill="none"
								>
									<path
										d="M29 54C42.7591 54 54 42.7591 54 29C54 15.2409 42.7591 4 29 4C15.2409 4 4 15.2409 4 29C4 42.7591 15.2409 54 29 54Z"
										fill="#007F00"
										stroke="#F0F7F0"
										strokeWidth="8"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}
								>
									<path
										d="M16.2503 9.1875H3.75033C3.29009 9.1875 2.91699 9.5606 2.91699 10.0208V17.5208C2.91699 17.9811 3.29009 18.3542 3.75033 18.3542H16.2503C16.7106 18.3542 17.0837 17.9811 17.0837 17.5208V10.0208C17.0837 9.5606 16.7106 9.1875 16.2503 9.1875Z"
										stroke="white"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M5.83301 9.16406V5.83269C5.83084 3.69332 7.46747 1.90039 9.6188 1.68538C11.7701 1.47038 13.7361 2.90326 14.1663 4.99985"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10 12.5V15"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</>
						)}
					</div>
					<div className="popup-text popup-text--type2 popup-text--center">
						<p>Are you sure you want to {event} the admin?</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={onConfirm}>
							Confirm
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AdminStatusUpdater;
