import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCryptoHistoryRequest } from 'redux/reducers/transactions/reducer';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import { getCryptoHistory, getCryptoHistoryPageCount } from 'redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';
import IconSvg from 'ui/Svg/IconSvg';
import ReactPaginate from 'react-paginate';
import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { getCryptoFilters } from 'redux/reducers/transactionsFilters/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import {
	STATUS_CRYPTO_FILTER_OPTIONS,
	STATUS_USER_CRYPTO_FILTER_OPTIONS,
	STATUS_USER_CRYPTO_WITHDRAWAL_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import ActiveReportsFilters from 'ui/ActiveFilters/ActiveReportsFilters';
import { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { ITransactionsTableSortedParams, THistoryFiltersOption } from './types';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import CryptoHistoryHeader from './CryptoHistoryHeader';
import { typeOptions } from './utils';
import CryptoHistoryRow from './CryptoHistoryRow';
import SpreadManagementItem from '../../../FeeManagement/SpreadManagement/SpreadManagementItem/SpreadManagementItem';
import { EPermissionNames } from '../../../../redux/reducers/auth/types';
import Loading from '../../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [{ name: 'Tx ID', value: ETransactionsFilters.TX_ID }];

interface ICryptoHistoryProp {
	type: string;
}
const CryptoHistory: FC<ICryptoHistoryProp> = ({ type }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const cryptoHistoryList = useSelector(getCryptoHistory);
	const pageCount = useSelector(getCryptoHistoryPageCount);
	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const activeFilters = useSelector(getCryptoFilters);

	const [reset, setReset] = useState(false);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [typeOption, setTypeOption] = useState(
		typeOptions.filter((option) => option.value === type)[0],
	);
	const [firstLoading, setFirstLoading] = useState(true);
	const [itemsPerPage, setItemsPerPage] = useState<number | string>(10);

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	useLayoutEffect(() => {
		setTypeOption(typeOptions.filter((option) => option.value === type)[0]);
	}, [type]);

	useLayoutEffect(() => {
		const sortedParamsObj = sorted ? { [`${String(sorted?.type)}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				userId,
				current_page: currentPage,
				per_page: Number(itemsPerPage),
				type: typeOption.value,
				...filterParams,
				...sortedParamsObj,
			},
			onFinally: () => setFirstLoading(false),
		};
		dispatch(getCryptoHistoryRequest(params));
	}, [sorted, currentPage, dispatch, typeOption, userId, itemsPerPage, activeFilters]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
		};
	}, [dispatch]);

	const handleChangePerPage = (value: string | number) => {
		setCurrentPage(Math.ceil(cryptoHistoryList.from / Number(value)));
		setItemsPerPage(value);
	};

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleResetFilters = () => {
		setReset(true);
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
	};

	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.STATUS,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};
	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				value: { date_from: startDate, date_to: endDate },
			}),
		);
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				}),
			);
		}
	};

	return (
		<div className="transfer-history-wrapper">
			<ActiveReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={cryptoCurrencyFilterOptions}
				statusOptions={
					type === 'deposit'
						? STATUS_USER_CRYPTO_FILTER_OPTIONS
						: STATUS_USER_CRYPTO_WITHDRAWAL_FILTER_OPTIONS
				}
				handleStatusChange={handleStatusChange}
				reset={reset}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				type="user-history"
			/>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--type2 table--deposit-history-crypto">
						<div className="table-header">
							<CryptoHistoryHeader setSorted={setSorted} />
						</div>
						<div className="table-body">
							{!firstLoading && (
								<>
									{' '}
									{cryptoHistoryList?.data?.length ? (
										cryptoHistoryList?.data?.map((e: any) => (
											<CryptoHistoryRow key={e.id} item={e} type={type} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{firstLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!cryptoHistoryList?.data?.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageDropdown
								options={[10, 20, 50, 100]}
								setPerPage={handleChangePerPage}
								perPage={Number(itemsPerPage)}
							/>
							{pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CryptoHistory;
