import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as EmailIcon } from 'assets/img/admin/login/email.svg';
import { ReactComponent as KeyIcon } from 'assets/img/admin/login/key.svg';
import { ReactComponent as PadlockIcon } from 'assets/img/admin/login/padlock.svg';
import { ReactComponent as OkIcon } from 'assets/img/admin/login/ok.svg';
import { Link } from 'react-router-dom';
import { navListBeforeLogin } from 'routes/routesList';
import { ILoginSubmitValue } from './types';
import { notificationsMessagesError } from '../../services/utils/notificationsMessages/notificationsMessagesError';
import { notificationContainer } from '../../services/utils/notificationContainer';
import InputTotp from '../../ui/Formik/InputTotp';

const LoginForm: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [isShowPass, setIsShowPass] = useState(false);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};
	const onSubmit = (obg: ILoginSubmitValue) => {
		const offset = -(new Date().getTimezoneOffset() / 60);
		if (!reCaptchaKey) {
			notificationContainer('Please complete the captcha field.', 'user_blocked', 'Captcha Error');
		} else {
			dispatch(
				loginRequest({
					data: {
						...obg,
						time_zone: offset,
						captcha: reCaptchaKey || '',
						remember: obg.remember || undefined,
					},
					history,
				}),
			);
		}
	};
	const initialValues = {
		email: '',
		password: '',
		totp: '',
		remember: false,
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('Please enter your email.')
			.email('Please enter a valid email address.')
			.max(60, 'Email Address is too long. Maximum 60 characters.'),
		totp: yup.string().max(6, 'Please enter 6 character 2FA code.'),
		password: yup
			.string()
			.required('Please enter your password.')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(
					'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
				),
			)
			.max(25, String('Password is too long. Maximum 25 characters.')),
	});
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ILoginSubmitValue, { resetForm, setSubmitting }) => {
				onSubmit(values);
				setSubmitting(false);
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form className="form">
					<div className="login-form-item">
						<div className="input">
							<Field
								required
								name="email"
								type="email"
								placeholder="Email"
								component={Input}
								title="Email"
								// fieldIcon={<EmailIcon />}
							/>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input">
							<Field
								required
								name="password"
								type={isShowPass ? 'text' : 'password'}
								ariaLabel="show-password"
								placeholder="Password"
								component={Input}
								title="Password"
								// fieldIcon={<KeyIcon />}
								isShowPass={isShowPass}
								setIsShowPass={setIsShowPass}
								link="/reset-password"
								linkTitle="Forgot Password?"
							/>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input">
							<Field
								required
								name="totp"
								type="text"
								placeholder="Code"
								component={InputTotp}
								className="input-item--pr-125"
								title="Authentication Code"
								// fieldIcon={<PadlockIcon />}
								// fieldIconClassName="input-icon--bigger"
								link={navListBeforeLogin.resetTwoFaPage.path}
								linkTitle="Reset 2FA Code"
							/>
						</div>
					</div>
					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
						/>
					</div>
					<div className="login-form-item">
						<button
							type="submit"
							aria-label="form-submit"
							className="btn btn-primary btn--full"
							disabled={!(isValid && dirty) || isSubmitting}
						>
							Login
						</button>
					</div>
					{/* <div className="authorization-form__body">
						<div className="cred-remember-block">
							<div className="checkbox checkbox--mb-0">
								<label className="checkbox__label">
									<Field type="checkbox" name="remember" className="hidden" />
									<div className=" checkbox__item">
										<span className="checkbox__item-icon">
											<OkIcon />
										</span>
									</div>
									<p className="checkbox__text">Remember me</p>
								</label>
							</div>
							<Link to={navListBeforeLogin.resetPassword.path} className="link">
								Forgot password?
							</Link>
						</div>
					</div> */}
				</Form>
			)}
		</Formik>
	);
};

export default LoginForm;
