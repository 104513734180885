/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getDepositWalletsUserRequest } from 'redux/reducers/walletBalance/reducer';
import {
	getWalletsUserBalancesDepositWallets,
	getWalletsUserBalancesDepositWalletsLoading,
} from 'redux/reducers/walletBalance/selectors';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import IconSvg from 'ui/Svg/IconSvg';
import Item from './Item/Item';
import Filters from '../Filters/Filters';
import PerPageDropdown from '../../../../../services/utils/PaginationPerPageDropdown';
import TableBodyNoData from '../../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../../layouts-elements/Loading/Loading';
import { currencyDataRequest } from '../../../../../redux/reducers/currency/reducer';

export interface ILocalFilter {
	value: string;
	state: number;
}
const Crypto = () => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletsUserBalancesDepositWallets);
	const loading = useSelector(getWalletsUserBalancesDepositWalletsLoading);

	const permission = useSelector(getPermissions)?.[EPermissionNames.WALLET_MANAGEMENT].editable;
	const lastPage = item?.last_page || 1;
	const totalPage = item?.total || 1;
	const perPage = 10;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);
	const [currentPage, setCurrentPage] = useState(1);
	// useLayoutEffect(() => {
	// 	const params: any = {
	// 		apiParams: {
	// 			per_page: perPage,
	// 			current_page: 1,
	// 			// currency: 'crypto',
	// 		},
	// 		//
	// 		// onFinally: () => setFirstLoading(false),
	// 	};
	// 	console.log(1);
	// 	dispatch(getDepositWalletsUserRequest(params));
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	const filterActiveUp = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};

	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});
	const changeFilter = (changeFilterValue: string) => {
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};

	const [filters, setFilters] = useState({
		per_page: itemsPerPage,
		currency: undefined,
		current_page: currentPage,
	});
	const [search, setSearch] = useState({
		current_page: currentPage,
		per_page: itemsPerPage,
		search_field: 'user_id',
		search_value: undefined,
	});

	useLayoutEffect(() => {
		const params: any = {
			apiParams: {
				type: 'crypto',
				per_page: itemsPerPage,
				current_page: currentPage,
				currency: filters.currency,
				[search.search_field]: search.search_value,
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getDepositWalletsUserRequest(params));
	}, [dispatch, currentPage, itemsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps
	useLayoutEffect(() => {
		const params: any = {
			apiParams: {
				type: 'crypto',
				per_page: itemsPerPage,
				current_page: 1,
				currency: filters.currency,
				[search.search_field]: search.search_value,
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getDepositWalletsUserRequest(params));
	}, [dispatch, search, filters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	// useLayoutEffect(() => {
	// 	console.log(item);
	// }, [item]);

	const setItemsPerPageHandler = (num: number | string) => {
		setCurrentPage(Math.ceil(item.from / Number(num)));
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<Filters
				filters={filters}
				onChange={setFilters}
				setSearch={setSearch}
				type="crypto"
				setCurrentPage={setCurrentPage}
				includeOneifyId
			/>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table-walletBalance--deposit-wallet-user">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('id');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											User ID
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('id')}`} />
												<div className={`arrow-top ${filterActiveUp('id')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('first_name');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Full name
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('first_name')}`} />
												<div className={`arrow-top ${filterActiveUp('first_name')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Email</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('company_name');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Company name
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('company_name')}`} />
												<div className={`arrow-top ${filterActiveUp('company_name')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('name');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Currency
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('name')}`} />
												<div className={`arrow-top ${filterActiveUp('name')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('balance');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Balance
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('balance')}`} />
												<div className={`arrow-top ${filterActiveUp('balance')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td" />
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{item?.data?.length ? (
										<Item
											itemList={item?.data}
											localFilter={localFilter}
											permission={!!permission}
										/>
									) : (
										<TableBodyNoData />
									)}
								</>
							)}

							{loading ? <Loading /> : null}
						</div>
						<div className="table-footer">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={setItemsPerPageHandler}
									perPage={itemsPerPage}
								/>
								<ReactPaginate
									breakLabel="..."
									pageCount={Math.ceil(totalPage / itemsPerPage)}
									forcePage={currentPage - 1}
									onPageChange={({ selected }) => setCurrentPage(selected + 1)}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="pagination-block">
				{lastPage > 1 && (
					<ReactPaginate
						breakLabel="..."
						pageCount={lastPage}
						forcePage={currentPage - 1}
						onPageChange={({ selected }) => setCurrentPage(selected + 1)}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				)}
			</div> */}
		</>
	);
};
export default Crypto;
