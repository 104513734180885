import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/walletBalance/reducer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { getAssets } from '../../redux/reducers/walletBalance/selectors';

export interface ITradeInput {
	onSelectChange: (value: string | undefined) => void;
	resetSelected: boolean;
	type?: 'crypto' | 'fiat' | null;
	selectTitle?: string;
	disableSearch?: boolean;
	withIcons?: boolean;
}

const CryptocurrencySelect: React.FC<ITradeInput> = ({
	onSelectChange,
	resetSelected,
	type,
	selectTitle,
	disableSearch,
	withIcons,
}) => {
	const dispatch = useDispatch();
	const currency = useSelector(getAssets);
	const [selectValue, setSelectValue] = useState('All');
	const currencyList = type
		? currency.filter((el: any) =>
				type === 'crypto' ? el.type === type || el.type === 'token' : el.type === type,
		  )
		: currency;

	useLayoutEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);
	const selectRef = useRef<HTMLDivElement | null>(null);

	const [selectList, setSelectList] = useState([{ id: 0, code: 'All' }]);
	const [selectOpen, setSelectOpen] = useState(false);
	const searchInSelect = (val: string) => {
		const filteredSelectList = currencyList?.filter(({ code }: any) =>
			code.includes(val.toLowerCase()),
		);
		setSelectList([{ id: 0, code: 'All' }, ...filteredSelectList]);
	};
	const selectChangeHandler = (value: string, id: number) => {
		setSelectValue(value);
		setSelectOpen(false);
		!!onSelectChange && onSelectChange(value === 'All' ? undefined : value);
	};

	useLayoutEffect(() => {
		setSelectValue('All');
		setSelectOpen(false);
		!!onSelectChange && onSelectChange(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetSelected]);

	useLayoutEffect(() => {
		setSelectList([{ id: 0, code: 'All' }, ...currencyList]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency]);

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setSelectOpen(false);
			}
		};
		if (selectOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [selectOpen]);
	return (
		<div className="selectedCurrency">
			<div className="select-block" ref={selectRef}>
				<p className="select-block__name">{selectTitle || 'Cryptocurrency'}</p>
				<div className={`select select--type3 ${selectOpen ? 'active' : ''}`}>
					<button
						type="button"
						className="select__current element---capitalize"
						onClick={() => setSelectOpen(!selectOpen)}
					>
						{withIcons && selectValue !== 'All' ? (
							<span className="select__current-text">
								<img src={`/img/currencies/${selectValue.toLowerCase()}.svg`} alt={selectValue} />
								<p>{selectValue === 'All' ? 'All' : selectValue.toUpperCase()}</p>
							</span>
						) : (
							<>{selectValue === 'All' ? 'All' : selectValue.toUpperCase()}</>
						)}
						<span className="select__current-arrow" />
					</button>
					<AnimatePresence>
						{selectOpen && (
							<motion.div
								className="select__drop select__drop---top"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { duration: 0.3 } }}
								exit={{ opacity: 0, transition: { duration: 0.3 } }}
							>
								{!disableSearch && (
									<div className="input">
										<div className="input-wrapper">
											<input
												className="input-item"
												type="text"
												placeholder=""
												onChange={(e) => searchInSelect(e.target.value)}
											/>
											<div className="input-icon input-icon--right">
												<svg
													width="20"
													height="21"
													viewBox="0 0 20 21"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M1.04199 9.24984C1.04199 4.99266 4.49315 1.5415 8.75033 1.5415C13.0075 1.5415 16.4587 4.99266 16.4587 9.24984C16.4587 13.507 13.0075 16.9582 8.75033 16.9582C4.49315 16.9582 1.04199 13.507 1.04199 9.24984ZM8.75033 2.7915C5.1835 2.7915 2.29199 5.68302 2.29199 9.24984C2.29199 12.8167 5.1835 15.7082 8.75033 15.7082C12.3171 15.7082 15.2087 12.8167 15.2087 9.24984C15.2087 5.68302 12.3171 2.7915 8.75033 2.7915Z"
														fill="#9D9DBC"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.95065 6.03433C6.66631 5.31872 7.65675 4.875 8.74962 4.875C9.84249 4.875 10.8329 5.31872 11.5486 6.03435C11.7926 6.27843 11.7926 6.67416 11.5486 6.91823C11.3045 7.16231 10.9088 7.16231 10.6647 6.91823C10.1739 6.42745 9.49767 6.125 8.74962 6.125C8.00158 6.125 7.32534 6.42744 6.8345 6.91825C6.59042 7.16232 6.19469 7.1623 5.95062 6.91822C5.70655 6.67413 5.70657 6.2784 5.95065 6.03433Z"
														fill="#9D9DBC"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M13.4008 13.9003C13.6449 13.6563 14.0406 13.6563 14.2847 13.9003L17.8203 17.4359C18.0643 17.68 18.0643 18.0757 17.8203 18.3198C17.5762 18.5638 17.1805 18.5638 16.9364 18.3198L13.4008 14.7842C13.1568 14.5401 13.1568 14.1444 13.4008 13.9003Z"
														fill="#9D9DBC"
													/>
												</svg>
											</div>
										</div>
									</div>
								)}
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul>
											{!!selectList?.length &&
												selectList.map(({ id, code }) => (
													<li key={id}>
														<button
															className="element---uppercase"
															onClick={() => selectChangeHandler(code, id)}
															type="button"
														>
															{withIcons && code !== 'All' ? (
																<>
																	<img
																		src={`/img/currencies/${code.toLowerCase()}.svg`}
																		alt={code}
																	/>
																	{code.toUpperCase()}
																</>
															) : (
																code.toUpperCase()
															)}
														</button>
													</li>
												))}
										</ul>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};

export default CryptocurrencySelect;
