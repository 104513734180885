import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IAdminsApi } from './types';

// ==========================================:
export const admins: IAdminsApi = {
	getAdmins: (payload) =>
		http.get(endpoint.admins.ADMINS(null), { params: payload }).then(({ data }) => data),
	createAdmin: (payload) =>
		http.post(endpoint.admins.ADMINS(null), payload).then(({ data }) => data),
	getAdminPermissions: () => http.get(endpoint.admins.PERMISSIONS).then(({ data }) => data),
	deleteAdmin: (payload) => http.delete(endpoint.admins.ADMINS(payload)).then(({ data }) => data),
	updateAdmin: (payload) =>
		http.put(endpoint.admins.ADMINS(payload.id), payload.obj).then(({ data }) => data),
	changeAdminStatus: (payload) =>
		http.post(endpoint.admins.CHANGE_ADMIN_STATUS(payload)).then(({ data }) => data),
	getAllPermissions: () => http.get(endpoint.admins.PERMISSIONS).then(({ data }) => data),
};
