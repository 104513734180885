import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import * as yup from 'yup';

import { ReactComponent as KeyIcon } from 'assets/img/admin/login/key.svg';
import { IResetPasswordValue } from 'redux/reducers/auth/types';
import { useDispatch } from 'react-redux';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { ROUTES } from 'routes/constants';

const LoginFirst = () => {
	const dispatch = useDispatch();
	const [isShowPass, setIsShowPass] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const onSubmit = (obg: IResetPasswordValue) => {
		// dispatch(popUpOpen('CreatePasswordSuccess'));
		if (location.search) {
			history.push(ROUTES.firstLogin2fa, { ...obg, token: location.search.slice(1) });
		}
	};

	const initialValues = {
		password: '',
		c_password: '',
	};

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required('Please, enter your password')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(
					'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
				),
			)
			.max(25, String('Password is too long. Maximum 25 characters.')),
		c_password: yup
			.string()
			.required('Passwords must match')
			.oneOf([yup.ref('password'), null], String('Passwords must match!'))
			.max(25, String('Password is too long. Maximum 25 characters.')),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm, setSubmitting }) => {
				onSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form className="form">
					<div className="login-form-item">
						<Field
							required
							name="password"
							type={isShowPass ? 'text' : 'password'}
							ariaLabel="show-password"
							placeholder="Enter your password"
							component={Input}
							title="Password"
							isShowPass={isShowPass}
							setIsShowPass={setIsShowPass}
						/>
					</div>
					<div className="login-form-item mb-30">
						<Field
							required
							name="c_password"
							type={isShowPass ? 'text' : 'password'}
							ariaLabel="show-password"
							placeholder="Enter your password"
							component={Input}
							title="Confirm password"
							isShowPass={isShowPass}
							setIsShowPass={setIsShowPass}
						/>
					</div>
					<div className="login-form-item mb-0">
						<button
							type="submit"
							aria-label="form-submit"
							className="btn btn-primary btn--full"
							disabled={!isValid || !dirty}
						>
							Confirm
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default LoginFirst;
