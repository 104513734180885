// import ResetRequestItem from 'components/ResetRequest/ResetRequestItem';
// import UserResetRequestsTabs from 'components/ResetRequest/UserResetRequestTabs/UserResetRequestsTabs';
import { FC } from 'react';
// import ReactPaginate from 'react-paginate';
// import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
// import { getRequests } from 'redux/reducers/users/selectors';
import { ROUTES } from 'routes/constants';
// import IconSvg from 'ui/Svg/IconSvg';
import UserPasswordTab from './Password';
import UserTwoFaTab from './TwoFa';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}

const UsersTable: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	// const requests = useSelector(getRequests);
	return (
		<>
			<div className="table-block table-block--relative">
				<div className="table-wrapper">
					<Switch>
						<Route
							exact
							path={ROUTES.UserResetRequest.twofa}
							// component={UserTwoFaTab}
							render={() => (
								<UserTwoFaTab
									handlePageChange={handlePageChange}
									setItemsPerPageHandler={setItemsPerPageHandler}
									itemsPerPage={itemsPerPage}
									page={page}
								/>
							)}
						/>
						<Route
							exact
							path={ROUTES.UserResetRequest.password}
							render={() => (
								<UserPasswordTab
									handlePageChange={handlePageChange}
									setItemsPerPageHandler={setItemsPerPageHandler}
									itemsPerPage={itemsPerPage}
									page={page}
								/>
							)}
						/>
						{/* <Redirect to={ROUTES.UserResetRequest.twofa} /> */}
					</Switch>
				</div>
			</div>
			{/* {requests?.last_page !== 1 && (
				<div className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={requests?.last_page || 1}
						onPageChange={handlePageChange}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default UsersTable;
