import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { EWithdrawalLimitType, IApiGetTransactionParams } from 'services/api/transactions/types';
import {
	getWithdrawalGeneralLimit,
	getWithdrawalLimits,
	getWithdrawalLimitsLoading,
} from 'redux/reducers/transactions/selectors';
import {
	getCryptoWithdrawalLimitsRequest,
	getFiatWithdrawalLimitsRequest,
	getGeneralWithdrawalLimitRequest,
	putGeneralWithdrawalLimitRequest,
} from 'redux/reducers/transactions/reducer';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
// import IconSvg from 'ui/Svg/IconSvg';
// import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import TransfersLimitsRow from './TransfersLimitsRow/TransfersLimitsRow';
import Loading from '../../../../layouts-elements/Loading/Loading';
// import TradesLimitsRow from '../../TradesLimitsTab/TradesLimitsTable/TradesLimitsRow/TradesLimitsRow';

export interface ITransfersLimitsTable {
	type: EWithdrawalLimitType;
}

const TransfersLimitsTable: FC<ITransfersLimitsTable> = ({ type }) => {
	const dispatch = useDispatch();
	const withdrawalLimits = useSelector(getWithdrawalLimits);
	const loading = useSelector(getWithdrawalLimitsLoading);
	const generalWithdrawalLimits = useSelector(getWithdrawalGeneralLimit);
	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;

	const [currentPage, setCurrentPage] = useState(withdrawalLimits?.current_page || 1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || withdrawalLimits?.total);
		setCurrentPage(1);
	};
	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};
	const pageCount = withdrawalLimits?.last_page ? withdrawalLimits.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withdrawalLimits?.last_page]);

	useLayoutEffect(() => {
		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
		};
		type === EWithdrawalLimitType.CRYPTO && dispatch(getCryptoWithdrawalLimitsRequest(params));
		type === EWithdrawalLimitType.FIAT && dispatch(getFiatWithdrawalLimitsRequest(params));
		dispatch(getGeneralWithdrawalLimitRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const [editedGlobalLimit, setEditedGlobalLimit] = useState(0);
	const handleEditedGlobalLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedGlobalLimit(Number(event.target.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => {
		setEditedGlobalLimit(generalWithdrawalLimits?.value || 0);
		setEditMode(true);
	};
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		dispatch(
			putGeneralWithdrawalLimitRequest({
				withdraw_limit_total_eur: editedGlobalLimit,
			}),
		);
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedGlobalLimit(5000);
		disableEditMode();
	};

	return (
		<>
			{/* <div className="withdrawal-limits"> */}
			{/*	<div className="withdrawal-limits__info"> */}
			{/*		<p>Maximum Withdrawal</p> */}
			{/*		<p>No Approval Required:</p> */}
			{/*	</div> */}
			{/*	<div className="withdrawal-limits__date"> */}
			{/*		{editMode ? ( */}
			{/*			<div className="input withdrawal-limits__price-input"> */}
			{/*				<div className="input-wrapper number__input"> */}
			{/*					<input */}
			{/*						type="number" */}
			{/*						placeholder="0" */}
			{/*						className="input-item" */}
			{/*						value={editedGlobalLimit || ''} */}
			{/*						onChange={handleEditedGlobalLimit} */}
			{/*					/> */}
			{/*				</div> */}
			{/*			</div> */}
			{/*		) : ( */}
			{/*			<p>{generalWithdrawalLimits?.value}</p> */}
			{/*		)} */}
			{/*		<span className="withdrawal-limits__value">EUR</span> */}
			{/*		{!!permission && editMode && ( */}
			{/*			<div className="table-buttons flex-e"> */}
			{/*				<button type="button" className="withdrawal-limits__action" onClick={handleEdit}> */}
			{/*					<CheckIcon /> */}
			{/*				</button> */}
			{/*				<button */}
			{/*					type="button" */}
			{/*					className="withdrawal-limits__action" */}
			{/*					onClick={handleDisableEditMode} */}
			{/*				> */}
			{/*					<CloseIcon /> */}
			{/*				</button> */}
			{/*			</div> */}
			{/*		)} */}
			{/*		{!!permission && !editMode && ( */}
			{/*			<button type="button" className="withdrawal-limits__action" onClick={enableEditMode}> */}
			{/*				<PencilIcon /> */}
			{/*			</button> */}
			{/*		)} */}
			{/*	</div> */}
			{/* </div> */}
			<div className="table-block table-block--transactions-transfer-limits">
				<div className="table-wrapper">
					<div className="table table--type2 table--transactions-transfer-limits">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Minimum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Maximum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Hourly Limit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Daily Limit</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{withdrawalLimits?.data.length ? (
										withdrawalLimits?.data.map((el) => (
											<TransfersLimitsRow key={el.id} {...el} type={type} perm={!!permission} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
					</div>
				</div>

				{!!withdrawalLimits?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!withdrawalLimits && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TransfersLimitsTable;
