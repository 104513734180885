import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetOneMessageParams,
	IMessage,
	IMessageCreateRequestPayload,
	IMessageDeleteFilesRequestPayload,
	IMessageDeleteRequestPayload,
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessagesFilter,
	IMessageStore,
	IMessageUpdateRequestPayload,
	IMessageWithPagination,
} from './types';

export const initialState: IMessageStore = {
	messages: null,
	loading: false,
	messagesFilters: [],
	oneMessage: null,
	loadingFiles: false,
};

const messages = createSlice({
	name: '@@messages',
	initialState,
	reducers: {
		addFilter: (state, { payload }: PayloadAction<IMessagesFilter>) => {
			const newState = state;
			newState.messagesFilters = [...newState.messagesFilters, payload];
		},
		resetFilters: (state) => {
			const newState = state;
			newState.messagesFilters = [];
		},
		deleteFilter: (state, { payload }: PayloadAction<IMessagesFilter>) => {
			const newState = state;
			newState.messagesFilters = newState.messagesFilters.filter(
				(item) => item.type !== payload.type,
			);
		},
		getMessagesRequest: (state, action: PayloadAction<IMessageRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getMessagesSuccess: (state, action: PayloadAction<IMessageWithPagination>) => {
			const newState = state;
			newState.messages = action.payload;
			newState.loading = false;
		},
		getOneMessageRequest: (state, action: PayloadAction<IApiGetOneMessageParams>) => {
			const newState = state;
			newState.loading = true;
		},
		getOneMessageSuccess: (state, action: PayloadAction<IMessage>) => {
			const newState = state;
			newState.oneMessage = action.payload;
			newState.loading = false;
		},
		resetOneMessageState: (state) => {
			const newState = state;
			newState.oneMessage = null;
		},
		createMessageRequest: (state, action: PayloadAction<IMessageCreateRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		createMessageSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},
		createMessageError: (state) => {
			const newState = state;
			newState.loading = false;
		},
		deleteMessageRequest: (state, action: PayloadAction<IMessageDeleteRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		deleteMessageSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},
		updateMessageRequest: (state, action: PayloadAction<IMessageUpdateRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		updateMessageSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},
		updateMessageError: (state) => {
			const newState = state;
			newState.loading = false;
		},
		deleteFilesSuccess: (state) => {
			const newState = state;
			newState.loadingFiles = false;
		},
		deleteFilesRequest: (state, action: PayloadAction<IMessageDeleteFilesRequestPayload>) => {
			const newState = state;
		},
		downloadFilesSuccess: (state) => {
			const newState = state;
			newState.loadingFiles = false;
		},
		downloadFilesRequest: (state, action: PayloadAction<IMessageDownloadFilesRequestPayload>) => {
			const newState = state;
		},
		cloneMessageRequest: (state, action: PayloadAction<IMessageDeleteRequestPayload>) => {
			const newState = state;
			// newState.loading = true;
		},
		cloneMessageSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},
	},
});

export default messages.reducer;

export const {
	getMessagesRequest,
	getMessagesSuccess,
	addFilter,
	resetFilters,
	deleteFilter,
	getOneMessageRequest,
	getOneMessageSuccess,
	resetOneMessageState,
	createMessageRequest,
	createMessageSuccess,
	createMessageError,
	deleteMessageRequest,
	deleteMessageSuccess,
	updateMessageRequest,
	updateMessageSuccess,
	updateMessageError,
	deleteFilesRequest,
	deleteFilesSuccess,
	downloadFilesRequest,
	downloadFilesSuccess,
	cloneMessageRequest,
	cloneMessageSuccess,
} = messages.actions;
