/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Sector } from 'recharts';

import { roundingNumber } from 'services/utils/roundingNumber';
import { IRenderActiveShapeProps } from './types';

const ActiveShape = (props: IRenderActiveShapeProps) => {
	const { shapeProps, isLegend } = props;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		valueEuro,
		valueCrypto,
		name,
	} = shapeProps;

	const RADIAN = Math.PI / 180;

	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + outerRadius * cos;
	const sy = cy + outerRadius * sin;
	const mx = cx + (outerRadius + 10) * cos;
	const my = cy + (outerRadius + 10) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius + 9}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>

			{isLegend && (
				<>
					<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
					<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

					<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
						{roundingNumber(valueCrypto, name)} {name}
					</text>

					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={18}
						textAnchor={textAnchor}
						fill="#999"
					>
						{roundingNumber(valueEuro, 'EUR')} EUR
					</text>
				</>
			)}
		</g>
	);
};

export default ActiveShape;
