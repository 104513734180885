import { IDashboardApi } from './types';
import { http } from '../../http';
import { endpoint } from '../../endpoint';

// ==========================================:
// ==========================================:
export const dashboard: IDashboardApi = {
	platformBalances: (type) =>
		http.get(endpoint.dashboard.PLATFORM_BALANCE(type)).then((response) => {
			return response.data;
		}),
	depositBalances: () =>
		http.get(endpoint.dashboard.DEPOSIT_BALANCE).then((response) => {
			return response.data;
		}),
	profitBalances: () =>
		http.get(endpoint.dashboard.PROFIT_BALANCE).then((response) => {
			return response.data;
		}),
	poolBalances: () =>
		http.get(endpoint.dashboard.POOL_BALANCE).then((response) => {
			return response.data;
		}),
	withdrawBalances: () =>
		http.get(endpoint.dashboard.WITHDRAW_BALANCE).then((response) => {
			return response.data;
		}),
	b2c2Balances: (type) =>
		http.get(endpoint.dashboard.B2C2(type)).then((response) => {
			return response.data;
		}),
	pendingFee: (type) =>
		http.get(endpoint.dashboard.PENDING_FEE(type)).then((response) => {
			return response.data;
		}),
	liquidity: (type) =>
		http.get(endpoint.dashboard.LIQUIDITY(type)).then((response) => {
			return response.data;
		}),
	liquidity_dashboard: (type) =>
		http.get(endpoint.dashboard.LIQUIDITY_DASHBOARD(type)).then((response) => {
			return response.data;
		}),
};
