export interface IAddReferralProps {
	open: boolean;
	closeModal: () => void;
}

export enum SearchField {
	id = 'User ID',
	oneify_id = 'Oneify ID',
	name = 'Name',
}
