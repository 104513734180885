import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EApiCryptoTransactionStatus } from 'services/api/transactions/types';
import { getOneUserRequest } from 'redux/reducers/users/reducer';
import { getOneUser } from 'redux/reducers/users/selectors';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import Loading from 'layouts-elements/Loading/Loading';
import { useLocation } from 'react-router';

import { getCryptoHistory } from '../../../redux/reducers/transactions/selectors';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import DefaultFields from '../DefaultFields/DefaultFields';
import DetailedViewCryptoForm from '../DetailedCryptoForm/DetailedCryptoForm';
import { IStateLocation } from '../types';
import {
	getCryptoDetails,
	getDetailedViewLoading,
} from '../../../redux/reducers/detailedView/selectors';
import { getCryptoDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface IDeposiCryptoDetails {
	txId: string | number;
	userId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositCryptoDetails: FC<IDeposiCryptoDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	const currencyList = useSelector(getCurrencyData);
	const [asset, setAsset] = useState<ICurrencyDataItem | null>(null);
	// const loading = useSelector(getHistoryLoading);
	const loading = useSelector(getDetailedViewLoading);
	const { state } = useLocation<IStateLocation>();
	const currentItemData = useSelector(getCryptoHistory);
	const cryptoDetails = useSelector(getCryptoDetails);

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (txId && userId) {
			// const params: IGetTransactionHistoryRequestPayload = {
			// 	apiParams: {
			// 		userId,
			// 		type: EApiTransactionType.DEPOSIT,
			// 		current_page: 1,
			// 		per_page: 1,
			// 		id: Number(txId),
			// 	},
			// };
			// dispatch(getCryptoHistoryRequest(params));
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, txId]);

	useEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	useEffect(() => {
		cryptoDetails &&
			setAsset(currencyList.filter((item) => item.id === cryptoDetails?.asset_id)[0]);
	}, [cryptoDetails, currencyList, dispatch]);

	return (
		<>
			{cryptoDetails && !loading && userData ? (
				<div className="detailed-view" key={cryptoDetails.id}>
					<DetailedViewCryptoForm item={cryptoDetails} userData={userData} />
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default DepositCryptoDetails;
