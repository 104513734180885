import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getFeeStoreData } from '../../../../redux/reducers/fee/selectors';
/* eslint-disable @typescript-eslint/no-explicit-any */

const CurrenclesItem: FC<any> = ({ data, updateHandler, permission }) => {
	const fee = useSelector(getFeeStoreData);
	const id = data?.id;
	const { depositable, withdrawable, exchangeable } = data;
	const code = data?.code;

	const checkValue = (text: string, value: number) => {
		const newArr: number[] = [];
		fee?.dataCurrencies.map((item: any) => {
			if (item[text] === 1) {
				newArr.push(item[text]);
				return item.depositable;
			}
			return null;
		});

		if (newArr.length > 1) {
			updateHandler(
				{
					asset_id: id,
					data: {
						[text]: value === 0 ? 1 : 0,
					},
				},
				data?.name,
			);
		} else {
			if (fee?.dataCurrencies.find((item: any) => item.id === id)[text] !== 1) {
				updateHandler(
					{
						asset_id: id,
						data: {
							[text]: 1,
						},
					},
					data?.name,
				);
				return;
			}
			// eslint-disable-next-line no-console
			console.log('dont update');
		}
	};

	return (
		<>
			<form className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p>{code.toUpperCase()}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Deposit</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									disabled={!permission}
									onChange={() => {
										checkValue('depositable', depositable);
									}}
									name="depositable"
									checked={depositable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Withdrawal</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									disabled={!permission}
									onChange={() => {
										checkValue('withdrawable', withdrawable);
									}}
									name="withdrawable"
									checked={withdrawable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Trade</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									disabled={!permission}
									onChange={() => {
										checkValue('exchangeable', exchangeable);
									}}
									name="exchangeable"
									checked={exchangeable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default CurrenclesItem;
