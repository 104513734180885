import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

const FeManagementTabs = () => {
	const { url } = useRouteMatch();

	return (
		<div className="tabs-buttons-nav tabs-buttons-nav--default-fees">
			<NavLink to={`${url}/withdraw-fee`}>Withdraw Fee</NavLink>
			<NavLink to={`${url}/deposit-fee`}>Deposit Fee</NavLink>
			<NavLink to={`${url}/trade-fee`}>Trade Fee</NavLink>
			<NavLink to={`${url}/spread-management-fee`}>Spread Fee</NavLink>
			<NavLink to={`${url}/currencles-fee`}>Currencies</NavLink>
		</div>
	);
};

export default FeManagementTabs;
