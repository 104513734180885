/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { getLiquidityRequest } from 'redux/reducers/walletBalance/reducer';
import Item from './Item/Item';
import { ILiquidityPayload } from '../../../../redux/reducers/walletBalance/types';
import {
	getWalletBalanceLiquidityFiat,
	getWalletBalanceLiquidityFiatLoading,
} from '../../../../redux/reducers/walletBalance/selectors';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { ILocalFilter } from '../../UserBalances/DepositWalletTotals/DepositWalletTotals/Crypto/Crypto';
import PerPageDropdown from '../../../../services/utils/PaginationPerPageDropdown';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Fiat = () => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletBalanceLiquidityFiat);
	const loading = useSelector(getWalletBalanceLiquidityFiatLoading);
	console.log(loading);
	const perPage = 10;
	const totalPage = item?.liquidity.total || 1;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);
	useLayoutEffect(() => {
		const params: ILiquidityPayload = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getLiquidityRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsPerPage]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const params: ILiquidityPayload = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: selected + 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getLiquidityRequest(params));
	};
	// local filter
	const [itemFilter, setItemFilter] = useState(item === null ? [] : item?.liquidity.data);
	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});
	const filterActiveUp = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};

	const changeFilter = (changeFilterValue: string) => {
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};
	useLayoutEffect(() => {
		if (localFilter.state === 0) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setItemFilter(item?.liquidity?.data);
		}
		if (localFilter.state === 1) {
			const max = itemFilter?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem > secondItem ? -1 : 1;
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const min = itemFilter?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem < secondItem ? -1 : 1;
			});
			setItemFilter(min);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, localFilter]);
	const setItemsPerPageHandler = (num: number | string) => {
		setItemsPerPage(Number(num));
	};
	return (
		<>
			<div className="table-block mt-20">
				<div className="table-wrapper">
					<div className="table table--liquidity-fiat">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('code');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Currency
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('code')}`} />
												<div className={`arrow-top ${filterActiveUp('code')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Total Liquidity</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Platform Balances</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Pending Fees</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>B2C2 Balances</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{itemFilter?.length ? (
										itemFilter?.map((e: any) => <Item key={e.code} item={e} />)
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
							<div className="td td--right">
								<div className="td__wrap td__wrap--total">
									<p className="td-hidden-name">Platform Balances</p>
									<p>
										Total: <span>{roundingNumber(item?.totalLiquidityInEur)} EUR</span>
									</p>
								</div>
							</div>
						</div>
						<div className="table-footer">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={setItemsPerPageHandler}
									perPage={itemsPerPage}
								/>
								<ReactPaginate
									breakLabel="..."
									pageCount={Math.ceil(totalPage / itemsPerPage)}
									onPageChange={handlePageClick}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {totalPage > perPage && (
				<div style={{ marginBottom: '32px' }} className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default Fiat;
