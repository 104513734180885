import React, { useState } from 'react';
import DepositWallet from './DepositWallet/DepositWallet';
import DepositWalletTotals from './DepositWalletTotals/DepositWalletTotals/DepositWalletTotals';
import { EPaymentMethodTab } from './DepositWalletTotals/DepositWalletTotals/types';

const UserBalances = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);

	return (
		<div className="wallet-content-tables">
			<DepositWalletTotals activeTab={activeTab} setActiveTab={setActiveTab} />
			<div className="mt-40">
				<DepositWallet activeTab={activeTab} setActiveTab={setActiveTab} />
			</div>
		</div>
	);
};

export default UserBalances;
