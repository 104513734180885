/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { deleteWalletAddressRequest } from 'redux/reducers/walletAddresses/reducer';

import { setPopUpData } from 'redux/reducers/popUp/reducer';

import { IWalletAddressRequestPayload } from 'redux/reducers/walletAddresses/types';
import { getFeeRequest, putFeeRequest, updateFee } from 'redux/reducers/fee/reducer';
import { getFeeStoreData, updateState } from 'redux/reducers/fee/selectors';
import CryptoItem from './CryptoItem/CryptoItem';
import PerPageDropdown from '../../../../services/utils/PaginationPerPageDropdown';
import FiatItem from '../../WithdrawFee/Fiat/FiatItem/FiatItem';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Crypto = ({ permission }: { permission: boolean }) => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeStoreData);
	const { loading } = useSelector(getFeeStoreData);
	const updateStateReqvest = useSelector(updateState);
	const perPage = 10;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);
	const totalPage = fee.data.total;

	useLayoutEffect(() => {
		const data = {
			per_page: itemsPerPage,
			current_page: 1,
			type: 'crypto',
		};
		dispatch(getFeeRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsPerPage]);
	// }, [updateStateReqvest, itemsPerPage]);
	const handlePageClick = ({ selected }: { selected: number }) => {
		const data = {
			per_page: itemsPerPage,
			current_page: selected + 1,
			type: 'crypto',
		};
		dispatch(getFeeRequest(data));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteWalletAddressRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you what to delete?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
	};

	const updateHandler = (obj: IWalletAddressRequestPayload) => {
		dispatch(putFeeRequest({ data: obj, feeType: 'Deposit' }));
		dispatch(updateFee({ data: obj }));
	};

	const setItemsPerPageHandler = (num: number | string) => {
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--fee-management-withdraw-crypto">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{fee?.data?.data.length ? (
										fee?.data?.data.map((e: any) => (
											<CryptoItem
												key={e.id}
												deleteHandler={deleteHandler}
												updateHandler={updateHandler}
												data={e}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						<div className="table-footer">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={setItemsPerPageHandler}
									perPage={itemsPerPage}
								/>
								<ReactPaginate
									breakLabel="..."
									pageCount={Math.ceil(totalPage / itemsPerPage)}
									onPageChange={handlePageClick}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {totalPage > perPage && (
				<div className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default Crypto;
