import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	putUserTradingLimitRequest,
	getUserTradingLimitsRequest,
} from 'redux/reducers/users/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import IconSvg from 'ui/Svg/IconSvg';

import { toMaxDecimals } from 'services/utils/numbers';
import { ITradingLimitsTableRowProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const TradingLimitsTableRow: FC<ITradingLimitsTableRowProps> = ({
	updateHandler,
	limit,
	userId,
	permission,
}) => {
	const dispatch = useDispatch();

	const [showEditing, setShowEditing] = useState<boolean>(false);

	const [minValue, setMinValue] = useState<number>(limit.limit_min);
	const [maxValue, setMaxValue] = useState<number>(limit.limit_max);

	useLayoutEffect(() => {
		setMinValue(limit.limit_min);
		setMaxValue(limit.limit_max);
	}, [limit, showEditing]);

	const handleChangeShowEditing = () => {
		setShowEditing(!showEditing);
	};
	const handleChangeMin = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setMinValue(Number(e.target.value));
	}, []);

	const handleChangeMax = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxValue(Number(e.target.value));
	}, []);

	const handleClickUpdateLimit = useCallback(() => {
		if (Number(minValue) > Number(maxValue)) {
			setShowEditing(false);
			notificationContainer('Min limit is bigger than max.', 'error');
			return;
		}
		// const apiParams: ITradingLimitsRequest = {
		// 	type: showCryptoList,
		// 	id: userId,
		// 	params: {
		// 		current_page: currentPage,
		// 		per_page: perPage,
		// 	},
		// };

		const params = {
			userId,
			trading_limit_id: limit.id,
			data: {
				limit_min: minValue,
				limit_max: maxValue,
			},
			onSuccess: () => {
				updateHandler();
				notificationContainer(
					'Trade limits were updated successfully.',
					'success',
					'Changes saved',
				);
			},
		};

		dispatch(putUserTradingLimitRequest(params));
		setShowEditing(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, limit.id, maxValue, minValue, userId]);

	return (
		<>
			{!showEditing ? (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							<img
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								src={`/img/currencies/${limit.asset.code}.svg`}
								alt={limit.asset.code}
							/>
							<p>{limit.asset.code.toUpperCase()}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimun</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.limit_min, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.limit_max, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={handleChangeShowEditing}
									className="btn--icon btn--edit"
								/>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="tr tr--editable">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							<img
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								src={`/img/currencies/${limit.asset.code}.svg`}
								alt={limit.asset.code}
							/>
							<p>{limit.asset.code.toUpperCase()}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimum</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={minValue}
									placeholder="0"
									onChange={(e) => handleChangeMin(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={maxValue}
									placeholder="0"
									onChange={(e) => handleChangeMax(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={handleChangeShowEditing}
								className="btn--icon-danger btn--cancel"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={handleClickUpdateLimit}
								className="btn--icon-success btn--check"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TradingLimitsTableRow;
