import React, { FC, MouseEvent, useCallback, useLayoutEffect } from 'react';

import { ISortedButton } from 'components/tables/WalletsTable/types';
import IconSvg from 'ui/Svg/IconSvg';
import { sortedTitle } from '../utiils';
import { IUsersTableHeaderProps } from '../types';

const AdminsTableHeader: FC<IUsersTableHeaderProps> = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Admin ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Full Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email Address</p>
					</div>
				</div>

				<div className="td td--center">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminsTableHeader;
