import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	TWO_FA_STATUS_NUM_ACCEPT,
	TWO_FA_STATUS_NUM_NEW,
	TWO_FA_STATUS_NUM_REJECT,
	TWO_FA_STATUS_NUM_REJECT_2,
} from 'redux/reducers/users/constants';
import { rejectAdminPassword, resetAdminPassword } from 'redux/reducers/users/reducer';
import { RejectModal } from './RejectModal';
import { ResetModal } from './ResetModal';
import { IResetRejectButtonsProps } from './types';

export const ResetRejectButtons: FC<IResetRejectButtonsProps> = ({
	status,
	id,
	handleClearSearch,
}) => {
	const dispatch = useDispatch();
	const [openResetModal, setOpenResetModal] = useState(false);
	const closeResetModal = () => setOpenResetModal(false);

	const [openRejectModal, setOpenRejectModal] = useState(false);
	const closeRejectModal = () => setOpenRejectModal(false);

	const handleReset = () => {
		dispatch(resetAdminPassword({ id }));
		handleClearSearch && handleClearSearch();
	};
	const handleReject = (message: string) => {
		dispatch(rejectAdminPassword({ id, message }));
		handleClearSearch && handleClearSearch();
	};

	return (
		<>
			<div className="table-buttons">
				{status === TWO_FA_STATUS_NUM_NEW ? (
					<>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							onClick={() => setOpenRejectModal(true)}
							className="btn--icon-danger btn--cancel"
						/>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							onClick={() => setOpenResetModal(true)}
							className="btn--icon-success btn--check"
						/>
					</>
				) : (
					<>
						<span className="empty-button" style={{ width: '25px' }} />
						<span className="empty-button" style={{ width: '25px' }} />
					</>
				)}
			</div>
			<ResetModal
				openModal={openResetModal}
				closeModal={closeResetModal}
				handleResetModal={handleReset}
			/>
			<RejectModal
				openModal={openRejectModal}
				closeModal={closeRejectModal}
				handleRejectModal={handleReject}
			/>
		</>
	);
};
