import { useState, useLayoutEffect } from 'react';

export type TUseDebounce = (value: string, delay: number, fn?: (value: string) => void) => string;

export const useDebounce: TUseDebounce = (value, delay, fn) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useLayoutEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
			!!fn && fn(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, delay]);
	return debouncedValue;
};
