import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import ResetRequestItem from 'components/ResetRequest/ResetRequestItem';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getRequests, getUserLoader } from 'redux/reducers/users/selectors';
import Loading from 'layouts-elements/Loading/Loading';
import PerPageDropdown from '../../../../../services/utils/PaginationPerPageDropdown';
import TableBodyNoData from '../../../../../ui/TableBodyNoData/TableBodyNoData';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}
const UserTwoFaTab: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	const requests = useSelector(getRequests);
	const isLoading = useSelector(getUserLoader);

	const permissions = useSelector(getPermissions);
	// const handlePageClick = ({ selected }: { selected: number }) => {
	// 	console.log(selected + 1);
	// };

	return (
		<div className="table table--user-management-reset-request">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>2FA</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>IP Address</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reason</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>

			<div className="table-body">
				{isLoading ? (
					<Loading />
				) : (
					<>
						{requests?.data.length ? (
							requests?.data?.map((item) => (
								<ResetRequestItem
									key={item.created_at + String(item.id)}
									data={item}
									permission={!!permissions?.[EPermissionNames.RESET_REQUEST].editable}
								/>
							))
						) : (
							<TableBodyNoData />
						)}
					</>
				)}
			</div>

			<div className="table-footer">
				<div className="pagination-block pagination-block--full">
					<PerPageDropdown
						options={[10, 20, 50, 100]}
						setPerPage={setItemsPerPageHandler}
						perPage={itemsPerPage}
					/>
					<ReactPaginate
						breakLabel="..."
						pageCount={requests?.last_page || 1}
						onPageChange={handlePageChange}
						forcePage={page - 1}
						className="pagination"
						activeClassName="active"
						previousClassName="pagination__arrow pagination__arrow--prev"
						nextClassName="pagination__arrow pagination__arrow--next"
						previousLabel=""
						nextLabel=""
					/>
				</div>
			</div>
		</div>
	);
};

export default UserTwoFaTab;
