import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	confirmationPasswordRequest,
	confirmationPasswordSuccess,
	confirmationInitState,
	confirmationTwoFaSuccess,
	confirmationTwoFaRequest,
	confirmationTwoFaCodeSuccess,
	confirmationTwoFaCodeRequest,
} from './reducer';
import { IRequestPayloadData, ITwoFaResponse } from './types';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';

// =============================================================:

// =============================================================:
function* confirmationPasswordWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.confirmation.confirmationPassword, payload);
		notificationContainer(notificationsMessagesInfo.passwordSuccessfully, 'success');
		yield put(confirmationPasswordSuccess());
		payload.history.push(`/registration/twoFa/`);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* confirmationTwoFaWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(api.confirmation.confirmationTwoFa, payload);
		notificationContainer(notificationsMessagesInfo.confirmationTwoFa, 'success');
		yield put(confirmationTwoFaSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* confirmationTwoFaCodeWorker(action: PayloadAction<IRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.confirmation.confirmationTwoFaRegistration, payload);
		notificationContainer(notificationsMessagesInfo.successfulRegistration, 'success');
		payload.history.push('/login');
		yield put(confirmationTwoFaCodeSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(confirmationInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* confirmationSaga() {
	yield takeEvery(confirmationPasswordRequest.type, confirmationPasswordWorker);
	yield takeEvery(confirmationTwoFaRequest.type, confirmationTwoFaWorker);
	yield takeEvery(confirmationTwoFaCodeRequest.type, confirmationTwoFaCodeWorker);
}
