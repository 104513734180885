import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ICurrencyApi } from './types';
// ==========================================:

export const currency: ICurrencyApi = {
	getCurrency: () =>
		http.get(endpoint.currency.GET_CURRENCY).then((response) => {
			return response.data;
		}),
	getCurrencyValue: ({ userId, value }) => {
		return http.get(endpoint.currency.GET_CURRENCY_USER(userId, value)).then((response) => {
			return response.data;
		});
	},
	putCurrencyValue: (params) => {
		return http.put(endpoint.currency.PUT_CURRENCY_USER, { ...params }).then((response) => {
			return response.data;
		});
	},
};
