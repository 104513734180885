/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TApiGetFiatHistoryResponse } from '../../../services/api/transactions/types';

// ==========================================:
export const initialState: any = {
	data: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 5,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	dataSpreadManagement: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 5,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	dataCurrencies: [],
	loading: false,
	loadingPut: false,
	updateState: false,
	loadingSpreadManagement: false,
	loadingSpreadManagementPut: false,
	loadingCurrenciesGet: false,
	loadingCurrenciesPut: false,
};

// ==========================================:
const fee = createSlice({
	name: '@@fee',
	initialState,
	reducers: {
		getFeeRequest: (state, payload) => {
			const newState = state;
			newState.loading = true;
		},
		getFeeSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.data = payload;
			newState.loading = false;
		},
		updateFee: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState.data.data.findIndex(
				(item: any) => item.id === payload.data.asset_id,
			);
			const updatableItem = newState.data.data[updatableItemIndex].fee;
			const updatedItem = { ...updatableItem, ...payload.data.data };
			const updatedItems = [...newState.data.data];
			updatedItems[updatableItemIndex].fee = updatedItem;
			newState.data.data = updatedItems;
		},
		putFeeRequest: (state, payload) => {
			const newState = state;
			newState.loadingPut = true;
		},
		putFeeSuccess: (state) => {
			const newState = state;
			newState.loadingPut = false;
		},
		updateFeeSpreadManagement: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState.dataSpreadManagement.data.findIndex(
				(item: any) => item.id === payload.asset_pair_id,
			);
			const updatableItem = newState.dataSpreadManagement.data[updatableItemIndex];
			const updatedItem = { ...updatableItem, ...payload };
			const updatedItems = [...newState.dataSpreadManagement.data];
			updatedItems[updatableItemIndex] = updatedItem;
			newState.dataSpreadManagement.data = updatedItems;
		},
		getFeeSpreadManagementRequest: (state, payload) => {
			const newState = state;
			newState.loadingSpreadManagement = true;
		},
		getFeeSpreadManagementSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.dataSpreadManagement = payload;
			newState.loadingSpreadManagement = false;
		},
		putFeeSpreadManagementRequest: (state, payload) => {
			const newState = state;
			newState.loadingSpreadManagementPut = true;
		},
		putFeeSpreadManagementSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.dataSpreadManagement = payload;
			newState.loadingSpreadManagementPut = false;
		},
		getFeeCurrenciesRequest: (state, payload) => {
			const newState = state;
			newState.loadingCurrenciesGet = true;
		},
		getFeeCurrenciesSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.dataCurrencies = payload;
			newState.loadingCurrenciesGet = false;
		},
		putFeeCurrenciesRequest: (state, payload) => {
			const newState = state;
			newState.loadingCurrenciesPut = true;
		},
		putFeeCurrenciesSuccess: (state) => {
			const newState = state;
			newState.loadingCurrenciesPut = false;
		},
		updateState: (state) => {
			const newState = state;
			newState.updateState = !newState.updateState;
		},
	},
});

export default fee.reducer;
export const {
	getFeeRequest,
	getFeeSuccess,
	putFeeRequest,
	putFeeSuccess,
	updateState,
	getFeeSpreadManagementRequest,
	getFeeSpreadManagementSuccess,
	putFeeSpreadManagementRequest,
	putFeeSpreadManagementSuccess,
	getFeeCurrenciesRequest,
	getFeeCurrenciesSuccess,
	putFeeCurrenciesRequest,
	putFeeCurrenciesSuccess,
	updateFee,
	updateFeeSpreadManagement,
} = fee.actions;
