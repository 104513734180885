import React, { useEffect, useState } from 'react';

import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { useDispatch, useSelector } from 'react-redux';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import Total from '../../Total/Total';
import { EPaymentMethodTab } from './types';
import TotalFiat from '../../Total/TotalFiat';
import { popUpOpen, setPopUpData } from '../../../../../redux/reducers/popUp/reducer';
import { getPlatformBalancesUserRequest } from '../../../../../redux/reducers/walletBalance/reducer';

const DepositWalletTotals = ({ activeTab, setActiveTab }: any) => {
	const permission = useSelector(getPermissions)?.[EPermissionNames.WALLET_MANAGEMENT].editable;
	const dispatch = useDispatch();

	useEffect(() => {
		const paramsCrypto: any = {
			apiParams: {
				per_page: 10,
				current_page: 1,
				currency: 'crypto',
			},
		};
		const paramsFiat: any = {
			apiParams: {
				per_page: 10,
				current_page: 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getPlatformBalancesUserRequest(paramsCrypto));
		dispatch(getPlatformBalancesUserRequest(paramsFiat));
	}, [dispatch]);

	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto />;
			case EPaymentMethodTab.FIAT:
				return <Fiat />;
			default:
				return <Crypto />;
		}
	};

	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};
	return (
		<>
			<div className="currencyButtonFile">
				<div className="currencyButton">
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
						onClick={setToCryptoTab}
					>
						Crypto
					</button>
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
						onClick={setToFiatTab}
					>
						Fiat
					</button>
				</div>

				{activeTab === 'fiat' ? null : (
					<>
						{permission && (
							<button
								onClick={() => {
									const data = {
										title: 'Send All to Pool Wallet',
										payload: {
											type: 'User balances all',
										},
									};
									dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
									dispatch(setPopUpData(data));
								}}
								type="button"
								className="btn btn-primary"
							>
								Send All to Pool Wallet
							</button>
						)}
					</>
				)}
			</div>
			{/* {activeTab === 'crypto' ? (
				<Total activeTab={activeTab} permission={!!permission} />
			) : (
				<TotalFiat activeTab={activeTab} permission={!!permission} />
			)} */}
			<Total activeTab={activeTab} permission={!!permission} />

			<div className="table-title">
				<p>{activeTab === 'crypto' ? 'Deposit Wallet Totals' : 'Platform Balance Totals'}</p>
			</div>
			{chooseTab()}
		</>
	);
};
export default DepositWalletTotals;
