import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { deleteAdminRequest } from 'redux/reducers/admins/reducer';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const DeleteAdminPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
	payload,
}) => {
	const dispatch = useDispatch();

	const handleDeleteAdmin = () => {
		const data = {
			...payload,
			message,
		};
		dispatch(deleteAdminRequest(data));
		closeModal();
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Remove Administrator</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0052 49.8385C40.4635 49.8385 49.8385 40.4635 49.8385 29.0052C49.8385 17.5469 40.4635 8.17188 29.0052 8.17188C17.5469 8.17188 8.17188 17.5469 8.17188 29.0052C8.17188 40.4635 17.5469 49.8385 29.0052 49.8385Z"
							fill="#FFB500"
							stroke="#FFB500"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M29.0004 38.4112H22.9404C19.4704 38.4112 18.0204 35.9312 19.7004 32.9012L22.8204 27.2812L25.7604 22.0013C27.5404 18.7912 30.4604 18.7912 32.2404 22.0013L35.1804 27.2913L38.3004 32.9113C39.9804 35.9413 38.5204 38.4212 35.0604 38.4212H29.0004V38.4112Z"
							stroke="#FCFCFD"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M29 26V31" stroke="#FCFCFD" strokeLinecap="round" strokeLinejoin="round" />
						<path
							d="M28.9941 34H29.0031"
							stroke="#FCFCFD"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-text">
					<p>Are you sure you want to delete the admin?</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button onClick={handleDeleteAdmin} type="button" className="btn btn-primary btn--full">
							Delete
						</button>
						<button onClick={closeModal} type="button" className="btn btn-danger btn--full">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteAdminPopUp;
