import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import TradingLimitsTableRow from './TradingLimitsTableRow';
import { ITradingLimitsTableProps } from './types';
import PerPageSelect from '../../../ui/PerPageSelect/PerPageSelect';
import Pagination from '../../../ui/Pagination/Pagination';
import ReferralTableRow from '../ReferralTable/ReferralTableRow';
import { EPermissionNames } from '../../../redux/reducers/auth/types';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../layouts-elements/Loading/Loading';

const TradingLimitsTable: FC<ITradingLimitsTableProps> = ({
	perPage,
	limitsList,
	permission,
	updateHandler,
	handleSetPerPage,
	pageCountValue,
	currentPageValue,
	setCurrentPage,
	loading,
}) => {
	const { userId } = useParams<IAccountDetailsParams>();
	// const [currentItems, setCurrentItems] = useState<IUserTradingLimitsItem[] | null>(null);
	// const [pageCount, setPageCount] = useState(0);
	// const [itemOffset, setItemOffset] = useState(0);

	// const handlePageClick = ({ selected }: { selected: number }) => {
	// 	if (limitsList && perPage) {
	// 		const newOffset = (selected * perPage) % limitsList.data.length;
	// 		setItemOffset(newOffset);
	// 	}
	// };

	// useLayoutEffect(() => {
	// 	if (limitsList && perPage) {
	// 		const endOffset = itemOffset + Number(perPage);
	// 		setCurrentItems(limitsList.data.slice(itemOffset, endOffset));
	// 		setPageCount(Math.ceil(limitsList.data.length / perPage));
	// 	}
	// }, [itemOffset, limitsList, perPage]);
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--trading-limits-crypto">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Minimum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Maximum</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{limitsList?.data?.length ? (
										limitsList?.data?.map((limit) => (
											<TradingLimitsTableRow
												key={limit.id}
												limit={limit}
												userId={userId}
												updateHandler={updateHandler}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{!!limitsList?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCountValue && currentPageValue && (
										<>
											{pageCountValue > 1 && (
												<Pagination
													pageCount={pageCountValue}
													currentPage={currentPageValue}
													onPageChange={setCurrentPage}
												/>
											)}
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TradingLimitsTable;
