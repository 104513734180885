import { IStoreState } from 'redux/types';
import { ITransactionsFiltersStore } from './types';

export const getTransactionsFilters = (state: IStoreState): ITransactionsFiltersStore =>
	state.transactionsFilters;

export const getCryptoFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).cryptoFilters;
};

export const getFiatFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).fiatFilters;
};
export const getFiatCryptoFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).cryptoFiatFilters;
};
export const getPooledDepositsFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).pooledDepositsFilters;
};

export const getTradesFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).tradesFilters;
};

export const getProfitWalletFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).profitWalletFilters;
};

export const getPendingFiatWithdrawalFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).pendingFiatWithdrawalFilters;
};

export const getPendingCryptoWithdrawalFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).pendingCryptoWithdrawalFilters;
};

export const getReferralFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).referralFilters;
};

export const getBankAccountsWithSelectFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).bankAccountsWithSelectFilters;
};

export const getLedgerFilters = (state: IStoreState) => {
	return getTransactionsFilters(state).ledgerFilters;
};
