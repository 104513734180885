import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IResetModalProps } from './types';

// ================================================:
export const ResetModal: FC<IResetModalProps> = ({ openModal, closeModal, handleResetModal }) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<h3 className="popup-header__title">Approve 2FA Reset Request</h3>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#FFB500"
							stroke="#FFB500"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M28.9994 38.4112H22.9394C19.4694 38.4112 18.0194 35.9312 19.6994 32.9012L22.8194 27.2812L25.7594 22.0013C27.5394 18.7912 30.4594 18.7912 32.2394 22.0013L35.1794 27.2913L38.2994 32.9113C39.9794 35.9413 38.5194 38.4212 35.0594 38.4212H28.9994V38.4112Z"
							stroke="#FCFCFD"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M29 26V31" stroke="#FCFCFD" strokeLinecap="round" strokeLinejoin="round" />
						<path
							d="M28.9961 34H29.0051"
							stroke="#FCFCFD"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--center">
						<p>Are you sure you want to approve the 2FA reset request?</p>
					</div>

					<div className="popup-footer">
						<div className="popup-submit popup-submit--column">
							<button
								type="button"
								className="btn btn-primary btn--full"
								onClick={() => {
									handleResetModal();
									closeModal();
								}}
							>
								Confirm
							</button>
							<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
