import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen } from '../popUp/reducer';
import { settingsInitState, verificationFilesRequest, verificationFilesSuccess } from './reducer';
import { IVerificationFilesRequestPayloadData } from './types';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';

// =============================================================:

// =============================================================:
function* verificationFilesWorker(action: PayloadAction<IVerificationFilesRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.settings.verification, payload);
		notificationContainer(notificationsMessagesInfo.verificationFiles, 'success');
		yield put(verificationFilesSuccess());
		yield put(popUpOpen('verificationSuccess'));

		payload.history.push('/');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* settingsSaga() {
	yield takeEvery(verificationFilesRequest.type, verificationFilesWorker);
}
