import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOneUser } from '../../../redux/reducers/users/selectors';
import { getFiatTransfersList } from '../../../redux/reducers/transactions/selectors';
import { getOneUserRequest } from '../../../redux/reducers/users/reducer';
import Loading from '../../../layouts-elements/Loading/Loading';
import DetailedViewTransfersFiatForm from '../DetailedViewTransfersFiatForm/DetailedViewTransfersFiatForm';
import {
	getDetailedViewLoading,
	getFiatDetails,
} from '../../../redux/reducers/detailedView/selectors';
import { getFiatDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface ITransfersFiatDetails {
	txId: string | number;
	userId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const TransfersFiatDetails: FC<ITransfersFiatDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	// const loading = useSelector(getOneUserLoading);
	const currentItemData = useSelector(getFiatTransfersList);
	const loading = useSelector(getDetailedViewLoading);
	const fiatDetails = useSelector(getFiatDetails);

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (txId && userData?.id) {
			// const params: any = {
			// 	userId: userData.id,
			// 	// type: EApiTransactionType.TRANSFER,
			// 	current_page: 1,
			// 	per_page: 1,
			// 	id: Number(txId),
			// };
			// dispatch(getFiatTransfersRequest(params));
			dispatch(getFiatDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId, userData?.id]);

	return (
		<>
			{fiatDetails && !loading && userData ? (
				<div className="detailed-view" key={fiatDetails.id}>
					<DetailedViewTransfersFiatForm item={fiatDetails} />
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default TransfersFiatDetails;
