import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IWalletAddressApi } from './types';
// ==========================================:
export const walletAddresses: IWalletAddressApi = {
	getWalletAddresses: (payload) =>
		http
			.get(endpoint.walletAddresses.WALLET_ADDRESSES(payload.userId), { params: payload.pageInfo })
			.then(({ data }) => data),
	getUserWalletAddresses: (payload) =>
		http
			.get(
				endpoint.walletAddresses.USER_WALLET_ADDRESSES(
					payload.userId,
					payload.asset_id,
					payload.chain_id,
				),
			)
			.then(({ data }) => data),
	deleteWalletAddress: (payload) =>
		http
			.delete(endpoint.walletAddresses.WALLET_ADDRESS(payload.userId), {
				params: payload,
			})
			.then(({ data }) => data),
	updateWalletAddress: (payload) =>
		http
			.put(endpoint.walletAddresses.WALLET_ADDRESS(payload.userId), payload)
			.then(({ data }) => data),
};
