import { useState } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EApiMessagesType } from 'redux/reducers/messages/types';
import { useDispatch, useSelector } from 'react-redux';
import { resetOneMessageState } from 'redux/reducers/messages/reducer';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getPermissions } from 'redux/reducers/auth/selectors';

import MessagesTable from './MessagesTable';

const Messages = () => {
	const dispatch = useDispatch();
	const [newMessageForm, setNewMessageForm] = useState(false);
	const permissions = useSelector(getPermissions);

	const handleCreateMessage = () => {
		dispatch(resetOneMessageState());
		setNewMessageForm(true);
	};

	const handleCloseMessageForm = () => {
		setNewMessageForm(false);
	};

	const handleMessageFormToogle = () => {
		setNewMessageForm(!newMessageForm);
	};
	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Messages</p>
				</div>
			</div>
			{!newMessageForm && (
				<div className="tabs-buttons-wrapper">
					<div className="tabs-buttons-nav tabs-buttons-nav--messages">
						<NavLink to={ROUTES.messages.index}>Planned</NavLink>
						<NavLink to={ROUTES.messages.sent}>Sent</NavLink>
					</div>
					{!!permissions?.[EPermissionNames.MESSAGES]?.editable && (
						<button
							type="button"
							className="btn btn-primary btn--mw-180"
							onClick={handleCreateMessage}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add Message
						</button>
					)}
				</div>
			)}
			<div>
				<Switch>
					<Route exact path={ROUTES.messages.index}>
						<MessagesTable
							type={EApiMessagesType.PLANNED}
							newMessageForm={newMessageForm}
							onToogleForm={handleMessageFormToogle}
						/>
					</Route>
					<Route exact path={ROUTES.messages.sent}>
						<MessagesTable
							type={EApiMessagesType.SENT}
							newMessageForm={newMessageForm}
							onToogleForm={handleMessageFormToogle}
						/>
					</Route>
					<Redirect to={ROUTES.messages.index} />
				</Switch>
			</div>
		</>
	);
};

export default Messages;
