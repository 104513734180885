import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { ICurrencyStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): ICurrencyStore => state.currency;
export const getCurrencyData = createSelector(
	[getAuthState],
	(currency: ICurrencyStore) => currency.currencyData,
);
export const getCurrencyLoading = createSelector(
	[getAuthState],
	(currency: ICurrencyStore) => currency.currencyLoading,
);

export const getFiatCurrencyData = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.currencyData.filter((el) => el.type === 'fiat');
});

export const getCryptoCurrencyData = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.currencyData.filter((el) => el.type !== 'fiat');
});

export const getCryptoCurrency = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.currencyCrypto;
});
export const getCryptoCurrencyLoading = createSelector(
	[getAuthState],
	(currency: ICurrencyStore) => {
		return currency.currencyCryptoLoading;
	},
);

export const getFiatCurrency = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.currencyFiat;
});
export const getFiatCurrencyLoading = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.currencyFiatLoading;
});
export const updateState = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.updateState;
});
