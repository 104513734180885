import { SortedButton } from './types';

export const sortedTitle: Record<string, string> = {
	[SortedButton.code]: 'code',
	[SortedButton.total]: 'total',
	[SortedButton.fireblock]: 'fireblock',
	[SortedButton.code_currnecy]: 'code',
	[SortedButton.deposit]: 'fiat',
	[SortedButton.platform_balance]: 'total',
};
