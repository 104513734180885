import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import Select from '../index';
import { IWalletAddress } from '../../../../redux/reducers/walletAddresses/types';
import { IWalletAddressNameSelect, IWalletAddressNameSelectCustomList } from './types';

const WalletAddressNameSelectCustomInput: FC<IWalletAddress> = ({ nickname, address }) => {
	return (
		<>
			{nickname && address ? (
				<span className="coin coin--type3" style={{ display: nickname && address ? '' : 'none' }}>
					<span className="coin__text-wrap">
						<span className="coin__text upper__text coin__text---pt-0">
							{nickname}
							{/* <span className="coin__text-more">{address}</span> */}
						</span>
					</span>
				</span>
			) : (
				<span>Select Wallet address</span>
			)}
		</>
	);
};

const WalletAddressNameSelectCustomList: FC<IWalletAddressNameSelectCustomList> = ({
	arr,
	onChange,
}) => (
	<>
		{arr &&
			arr.map((el) => {
				return (
					<li key={el.address}>
						<button type="button" onClick={() => onChange(el)}>
							<span className="coin coin--type3">
								<span className="coin__text-wrap">
									<span
										className="coin__text upper__text coin__text---pt-0"
										style={{ textTransform: 'none' }}
									>
										{el.nickname} <br />
										<span
											className="coin__text-more coin__text-more--type2"
											style={{ overflowWrap: 'anywhere' }}
										>
											{el.address}
										</span>
									</span>
								</span>
							</span>
						</button>
					</li>
				);
			})}
	</>
);

const WalletAddressNameSelect: FC<IWalletAddressNameSelect> = ({
	activeValue,
	onChange,
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	...props
}) => {
	const initActiveWallet = useMemo(
		() => ({
			address: '',
			nickname: '',
		}),
		[],
	);
	const [activeWallet, setActiveWallet] = useState<any>(initActiveWallet);
	const [searchCurrency, setSearchCurrency] = useState<any[]>([]);

	useLayoutEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useLayoutEffect(() => {
		setActiveWallet(activeValue || initActiveWallet);
	}, [initActiveWallet, activeValue]);

	useLayoutEffect(() => {
		if (resetCustomSelect) {
			setActiveWallet(initActiveWallet);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveWallet, resetCustomSelect, setResetCustomSelect]);

	const onChangeHandler = (el: IWalletAddress) => {
		setActiveWallet(el);
		!!onChange && onChange(el);
	};

	const customList = {
		list: <WalletAddressNameSelectCustomList arr={searchCurrency} onChange={onChangeHandler} />,
		activeElement: activeWallet.address,
	};

	return (
		<Select
			{...props}
			customInput={<WalletAddressNameSelectCustomInput {...activeWallet} />}
			customList={customList}
			arr={arr}
		/>
	);
};
export default WalletAddressNameSelect;
