import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
	getAdminResetDataRequest,
	getAdminResetTwoFaDataRequest,
	getResetRequests,
	getUserPasswordResetRequests,
} from 'redux/reducers/users/reducer';
import {
	getAdminResetPasswordsRequests,
	getAdminResetPasswordsRequestsLoading,
	getAdminTwoFaResetRequests,
	getRequests,
	getUsersPasswordResetRequests,
} from 'redux/reducers/users/selectors';
import { ROUTES } from 'routes/constants';
import { NavLink } from 'react-router-dom';
import UsersTable from './UserTable';
import AdminsTable from './AdminTable';
import { navListAfterLogin } from '../../../routes/routesList';
import AdminResetRequestsTabs from '../../../components/ResetRequest/AdminResetRequestTabs/AdminResetRequestsTabs';
import UserResetRequestsTabs from '../../../components/ResetRequest/UserResetRequestTabs/UserResetRequestsTabs';
import ResetRequestSearch from '../../../components/tables/UsersTable/ResetRequestSearch';
import ResetRequestSearchFull from '../../../components/tables/UsersTable/ResetRequestSearchFull';

/* eslint-disable jsx-a11y/anchor-is-valid */
const ResetRequestPage = () => {
	const [page, setPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const history = useHistory();
	const [activeTab, setActiveTab] = useState(/users|admin/.exec(history.location.pathname)?.[0]);
	const dispatch = useDispatch();
	const userPasswordRequests = useSelector(getUsersPasswordResetRequests);
	const userTwoFaRequests = useSelector(getRequests);

	const adminPasswordRequests = useSelector(getAdminResetPasswordsRequests);
	const adminTwoFaRequests = useSelector(getAdminTwoFaResetRequests);
	const [query, setQuery] = useState<any>({});
	const [total, setTotal] = useState<number>();

	const { pathname } = useLocation();

	useLayoutEffect(() => {
		if (history.location.pathname.includes(ROUTES.UserResetRequest.twofa)) {
			const data = {
				current_page: page,
				per_page: itemsPerPage,
				[query?.search_field]: query?.search_value,
				[query?.status_field]: query?.status_value,
			};
			dispatch(getResetRequests({ ...data, current_page: page, per_page: itemsPerPage }));
			setTotal(userTwoFaRequests?.total);
		}
		if (history.location.pathname.includes(ROUTES.AdminResetRequest.twofa)) {
			const data = {
				current_page: page,
				per_page: itemsPerPage,
				[query?.search_field]: query?.search_value,
				[query?.status_field]: query?.status_value,
			};
			dispatch(
				getAdminResetTwoFaDataRequest({ ...data, current_page: page, per_page: itemsPerPage }),
			);
			setTotal(adminTwoFaRequests?.total);
		}
		if (history.location.pathname.includes(ROUTES.AdminResetRequest.password)) {
			const data = {
				current_page: page,
				per_page: itemsPerPage,
				[query?.search_field]: query?.search_value,
				[query?.status_field]: query?.status_value,
			};
			dispatch(getAdminResetDataRequest({ ...data, current_page: page, per_page: itemsPerPage }));
			setTotal(adminPasswordRequests?.total);
		}
		if (history.location.pathname.includes(ROUTES.UserResetRequest.password)) {
			const data = {
				current_page: page,
				per_page: itemsPerPage,
				[query?.search_field]: query?.search_value,
				[query?.status_field]: query?.status_value,
			};
			dispatch(
				getUserPasswordResetRequests({ ...data, current_page: page, per_page: itemsPerPage }),
			);
			setTotal(userPasswordRequests?.total);
		}
	}, [
		dispatch,
		query,
		page,
		itemsPerPage,
		activeTab,
		history.location.pathname,
		userTwoFaRequests?.total,
		adminTwoFaRequests?.total,
		adminPasswordRequests?.total,
		userPasswordRequests?.total,
	]);

	const handlePageChange = ({ selected }: { selected: number }) => {
		setPage(selected + 1);
	};

	const setItemsPerPageHandler = (num: number | string) => {
		let fromNumber;
		if (activeTab === 'users') {
			fromNumber =
				pathname === ROUTES.UserResetRequest.password
					? userPasswordRequests?.from
					: userTwoFaRequests?.from;
		}
		if (activeTab === 'admin') {
			fromNumber =
				pathname === ROUTES.AdminResetRequest.password
					? adminPasswordRequests?.from
					: adminTwoFaRequests?.from;
		}
		fromNumber && setPage(Math.ceil(fromNumber / Number(num)));
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Reset Requests</p>
				</div>
			</div>
			<div className="currencyButton currencyButton--full">
				<NavLink
					to={navListAfterLogin.resetRequestUser2fa.path}
					className={activeTab === 'users' ? 'active' : ''}
				>
					User
				</NavLink>
				<NavLink
					to={navListAfterLogin.resetRequestAdmin2fa.path}
					className={activeTab === 'admin' ? 'active' : ''}
				>
					Admin
				</NavLink>
			</div>
			<div className="mt-20">
				{activeTab === 'users' && <UserResetRequestsTabs />}
				{activeTab === 'admin' && <AdminResetRequestsTabs />}
			</div>

			{activeTab === 'users' && (
				<ResetRequestSearchFull
					total={total}
					setQuery={setQuery}
					tab={activeTab}
					setPage={setPage}
				/>
			)}
			{activeTab === 'admin' && (
				<ResetRequestSearch total={total} setQuery={setQuery} tab={activeTab} setPage={setPage} />
			)}

			{activeTab === 'users' && (
				<UsersTable
					handlePageChange={handlePageChange}
					setItemsPerPageHandler={setItemsPerPageHandler}
					itemsPerPage={itemsPerPage}
					page={page}
				/>
			)}
			{activeTab === 'admin' && (
				<AdminsTable
					handlePageChange={handlePageChange}
					setItemsPerPageHandler={setItemsPerPageHandler}
					itemsPerPage={itemsPerPage}
					page={page}
				/>
			)}
		</>
	);
};

export default ResetRequestPage;
