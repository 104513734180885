/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminsResponse } from '../../../services/api/admins/types';
import { IAdminsStore, INewAdminData, TPermissionsResponse } from './types';

export const initialState: IAdminsStore = {
	admins: null,
	adminsLoading: false,
	permissions: null,
};

const admins = createSlice({
	name: '@@admins',
	initialState,
	reducers: {
		getAdminsRequest: (state, action) => {
			const newState = state;
			newState.adminsLoading = true;
		},
		getAdminsSuccess: (state, action: PayloadAction<IAdminsResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.adminsLoading = false;
			newState.admins = payload;
		},
		resetAdminsLoader: (state) => {
			const newState = state;
			newState.adminsLoading = false;
		},
		createAdminRequest: (state, action: PayloadAction<INewAdminData>) => {},
		createAdminSuccess: (state, action) => {},
		deleteAdminRequest: (state, action) => {},
		deleteAdminSuccess: (state, action) => {},
		getAdminPermissionsRequest: (state, action) => {},
		getAdminPermissionsSuccess: (state, action) => {},
		updateAdminDataRequest: (state, action) => {},
		updateAdminDataSuccess: (state, action) => {},
		changeAdminStatusRequest: (state, action) => {},
		changeAdminStatusSuccess: (state, action) => {},
		getAllPermissionsListRequest: (state) => {},
		getAllPermissionsListSuccess: (state, action: PayloadAction<TPermissionsResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.permissions = payload;
		},
	},
});

export default admins.reducer;
export const {
	getAdminsRequest,
	getAdminsSuccess,
	getAllPermissionsListRequest,
	getAllPermissionsListSuccess,
	createAdminRequest,
	createAdminSuccess,
	deleteAdminRequest,
	deleteAdminSuccess,
	changeAdminStatusRequest,
	changeAdminStatusSuccess,
	updateAdminDataRequest,
	resetAdminsLoader,
} = admins.actions;
