import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_FILTER_OPTIONS } from 'redux/reducers/transactionsFilters/constants';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { getFiatCurrencyData } from 'redux/reducers/currency/selectors';

import FilterSelect from 'ui/Formik/Select/FilterSelect';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';

const BankAccountsWithSelectHeader = () => {
	const dispatch = useDispatch();

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.ASSET_ID,
				value: value as string,
				name,
				filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
			}),
		);
	};

	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Beneficiary Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Beneficiary Address</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Bank Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Bank Address</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<FilterSelect
						title="Currency"
						options={
							[...INIT_FILTER_OPTIONS, ...fiatCurrencyFilterOptions] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleAssetChange}
					/>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>SWIFT/BIC</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Account Number</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Note</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Enable/Disable</p>
				</div>
			</div>
		</div>
	);
};

export default BankAccountsWithSelectHeader;
