import { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { EPaymentMethodTab } from './types';
import FeeManagementWithdraw from './FeeManagementWithdraw/FeeManagementWithdraw';
import FeeManagementDeposit from './FeeManagementDeposit/FeeManagementDeposit';
import FeeManagementTrade from './FeeManagementTrade/FeeManagementTrade';
import FeeManagementSpread from './FeeManagementSpread/FeeManagementSpread';

const FeeManagementTab = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.WITHDRAW);
	const [activeCoin, setActiveCoin] = useState('crypto');
	const permissions = useSelector(getPermissions);
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.WITHDRAW:
				return (
					<FeeManagementWithdraw
						activeCoin={activeCoin}
						permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
					/>
				);
			case EPaymentMethodTab.DEPOSIT:
				return (
					<FeeManagementDeposit
						activeCoin={activeCoin}
						permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
					/>
				);
			case EPaymentMethodTab.TRADE:
				return (
					<FeeManagementTrade
						permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
					/>
				);
			case EPaymentMethodTab.SPREAD:
				return (
					<FeeManagementSpread
						permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
					/>
				);
			default:
				return (
					<FeeManagementWithdraw
						activeCoin={activeCoin}
						permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
					/>
				);
		}
	};
	const setToWithdrawTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setActiveTab(EPaymentMethodTab.WITHDRAW);
	};
	const setToDepositTab = () => {
		setActiveTab(EPaymentMethodTab.DEPOSIT);
	};
	const setToTradeTab = () => {
		setActiveTab(EPaymentMethodTab.TRADE);
	};
	const setToSpredTab = () => {
		setActiveTab(EPaymentMethodTab.SPREAD);
	};
	return (
		<>
			<div className="table-panel mt-20 mb-20">
				<div className="tabs-buttons-nav tabs-buttons-nav--fee-management mb-0">
					<button
						type="button"
						name="WITHDRAW"
						className={activeTab === EPaymentMethodTab.WITHDRAW ? 'active' : ''}
						onClick={setToWithdrawTab}
					>
						Withdraw Fee
					</button>

					<button
						type="button"
						className={activeTab === EPaymentMethodTab.DEPOSIT ? 'active' : ''}
						onClick={setToDepositTab}
					>
						Deposit Fee
					</button>

					<button
						type="button"
						className={activeTab === EPaymentMethodTab.TRADE ? 'active' : ''}
						onClick={setToTradeTab}
					>
						Trade Fee
					</button>
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.SPREAD ? 'active' : ''}
						onClick={setToSpredTab}
					>
						Spread Fee
					</button>
				</div>
				{activeTab === EPaymentMethodTab.DEPOSIT || activeTab === EPaymentMethodTab.WITHDRAW ? (
					<div className="currencyButton">
						<button
							onClick={() => setActiveCoin('crypto')}
							type="button"
							className={activeCoin === 'crypto' ? 'active' : ''}
						>
							Crypto
						</button>
						<button
							onClick={() => setActiveCoin('fiat')}
							type="button"
							className={activeCoin === 'fiat' ? 'active' : ''}
						>
							Fiat
						</button>
					</div>
				) : null}
			</div>
			{chooseTab()}
		</>
	);
};
export default FeeManagementTab;
