import { useParams } from 'react-router';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import {
	changeWalletAddressesPage,
	changeWalletAddressesUserId,
	deleteWalletAddressRequest,
	getWalletAddressesRequest,
	updateWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';
import {
	getWalletAddresses,
	getWalletAddressesLoading,
	getWalletAddressesPageInfo,
	getWalletAddressesPagesCount,
} from 'redux/reducers/walletAddresses/selectors';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { IAccountDetailsParams } from '../../types';
import PaymentMethodCryptoItem from './PaymentMethodCryptoItem/PaymentMethodCryptoItem';
import { IWalletAddressRequestPayload } from '../../../../redux/reducers/walletAddresses/types';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import TradeHistoryRow from '../../TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import Loading from '../../../../layouts-elements/Loading/Loading';

const PaymentMethodCrypto = () => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const walletAddressesData = useSelector(getWalletAddresses);
	const walletAddressesPageInfo = useSelector(getWalletAddressesPageInfo);
	const walletAddressesPagesCount = useSelector(getWalletAddressesPagesCount);
	const permissions = useSelector(getPermissions);
	const loading = useSelector(getWalletAddressesLoading);

	useLayoutEffect(() => {
		dispatch(changeWalletAddressesUserId(Number(userId)));
		dispatch(getWalletAddressesRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const newPageInfo = { ...walletAddressesPageInfo, current_page: selected + 1 };
		dispatch(changeWalletAddressesPage(newPageInfo));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteWalletAddressRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you want to delete the address?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
		dispatch(popUpOpen('warningPopUp'));
	};

	const updateHandler = (obj: IWalletAddressRequestPayload) => {
		dispatch(updateWalletAddressRequest(obj));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--type2 table--payment-method-crypto table--payment-method-crypto--custom">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Wallet Name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Cryptocurrency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Network</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Wallet Address</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{walletAddressesData?.length ? (
										walletAddressesData?.map((el: any) => (
											<PaymentMethodCryptoItem
												key={el.id}
												permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
												deleteHandler={deleteHandler}
												updateHandler={updateHandler}
												{...el}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
					</div>
				</div>
			</div>
			{walletAddressesPagesCount > walletAddressesPageInfo.per_page && (
				<div className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(walletAddressesPagesCount / walletAddressesPageInfo.per_page)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow arrow--prev"
						nextClassName="arrow arrow--next"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)}
		</>
	);
};

export default PaymentMethodCrypto;
