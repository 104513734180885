import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAdminsStore } from './types';

export const admins = (state: IStoreState): IAdminsStore => state.admins;

export const getAdmins = createSelector([admins], (users: IAdminsStore) => users.admins);
export const getAdminsLoading = createSelector(
	[admins],
	(users: IAdminsStore) => users.adminsLoading,
);

export const getAdminsPermissionsList = createSelector(
	[admins],
	(users: IAdminsStore) => users.permissions,
);
