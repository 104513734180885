import { IAccountDetailsParams } from 'components/AccountDetails/types';
import TradingLimitsTable from 'components/tables/TradingLimitsTable';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { useParams } from 'react-router';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { ITradingLimitsRequest } from 'services/api/users/types';
import { getUserTradingLimitsRequest } from 'redux/reducers/users/reducer';
import { getUserLoader, getUserTradingLimits } from 'redux/reducers/users/selectors';

const TradingLimitsTabView = () => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	// const limitsCryptoList = useSelector(getUserCryptoTradingLimits);
	const userTradingLimits = useSelector(getUserTradingLimits);
	const loading = useSelector(getUserLoader);
	// const limitsFiatList = useSelector(getUserFiatTradingLimits);
	const permissions = useSelector(getPermissions);
	const pageCount = userTradingLimits?.last_page || 1;

	const [showCryptoList, setShowCryptoList] = useState(true);
	const handleSwitchLists = () => {
		setShowCryptoList(!showCryptoList);
	};

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		if (userId) {
			const apiParams: ITradingLimitsRequest = {
				type: showCryptoList,
				id: userId,
				params: {
					current_page: currentPage,
					per_page: perPage,
				},
			};
			dispatch(getUserTradingLimitsRequest(apiParams));
		}
		// dispatch(getUserTradingLimitsRequest({ userId }));
	}, [dispatch, userId, showCryptoList, perPage, currentPage]);

	const updateHandler = () => {
		const apiParams: ITradingLimitsRequest = {
			type: showCryptoList,
			id: userId,
			params: {
				current_page: currentPage,
				per_page: perPage,
			},
		};
		dispatch(getUserTradingLimitsRequest(apiParams));
	};

	return (
		<div className="user-management">
			<div className="currencyButtonFile mt-20 mb-20">
				<div className="currencyButton">
					<button
						type="button"
						className={showCryptoList ? 'active' : ''}
						onClick={handleSwitchLists}
					>
						Crypto
					</button>
					<button
						type="button"
						className={showCryptoList ? '' : 'active'}
						onClick={handleSwitchLists}
					>
						Fiat
					</button>
				</div>
			</div>

			<TradingLimitsTable
				limitsList={userTradingLimits}
				permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
				perPage={perPage}
				setPerPage={handleSetPerPage}
				updateHandler={updateHandler}
				handleSetPerPage={handleSetPerPage}
				pageCountValue={pageCount}
				currentPageValue={currentPage}
				setCurrentPage={setCurrentPage}
				loading={loading}
			/>
		</div>
	);
};

export default TradingLimitsTabView;
