import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IDownloadApi } from './types';
// ==========================================:
export const download: IDownloadApi = {
	downloadKey: () => http.post(endpoint.download.GET_DOWNLOAD_KEY).then(({ data }) => data),
	downloadDepositWithdraw: ({ fileName, key, currency, idUser }) =>
		http
			.get(endpoint.download.GET_FILE_DOWNLOAD_DEPOSIT_WITHDRAW(currency, idUser, fileName, key))
			.then(({ data }) => data),
	downloadTrade: ({ fileName, key, idUser }) =>
		http
			.get(endpoint.download.GET_FILE_DOWNLOAD_DEPOSIT_TRADE(idUser, fileName, key))
			.then(({ data }) => data),
	// sup
	supportPost: (body) => http.post(endpoint.support, body).then(({ data }) => data),
	downloadLegder: ({ adminId, extension }) =>
		http
			.get(endpoint.download.GET_FILE_DOWNLOAD_LEDGER(adminId, extension), { responseType: 'blob' })
			.then(({ data }) => data),
};
