import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { responseErrors } from 'services/http/responseErrors';
import { api } from 'services';

import {
	IAddReferralUserRequest,
	IReferralsUserRequest,
	ICommissionResponse,
	IUpdateCommissionRequest,
	IReferralsResponse,
	TReferralUsersList,
	IGetReferralFilePayload,
} from 'services/api/referrals/types';
import { downloadFile } from 'services/utils/downloadFile';
import { IApiPaginationPayload } from 'services/api/types';
import { getSecretTokenForFileDownload } from 'redux/reducers/auth/selectors';
import { notificationsMessagesInfo } from 'services/utils/notificationsMessages/notificationsMessagesInfo';

import {
	addReferralByUserRequest,
	delReferralByUserRequest,
	getCommissionRequest,
	getCommissionSuccess,
	getFreeReferralsRequest,
	getFreeReferralsSuccess,
	getReferralsByUserRequest,
	getReferralsByUserSuccess,
	getReferralsFileRequest,
	getReferralsFileUserIdRequest,
	getReferralsRequest,
	getReferralsSuccess,
	putCommissionRequest,
} from './reducer';
import { popUpOpen, setPopUpData } from '../popUp/reducer';

function* getCommissionWorker() {
	try {
		const response: ICommissionResponse = yield call(api.referrals.getCommission);
		yield put(getCommissionSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* putCommissionWorker({ payload }: PayloadAction<IUpdateCommissionRequest>) {
	try {
		yield call(api.referrals.updateCommission, payload);
		yield put(getCommissionRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getReferralsWorker({ payload }: PayloadAction<IApiPaginationPayload>) {
	try {
		const response: TReferralUsersList = yield call(api.referrals.getReferrals, payload);
		yield put(getReferralsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getFreeReferralsWorker({ payload }: PayloadAction<IApiPaginationPayload>) {
	try {
		const response: IReferralsResponse = yield call(api.referrals.getFreeReferrals, payload);
		yield put(getFreeReferralsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getReferralsByUserWorker({ payload }: PayloadAction<IReferralsUserRequest>) {
	try {
		const response: TReferralUsersList = yield call(api.referrals.getReferralsByUser, payload);

		yield put(getReferralsByUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* addReferralByUserWorker({ payload }: PayloadAction<IAddReferralUserRequest>) {
	try {
		yield call(api.referrals.addReferralByUser, payload);
		yield put(getReferralsByUserRequest({ userId: payload.userId }));
		yield put(getFreeReferralsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* delReferralByUserWorker({ payload }: PayloadAction<IAddReferralUserRequest>) {
	try {
		yield call(api.referrals.delReferralByUser, payload);
		yield put(getReferralsByUserRequest({ userId: payload.userId }));
		yield put(getFreeReferralsRequest({}));
		yield put(setPopUpData({ message: notificationsMessagesInfo.referralDeleteSuccess }));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getReferralsFileWorker({ payload }: PayloadAction<IGetReferralFilePayload>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		const response: string = yield call(api.referrals.getReferralsFile, {
			...payload,
			token: token.secret,
		});
		yield call(() => {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// downloadFile(response, `Referrals File.${payload.fileType}`);
			const { origin } = window.location;
			// window.location.replace(
			// 	`${origin}/api/admin/referral/user/report/${payload.fileType}/${token.secret}/${
			// 		payload?.adminId ? payload?.adminId : '1'
			// 	}`,
			// );
			// eslint-disable-next-line no-debugger
			debugger;
			window.location.replace(
				`${origin}/api/admin/referral/user/report/${payload.fileType}/${token.secret}/0`,
			);
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getReferralsFileIdUserWorker({ payload }: PayloadAction<IGetReferralFilePayload>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		const response: string = yield call(api.referrals.getReferralsFile, {
			...payload,
			token: token.secret,
		});

		yield call(() => {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// downloadFile(response, `Referrals File.${payload.fileType}`);
			const { origin } = window.location;
			// window.location.replace(
			// 	`${origin}/api/admin/referral/user/report/${payload.fileType}/${token.secret}/${
			// 		payload?.adminId ? payload?.adminId : '1'
			// 	}`,
			// );
			window.location.replace(
				`${origin}/api/admin/referral/user/report/${payload.fileType}/${token.secret}/1/${String(
					payload?.userId,
				)}`,
			);
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* referralSaga() {
	yield takeEvery(getCommissionRequest.type, getCommissionWorker);
	yield takeEvery(putCommissionRequest.type, putCommissionWorker);
	yield takeEvery(getFreeReferralsRequest.type, getFreeReferralsWorker);
	yield takeLatest(getReferralsRequest.type, getReferralsWorker);
	yield takeEvery(getReferralsByUserRequest.type, getReferralsByUserWorker);
	yield takeEvery(addReferralByUserRequest.type, addReferralByUserWorker);
	yield takeEvery(delReferralByUserRequest.type, delReferralByUserWorker);
	yield takeEvery(getReferralsFileRequest.type, getReferralsFileWorker);
	yield takeEvery(getReferralsFileUserIdRequest.type, getReferralsFileIdUserWorker);
}
