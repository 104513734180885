import { IMessagesFilter } from 'redux/reducers/messages/types';
import {
	ETransactionsFilters,
	ITransactionsDateFilterValue,
	ITransactionsFilter,
	ITransactionsFromAndToFilterValue,
} from 'redux/reducers/transactionsFilters/types';

export interface IConvertFilterObjectToParamsObjectReturnValue {
	[key: string]: string | number;
}

export const convertFilterObjectToParamsObject = (
	activeFilters: ITransactionsFilter[] | IMessagesFilter[],
): IConvertFilterObjectToParamsObjectReturnValue => {
	const params = {};
	const paramsArr = activeFilters.map((filter) => {
		if (filter.type === ETransactionsFilters.DATE) {
			const value = filter.value as ITransactionsDateFilterValue;
			// const dateTo = new Date(value.date_to.setHours(23, 59, 99, 999));
			const newToDate = new Date(value.date_to);

			newToDate.setHours(newToDate.getHours() + 23);
			newToDate.setMinutes(newToDate.getMinutes() + 59);
			newToDate.setSeconds(newToDate.getSeconds() + 59);
			return {
				date_from: value.date_from,
				date_to: newToDate,
			};
		}
		if (typeof filter.value === 'object') {
			const value = filter.value as ITransactionsFromAndToFilterValue;
			if (value.to && value.from) {
				return {
					[`${filter.type}_from`]: value.from,
					[`${filter.type}_to`]: value.to,
				};
			}
		}
		const { value } = filter;
		return { [filter.type]: value };
	});

	paramsArr.forEach((item) => {
		Object.assign(params, item);
	});

	return params;
};
