/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { getPlatformBalancesRequest } from 'redux/reducers/walletBalance/reducer';
import {
	getWalletBalancePlatformBalancesFiat,
	getWalletBalancePlatformBalancesFiatLoading,
} from 'redux/reducers/walletBalance/selectors';
import { ILiquidityPayload } from 'redux/reducers/walletBalance/types';

import Item from './Item/Item';
import PerPageDropdown from '../../../../services/utils/PaginationPerPageDropdown';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Fiat = () => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletBalancePlatformBalancesFiat);
	const loading = useSelector(getWalletBalancePlatformBalancesFiatLoading);
	const perPage = 10;
	const totalPage = item?.total || 1;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);
	useLayoutEffect(() => {
		const params: ILiquidityPayload = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getPlatformBalancesRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsPerPage]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const params: ILiquidityPayload = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: selected + 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getPlatformBalancesRequest(params));
	};

	const setItemsPerPageHandler = (num: number | string) => {
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table-walletBalance--platformBalance-fiat">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Platform Balance</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{item?.data?.length ? (
										<>
											{item?.data.map((e: any) => {
												return <Item key={e.code} item={e} />;
											})}
										</>
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						<div className="table-footer">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={setItemsPerPageHandler}
									perPage={itemsPerPage}
								/>
								<ReactPaginate
									breakLabel="..."
									pageCount={Math.ceil(totalPage / itemsPerPage)}
									onPageChange={handlePageClick}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {totalPage > perPage && (
				<div style={{ marginBottom: '32px' }} className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default Fiat;
