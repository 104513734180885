/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import { EWithdrawalLimitType, ITradeLimit } from 'services/api/transactions/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	putTradingLimitRequest,
	updateTradingLimitRequest,
} from 'redux/reducers/transactions/reducer';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';

const TradesLimitsRow: FC<ITradeLimit> = ({
	id,
	asset_id,
	limit_min,
	limit_max,
	permission,
	updateHandler,
	type,
}) => {
	const dispatch = useDispatch();
	const [editedMaximum, setEditedMaximum] = useState(limit_max);
	const handleEditMaximum = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedMaximum(Number(event.target.value));
	};
	const [editedMinimum, setEditedMinimum] = useState(limit_min);
	const handleEditMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedMinimum(Number(event.target.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => setEditMode(false);

	useEffect(() => {
		setEditedMaximum(limit_max);
		setEditedMinimum(limit_min);
	}, [limit_max, limit_min]);

	const handleEdit = () => {
		const params = {
			trading_limit_id: id,
			limit_max: editedMaximum,
			limit_min: editedMinimum,
			onSuccess: () => {
				// updateHandler();
				notificationContainer('Trade limits were updated successfully', 'success', 'Changes saved');
			},
		};
		dispatch(putTradingLimitRequest(params));
		dispatch(
			updateTradingLimitRequest({
				id: params.trading_limit_id,
				data: {
					limit_max: params.limit_max,
					limit_min: params.limit_min,
				},
			}),
		);
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedMaximum(limit_max);
		setEditedMinimum(limit_min);
		disableEditMode();
	};

	const currencyData = useSelector(getCurrencyData);

	const filterCurrency = useMemo(() => {
		return currencyData?.find((el) => el.id === asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyData]);

	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				{filterCurrency && (
					<div className="td td--currency">
						<img src={`/img/currencies/${filterCurrency?.code}.svg`} alt={filterCurrency?.name} />
						<p>{filterCurrency?.code?.toUpperCase() || '-'}</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Minimum</p>
				{editMode ? (
					<input
						type="number"
						name="minimum"
						placeholder="0"
						value={editedMinimum || ''}
						onChange={handleEditMinimum}
						onWheel={(event) => event.currentTarget.blur()}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(limit_min, type === EWithdrawalLimitType.CRYPTO ? 'btc' : '')}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Maximum</p>
				{editMode ? (
					<input
						type="number"
						name="maximum"
						placeholder="0"
						value={editedMaximum || ''}
						onChange={handleEditMaximum}
						onWheel={(event) => event.currentTarget.blur()}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<p>{roundingNumber(limit_max, type === EWithdrawalLimitType.CRYPTO ? 'btc' : '')}</p>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn btn--icon btn--check"
							onClick={handleEdit}
						></button>
						<button
							type="button"
							className="btn btn--icon btn--cancel"
							onClick={handleDisableEditMode}
						></button>
					</div>
				)}
				{permission && !editMode && (
					// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
					<button
						type="button"
						className="btn btn--icon btn--edit"
						onClick={enableEditMode}
					></button>
				)}
			</div>
		</div>
	);
};

export default TradesLimitsRow;
