import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { putUserTransferLimitRequest } from 'redux/reducers/users/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { ITransferLimitsTableRowProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { IUpdateTransferLimitRequestPayload } from '../../../../services/api/users/types';

const TransferLimitsTableRow: FC<ITransferLimitsTableRowProps> = ({
	updateHandler,
	limit,
	userId,
	permission,
}) => {
	const dispatch = useDispatch();

	const [showEditing, setShowEditing] = useState<boolean>(false);

	const [minValue, setMinValue] = useState<number>(limit.minimum);
	const [maxValue, setMaxValue] = useState<number>(limit.maximum);
	const [hourlyValue, setHourlyValue] = useState<number>(limit.hourly);
	const [dailyValue, setDailyValue] = useState<number>(limit.daily);

	useLayoutEffect(() => {
		setMinValue(limit.minimum);
		setMaxValue(limit.maximum);
	}, [limit, showEditing]);

	const handleChangeShowEditing = () => {
		setShowEditing(!showEditing);
		setMinValue(limit.minimum);
		setMaxValue(limit.maximum);
		setHourlyValue(limit.hourly);
		setDailyValue(limit.daily);
	};
	const handleChangeMin = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setMinValue(Number(e.target.value));
	}, []);

	const handleChangeMax = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxValue(Number(e.target.value));
	}, []);

	const handleChangeHourly = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setHourlyValue(Number(e.target.value));
	}, []);

	const handleChangeDaily = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setDailyValue(Number(e.target.value));
	}, []);

	const handleClickUpdateLimit = useCallback(() => {
		if (Number(minValue) > Number(maxValue)) {
			setShowEditing(false);
			notificationContainer('Min limit is bigger than max.', 'error');
			return;
		}

		const params: IUpdateTransferLimitRequestPayload = {
			id: limit.id,
			data: {
				minimum: minValue,
				maximum: maxValue,
				hourly: hourlyValue,
				daily: dailyValue,
			},
			onSuccess: () => {
				updateHandler();
				notificationContainer(
					'Transfer limits were updated successfully.',
					'success',
					'Changes saved',
				);
			},
		};

		dispatch(putUserTransferLimitRequest(params));
		setShowEditing(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, limit.id, maxValue, minValue, hourlyValue, dailyValue]);

	return (
		<>
			{!showEditing ? (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							<img
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								src={`/img/currencies/${limit.asset.code}.svg`}
								alt={limit.asset.code}
							/>
							<p>{limit.asset.code.toUpperCase()}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimun</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.minimum, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.maximum, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Hourly Limit</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.hourly, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Daily Limit</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit.daily, limit.asset.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={handleChangeShowEditing}
									className="btn--icon btn--edit"
								/>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="tr tr--editable">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							<img
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								src={`/img/currencies/${limit.asset.code}.svg`}
								alt={limit.asset.code}
							/>
							<p>{limit.asset.code.toUpperCase()}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimum</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={minValue}
									placeholder="0"
									onChange={(e) => handleChangeMin(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={maxValue}
									placeholder="0"
									onChange={(e) => handleChangeMax(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Hourly Limit</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={hourlyValue}
									placeholder="0"
									onChange={(e) => handleChangeHourly(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Daily Limit</p>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="table__input"
									type="number"
									value={dailyValue}
									placeholder="0"
									onChange={(e) => handleChangeDaily(e)}
									onWheel={(event) => event.currentTarget.blur()}
								/>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={handleChangeShowEditing}
								className="btn--icon-danger btn--cancel"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={handleClickUpdateLimit}
								className="btn--icon-success btn--check"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TransferLimitsTableRow;
