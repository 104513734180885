import { FC } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import toastifyErrorHeading from 'assets/img/toast_error.svg';
import { useDispatch } from 'react-redux';
import { IToastifyComponent } from '../types';
import { popUpOpen } from '../../../redux/reducers/popUp/reducer';

const UserBlocked: FC<IToastifyComponent> = ({ message, toastProps, title }) => {
	const dispatch = useDispatch();
	return (
		<div className="toastify-component">
			<div className="toastify-component__heading">
				<img src={toastifyErrorHeading} width="30" height="30" alt="" />
				<p>{title}</p>
			</div>
			{/* eslint-disable-next-line react/no-danger */}
			<div className="toastify-component__content" dangerouslySetInnerHTML={{ __html: message }} />
			<button
				type="button"
				className="toastify-component__btn-light"
				onClick={() => {
					toastProps.closeToast();
					dispatch(popUpOpen('supportSuccess'));
				}}
			>
				Support
			</button>
		</div>
	);
};

export default UserBlocked;
