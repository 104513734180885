import React, { FC, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EApiTransactionStatus } from 'services/api/transactions/types';
import IconSvg from 'ui/Svg/IconSvg';

export interface IDetailedViewSelect {
	selectArray: IHistoryFiltersOptionStringValue[];
	activeElement: IHistoryFiltersOptionStringValue;
	onChange: (activeElement: IHistoryFiltersOptionStringValue) => void;
	disabled?: boolean;
}

const DetailedViewSelect: FC<IDetailedViewSelect> = ({
	selectArray,
	activeElement,
	onChange,
	disabled,
}) => {
	const [opened, setOpened] = useState(false);
	const handleToggleDropdown = () => (disabled ? null : setOpened(!opened));
	const handleCloseDropdown = () => setOpened(false);
	const dropdownElement = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownElement, handleCloseDropdown);

	const handleChooseDropdownItem = (newActiveElement: IHistoryFiltersOptionStringValue) => {
		onChange(newActiveElement);
		handleCloseDropdown();
	};

	return (
		<div className="input">
			<p className="input__name">Status</p>

			<div className={`select select--detailed ${opened ? 'active' : ''}`} ref={dropdownElement}>
				<button type="button" className="select__current" onClick={handleToggleDropdown}>
					{activeElement?.name || ''}
					<span className="select__current-arrow">
						<IconSvg name="keyboard-arrow-down" w="9" h="6" />
					</span>
				</button>
				<AnimatePresence>
					{opened && (
						<motion.div
							className="select__drop"
							style={{ display: 'block' }}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.3 } }}
							exit={{ opacity: 0, transition: { duration: 0.3 } }}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										{!!selectArray?.length &&
											selectArray.map((item) => (
												<li key={item.id}>
													<button
														type="button"
														// className={getButtonClassName(item.value)}
														onClick={() => handleChooseDropdownItem(item)}
													>
														{item.name}
													</button>
												</li>
											))}
									</ul>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default DetailedViewSelect;
