/* eslint-disable react-hooks/exhaustive-deps */

import { useDebounce } from 'hooks/useDebounce';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';

import { IUsersRequest } from 'services/api/users/types';
import IconSvg from 'ui/Svg/IconSvg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import {
	TWO_FA_STATUS_NUM_ACCEPT,
	TWO_FA_STATUS_NUM_NEW,
	TWO_FA_STATUS_NUM_REJECT_2,
} from 'redux/reducers/users/constants';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import { ISearchSelectItem, IUsersSearchProps } from '../types';
import { EPermissionNames } from '../../../../redux/reducers/auth/types';
import { resetActiveBankAccounts } from '../../../../redux/reducers/bankAccounts/reducer';
import { resetFilters } from '../../../../redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from '../../../../redux/reducers/transactionsFilters/types';
import { getPermissions } from '../../../../redux/reducers/auth/selectors';
import { ROUTES } from '../../../../routes/constants';

const arr = [
	{ name: 'User ID', value: 'user_id' },
	{ name: 'Email', value: 'email' },
	{ name: 'Company Name', value: 'company_name' },
	{ name: 'Full Name', value: 'full_name' },
];

export const TWO_FA_STATUS_OPTIONS = [
	{
		id: 1,
		name: 'All',
		value: '',
	},
	{
		id: 2,
		name: 'New',
		value: TWO_FA_STATUS_NUM_NEW,
	},
	{
		id: 3,
		name: 'Accepted',
		value: TWO_FA_STATUS_NUM_ACCEPT,
	},
	{
		id: 4,
		name: 'Rejected',
		value: TWO_FA_STATUS_NUM_REJECT_2,
	},
];

interface ITwoFAStatusOption {
	id: number;
	name: string;
	value: number;
}

export interface IResetRequestSearchProps {
	total: number | undefined;
	setQuery: Dispatch<SetStateAction<IUsersRequest>>;
	tab?: string;
	leftColum?: boolean;
	children?: any;
	selectFieldChildren?: boolean;
	resetSelect?: any;
	isResetable?: boolean;
	setPage?: any;
}

const ResetRequestSearch: FC<IResetRequestSearchProps> = ({
	total,
	setQuery,
	tab,
	leftColum = true,
	children,
	selectFieldChildren,
	resetSelect,
	isResetable,
	setPage,
}) => {
	const permissions = useSelector(getPermissions);
	const history = useHistory();
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState<ISearchSelectItem>({
		name: 'User ID',
		value: 'user_id',
	});
	const [searchValue, setSearchValue] = useState('');
	const [opened, setOpened] = useState(false);
	const debouncedSearch = useDebounce(searchValue, 500);
	const selectBlockRef = useRef<HTMLDivElement>(null);
	const [searchArr, setSearchArr] = useState<
		| {
				name: string;
				value: string;
		  }[]
		| []
	>([]);
	const [reset, setReset] = useState(false);

	const arrReset = [
		{
			name: tab === 'users' ? 'User ID' : 'Admin ID',
			value: tab === 'users' ? 'user_id' : 'admin_id',
		},
		{
			name: 'Email',
			value: 'email',
		},
		{
			name: 'Full Name',
			value: 'full_name',
		},
	];
	useLayoutEffect(() => {
		if (tab) {
			setSearchArr(arrReset);
			if (tab === 'admin') {
				setSearchValue('');
				setSearchField({
					name: 'Admin ID',
					value: 'user_id',
				});
			} else if (tab === 'users') {
				setSearchValue('');
				setSearchField({
					name: 'User ID',
					value: 'user_id',
				});
			}
			if (tab === 'admin_tab') {
				setSearchField({
					name: 'Admin ID',
					value: 'admin_id',
				});
			}

			// else {
			// 	setSearchField({
			// 		name: 'User ID',
			// 		value: 'user_id',
			// 	});
			// }
		} else setSearchArr(arr);
	}, [tab]);

	const handleSelect = useCallback((el: ISearchSelectItem) => {
		setSearchField(el);
		setSearchValue('');
		setOpened(false);
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReset(false);
		setSearchValue(e.target.value);
		setPage(1);
	};

	const toggleSelect = () => {
		setOpened(!opened);
	};

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectBlockRef.current?.contains(event.target as Node)) {
				setOpened(false);
			}
		};

		if (opened) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [opened]);

	useLayoutEffect(() => {
		if (debouncedSearch && searchValue) {
			if (tab === 'admin' || tab === 'admin_tab' || tab === 'user') {
				setQuery((prevState: IUsersRequest) => ({
					...prevState,
					[searchField.value]: debouncedSearch,
					current_page: 1,
				}));
			}
			setQuery((prevState: IUsersRequest) => ({
				...prevState,
				search_field: searchField.value,
				search_value: debouncedSearch,
				// [searchField.value]: debouncedSearch,
				current_page: 1,
			}));
			return;
		}
		setQuery((prevState) => ({
			per_page: prevState.per_page,
			current_page: 1,
			status_field: prevState.status_field,
			status_value: prevState.status_value,
		}));
	}, [debouncedSearch, searchField.value, setQuery]);

	const resetHandler = () => {
		setSearchArr(arrReset);
		setSearchValue('');
		setReset(true);
		if (tab === 'admin') {
			setSearchField({
				name: 'Admin ID',
				value: 'user_id',
			});
		} else if (tab === 'users') {
			setSearchField({
				name: 'User ID',
				value: 'user_id',
			});
		}
		setQuery((prevState) => ({
			per_page: prevState.per_page,
			current_page: 1,
		}));
	};

	const handleStatusChange = (option: ITwoFAStatusOption) => {
		setReset(false);
		if (option.value) {
			setQuery((prevState) => ({
				...prevState,
				status_field: 'status',
				status_value: option.value,
			}));
		} else {
			setQuery((prevState) => ({
				per_page: prevState.per_page,
				current_page: 1,
				search_field: prevState.search_field,
				search_value: prevState.search_value,
			}));
		}
	};

	return (
		<div className="table-filter table-filter--reset-request flex-m">
			<div className="filter__total">
				{total ? (
					<>
						{total === 1 && <>{total} Request</>}
						{total > 1 && <>{total} Requests</>}
					</>
				) : (
					<>0 Requests</>
				)}
			</div>
			<div className="filter__wrap">
				<div className="filter__status">
					<p>Status</p>
					<FilterSelect
						options={TWO_FA_STATUS_OPTIONS}
						selected={TWO_FA_STATUS_OPTIONS[0]}
						onChange={handleStatusChange}
						reset={reset}
					/>
				</div>
				<div className="filter">
					<div className="search-input-wrap">
						<div className="search-input">
							<div
								ref={selectBlockRef}
								className={`select select--type4 ${opened ? 'active' : ''}`}
							>
								<button type="button" className="select__current" onClick={toggleSelect}>
									{searchField?.name}
									<span className="select__current-arrow">
										<IconSvg name="keyboard-arrow-down" w="9" h="5" />
									</span>
								</button>
								<AnimatePresence>
									{opened && (
										<motion.div
											className="select__drop"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1, transition: { duration: 0.3 } }}
											exit={{ opacity: 0, transition: { duration: 0.3 } }}
										>
											<div className="select__drop-scroll">
												<div className="select__drop-item">
													<ul>
														{searchArr.map((el) => (
															<li key={el.value}>
																<button type="button" onClick={() => handleSelect(el)}>
																	{el.name}
																</button>
															</li>
														))}
													</ul>
												</div>
											</div>
										</motion.div>
									)}
								</AnimatePresence>
							</div>

							<div className="input input--mb-0">
								<div className="input-wrapper">
									<input
										className="input-item input-item--type2 input-item--pr50"
										type="text"
										placeholder={`Search by ${searchField.name}`}
										value={searchValue}
										onChange={handleChange}
									/>
									<div className="input-icon input-icon--auto input-icon--right">
										<IconSvg name="search" w="24" h="24" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="filter--reset">
					<button type="button" className="btn btn-reset" onClick={resetHandler}>
						Reset
					</button>
				</div>
			</div>
		</div>
	);
};

export default ResetRequestSearch;
