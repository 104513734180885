import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import ReferralsByUser from './ReferralsByUser';
import ReferralsTab from './ReferralsTab';
import TradesTab from './TradesTab';

const ReferralSystem = () => (
	<>
		<div className="title-block-wrap">
			<div className="title-block">
				<p className="title">Referral Management</p>
			</div>
		</div>
		<div>
			<Switch>
				<Route exact component={ReferralsTab} path={ROUTES.referralSystem.referrals} />
				<Route exact component={ReferralsByUser} path={ROUTES.referralSystem.referralsByUser} />
				<Route exact component={TradesTab} path={ROUTES.referralSystem.trades} />
				<Redirect to={ROUTES.referralSystem.referrals} />
			</Switch>
		</div>
	</>
);

export default ReferralSystem;
