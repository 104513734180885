/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IRequestPayloadData,
	IRequestPayloadTwoFaData,
	IConfirmationStore,
	ITwoFaResponse,
} from './types';

// ==========================================:
export const initialState: IConfirmationStore = {
	token: null,
	twoFa: null,
	confirmationPasswordLoader: false,
	confirmationTwoFaLoader: false,
	confirmationTwoCodeFaLoader: false,
};

// ==========================================:
const confirmation = createSlice({
	name: '@@confirmation',
	initialState,
	reducers: {
		confirmationPasswordRequest: (state, action: PayloadAction<IRequestPayloadData>) => {
			const { payload } = action;
			const stateData = state;
			stateData.token = payload?.token;
			stateData.confirmationPasswordLoader = true;
		},
		confirmationPasswordSuccess: (state) => {
			const stateData = state;
			stateData.confirmationPasswordLoader = false;
		},
		confirmationTwoFaRequest: (state, action: PayloadAction<IRequestPayloadData>) => {
			const stateData = state;
			stateData.confirmationTwoFaLoader = true;
		},
		confirmationTwoFaSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateData = state;
			stateData.twoFa = payload;
			stateData.confirmationTwoFaLoader = false;
		},
		confirmationTwoFaCodeRequest: (state, action: PayloadAction<IRequestPayloadTwoFaData>) => {
			const stateData = state;
			stateData.confirmationTwoCodeFaLoader = true;
		},
		confirmationTwoFaCodeSuccess: (state) => {
			const stateData = state;
			stateData.confirmationTwoCodeFaLoader = false;
		},
		confirmationInitState: () => initialState,
	},
});
export default confirmation.reducer;
export const {
	confirmationPasswordRequest,
	confirmationPasswordSuccess,
	confirmationTwoFaRequest,
	confirmationTwoFaSuccess,
	confirmationTwoFaCodeRequest,
	confirmationTwoFaCodeSuccess,
	confirmationInitState,
} = confirmation.actions;
