import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const fee: any = {
	getFee: (params: any) => http.get(endpoint.fee.GET_FEE(), { params }).then(({ data }) => data),
	putFee: (body: any) => http.put(endpoint.fee.PUT_FEE(), { ...body }).then(({ data }) => data),
	getSpread: (params: any) =>
		http.get(endpoint.fee.GET_SPREAD(), { params }).then(({ data }) => data),
	putSpread: (body: any) =>
		http.put(endpoint.fee.PUT_SPREAD(), { ...body }).then(({ data }) => data),
	getCurrencies: (params: any) =>
		http.get(endpoint.fee.GET_CURRENCLES(), { params }).then(({ data }) => data),
	putCurrencies: (body: any) =>
		http.put(endpoint.fee.PUT_CURRENCLES(), { ...body }).then(({ data }) => data),
};
