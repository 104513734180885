import { FC } from 'react';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { IPaginationProps } from './types';

const Pagination: FC<IPaginationProps> = ({ pageCount, currentPage, onPageChange }) => {
	return (
		<ReactPaginate
			pageCount={pageCount}
			forcePage={currentPage - 1}
			onPageChange={({ selected }) => onPageChange(selected + 1)}
			className="pagination"
			activeClassName="active"
			previousClassName="pagination__arrow pagination__arrow--prev"
			nextClassName="pagination__arrow pagination__arrow--next"
			previousLabel=""
			nextLabel=""
		/>
	);
};

export default Pagination;
