/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { FC, useRef, useState } from 'react';
import { IDepositProps } from 'redux/reducers/walletBalance/types';

const DepositAccountCryptoItem: FC<IDepositProps> = ({
	id,
	bank_address,
	bank_name,
	beneficiary_address,
	beneficiary_name,
	swift_bic,
	account_number,
	permission,
	note,
	asset,
	updateHandler,
	deleteHandler,
}) => {
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Beneficiary Name</p>
					<p className="td-title-name">{beneficiary_name}</p>
					<p className="td-hidden-name">Beneficiary Address</p>
					<p className="td-subtitle-name">{beneficiary_address}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Bank Name</p>
					<p className="td-title-name">{bank_name}</p>
					<p className="td-hidden-name">Bank Address</p>
					<p className="td-subtitle-name">{bank_address}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p className="td-title-name">{asset?.code.toUpperCase()}</p>
					<p className="td-subtitle-name">{asset?.name}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">SWIFT BIC</p>
					<p>{swift_bic}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Account Number</p>
					<p>{account_number.length > 15 ? `${account_number.slice(0, 15)}...` : account_number}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Note</p>
					<p>{note}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Action</p>
					<div className="table-buttons">
						{permission && (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label
							<>
								<button
									type="button"
									className="btn btn--icon btn--edit"
									onClick={() => updateHandler(id)}
								/>
								<button
									type="button"
									className="btn btn--icon-danger btn--delete"
									onClick={() => deleteHandler(id, bank_name)}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default DepositAccountCryptoItem;
