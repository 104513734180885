import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import image from 'assets/dist/img/email-icon.svg';
import { useDispatch } from 'react-redux';
import { forgotPasswordPopupClose } from 'redux/reducers/auth/reducer';
import { IPropsPopUp } from './types';

const ResetPasswordPopUp: FC<IPropsPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const onClose = () => {
		dispatch(forgotPasswordPopupClose());
		closeModal();
	};
	return (
		<Popup open={open} onClose={onClose} closeOnDocumentClick>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Request Sent</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#0071AB"
							stroke="#0071AB"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M29.0013 29.3776L21.918 24.7318V34.4193H30.043V35.6693H21.918C21.5846 35.6693 21.293 35.5443 21.043 35.2943C20.793 35.0443 20.668 34.7526 20.668 34.4193V23.5859C20.668 23.2526 20.793 22.9609 21.043 22.7109C21.293 22.4609 21.5846 22.3359 21.918 22.3359H36.0846C36.418 22.3359 36.7096 22.4609 36.9596 22.7109C37.2096 22.9609 37.3346 23.2526 37.3346 23.5859V30.0443H36.0846V24.7318L29.0013 29.3776ZM29.0013 28.1276L36.0846 23.5859H21.918L29.0013 28.1276ZM35.1263 38.0859L34.2513 37.2109L35.7721 35.6693H31.293V34.4193H35.7721L34.2305 32.8776L35.1263 32.0026L38.168 35.0443L35.1263 38.0859ZM21.918 24.7318V35.1276V30.0443V30.1276V23.5859V24.7318Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--mt popup-text--mb-0 popup-text--center">
						<p>
							Your password reset request is being reviewed. Once approved you will receive a reset
							via email.
						</p>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ResetPasswordPopUp;
