interface IErrorsMap {
	[index: string]: string;
}

export const notificationsMessagesError: IErrorsMap = {
	bankAccounts: 'Bank accounts were not found',
	bonusFound: 'Bonus Wallets not found',
	referralsFound: 'Referrals count not found',
	bonusCompleted: 'Bonus Transfer was not completed',
	bankNotCreated: 'Bank account has not been created',
	transactionFailed: 'Transaction failed',
	enterTwoFa: 'Please, enter your 2FA code',
	walletAddress: 'Wallet address were not found',
	walletAddressDeleted: 'Wallet address has not been deleted',
};

export const notificationsMessagesErrorServers: IErrorsMap = {
	invalid_vault_id: 'invalid vault_id',
	empty_fireblock_vault_id_in_user: 'empty fireblock vault id in user',
	zero_asset_balance: 'zero asset balance',
	referral_already_exists: 'referral already exists',
	error_while_deleting: 'error while deleting',
	action_rejected: 'action rejected',
	transaction_is_not_in_pending_status: 'transaction is not_in ending status',
	the_frozen_balance_is_less_than_the_withdrawal_amount:
		'the frozen balance is less than the withdrawal amount',
	user_already_rejected: 'user already rejected',
	user_already_approved: 'user already approved',
	user_not_verified: 'user not verified',
	two_fa_already_disabled: 'Two fa already disabled',
	the_balance_is_less_than_the_amount: 'the balance is less than the amount',
	the_frozen_balance_is_less_than_the_amount: 'the frozen balance is less than the amount',
	min_limit: 'min limit',
	max_limit: 'max limit',
	you_can_not_exchange_the_same_asset: 'you can not exchange the same asset',
	asset_is_not_exchangeable: 'asset is not exchangeable',
	deny_exchange_fiat_to_fiat: 'deny exchange fiat to fiat',
	wrong_asset_for_exchange: 'wrong_asset_for_exchange',
	external_order_creation_error: 'external order creation error',
	exchange_rate_not_available: 'value must be between_0 100',
	value_must_be_between_0_100: 'value must be between 0 100',
	transaction_error: 'transaction error',
	asset_is_not_withdrawable: 'asset is not withdrawable',
	minimum_limit: 'minimum limit',
	hour_limit: 'hour limit',
	daily_limit: 'daily limit',
	asset_is_not_depositable: 'asset is not depositable',
	invalid_transaction_type: 'invalid transaction type',
	invalid_transaction_id: 'invalid transaction id',
	you_can_update_only_withdraw_address: 'you can update only withdraw address',
	you_can_delete_only_withdraw_address: 'you can delete only withdraw address',
	fireblocks_error: 'fireblocks error',
	max_amount_must_be_lower_or_equal_2: 'max amount must be lower or equal 2',
};

export const Errors: IErrorsMap = {
	the_user_datafirst_name_must_be_a_string: 'Users firstname must be a string.',
	two_fa_already_disabled: 'Two fa already disabled.',
	invalid_totp_code: 'Invalid totp code.',
	the_totp_field_is_required: 'The totp field is required.',
	already_exist: 'Already exist.',
	'commission_cannot_be_more_than100%': 'The commission cannot be more than 100%.',
	the_useremail_has_already_been_taken: 'The useremail has already been taken.',
	token_incorrect: 'Token incorrect.',
	the_user_datafireblocks_vault_id_has_already_been_taken:
		'The user data fireblocks vault id has already been taken.',
	fireblocks_vault_not_exists_yet: 'Fireblocks vault not exists yet',
	fireblocks_vault_already_in_use: 'Fireblocks vault already in use',
};
