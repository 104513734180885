import React, { useState, useLayoutEffect } from 'react';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { useDispatch } from 'react-redux';
import DataTable from './DataTable';
// import CryptoTab from './DataTable';

const TransferLimitsTab = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState('crypto');

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	const handleChangeTab = (e: any) => {
		const { name } = e.target;
		if (name === 'crypto' || name === 'fiat') {
			setActiveTab(name);
		}
	};

	return (
		<div className="user-management">
			<div className="currencyButtonFile mb-20">
				<div className="currencyButton" onClick={handleChangeTab}>
					<button type="button" name="crypto" className={activeTab === 'crypto' ? 'active' : ''}>
						Crypto
					</button>
					<button type="button" name="fiat" className={activeTab === 'fiat' ? 'active' : ''}>
						Fiat
					</button>
				</div>
			</div>
			<DataTable type={activeTab} />
		</div>
	);
};

export default TransferLimitsTab;
