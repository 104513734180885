import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IСonfirmationApi } from './types';
// ==========================================:

export const confirmation: IСonfirmationApi = {
	confirmationPassword: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_PASSWORD, payload).then((response) => {
			return response.data;
		}),
	confirmationTwoFa: (params) =>
		http
			.get(endpoint.confirmation.CONFIRMATION_TWO_FA, { params })
			.then((response) => response.data),
	confirmationTwoFaRegistration: (payload) =>
		http.post(endpoint.confirmation.CONFIRMATION_ENABLE_CHANGE_TWO_FA, payload).then((response) => {
			return response.data;
		}),
};
