import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { IWalletBalanceStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): IWalletBalanceStore => state.walletBalance;

export const getAssets = createSelector([getAuthState], (currency: any) => {
	return currency?.assets;
});

export const getWalletBalanceLiquidityCrypto = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.liquidityCrypto;
	},
);
export const getWalletBalanceLiquidityCryptoLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.liquidityCryptoLoading;
	},
);
export const getWalletBalanceLiquidityFiat = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.liquidityFiat;
	},
);
export const getWalletBalanceLiquidityFiatLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.liquidityFiatLoading;
	},
);
//
export const getWalletBalancePlatformBalancesCrypto = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesCrypto;
	},
);

export const getWalletBalancePlatformBalancesCryptoLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesCryptoLoading;
	},
);
export const getWalletBalancePlatformBalancesFiat = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.PlatformBalancesFiat;
	},
);
export const getWalletBalancePlatformBalancesFiatLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.PlatformBalancesFiatLoading;
	},
);
//
export const getWalletBalanceDepositsWalletsCrypto = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.DepositsWalletsCrypto;
	},
);

export const getWalletBalanceDepositsWalletsCryptoLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.DepositsWalletsCryptoLoading;
	},
);

export const getWalletBalanceDepositsWalletsFiat = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.DepositsWalletsFiat;
	},
);
//
export const getWalletBalancePendingFeesCrypto = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PendingFeesCrypto;
	},
);
export const getWalletBalancePendingFeesCryptoLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PendingFeesCryptoLoading;
	},
);

export const getWalletBalancePendingFeesTotal = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PendingFeesTotal;
	},
);
// User
export const getWalletBalancePlatformBalancesUserCrypto = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesUserCrypto;
	},
);
export const getWalletBalancePlatformBalancesUserCryptoLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesUserCryptoLoading;
	},
);
export const getWalletBalancePlatformBalancesUserFiat = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesUserFiat;
	},
);
export const getWalletBalancePlatformBalancesUserFiatLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformBalancesUserFiatLoading;
	},
);
//

export const getWalletsUserBalancesDepositWallets = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformDepositWallets;
	},
);
export const getWalletsUserBalancesDepositWalletsLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency?.PlatformDepositWalletsLoading;
	},
);
//

export const getWalletBalancePendingFeesFiat = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.PendingFeesFiat;
	},
);
export const getWalletBalancePendingFeesFiatLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.PendingFeesFiatLoading;
	},
);
export const getDepositAccounts = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.DepositAccounts;
	},
);
export const getDepositAccountsLoading = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.DepositAccountsLoading;
	},
);
export const getUserDepositAccounts = createSelector(
	[getAuthState],
	(currency: IWalletBalanceStore) => {
		return currency.UserDepositAccounts;
	},
);
//
