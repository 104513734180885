import React, { useLayoutEffect, FC, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import {
	getUserFeeRequest,
	putUserFeesRequest,
	updateUserFees,
} from 'redux/reducers/users/reducer';
import { getUsersFees, getUsersFeesLoader } from 'redux/reducers/users/selectors';
import { IUserFeesUpdteBody, IUserFeesData } from 'redux/reducers/users/types';
import FeeManagementDepositItem from './FeeManagementDepositItem/FeeManagementDepositItem';
import { IFeesProps } from './types';
import { IAccountDetailsParams } from '../../types';
import FeeManagementWithdrawItem from '../FeeManagementWithdraw/FeeManagementWithdrawItem/FeeManagementWithdrawItem';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const FeeManagementDeposit: FC<IFeesProps> = ({ activeCoin, permission }) => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const loading = useSelector(getUsersFeesLoader);

	const usersFees = useSelector(getUsersFees);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = usersFees?.last_page || 1;

	useLayoutEffect(() => {
		if (userId) {
			const params: IUserFeesData = {
				type: activeCoin,
				id: userId,
				current_page: currentPage,
				per_page: perPage,
			};
			dispatch(getUserFeeRequest(params));
		}
		// const params: IUserFeesData = {
		// 	type: activeCoin,
		// 	current_page: currentPage,
		// 	per_page: perPage,
		// };
		// dispatch(getUserFeeRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userId, activeCoin, perPage, currentPage]);

	const updateHandler = (obj: IUserFeesUpdteBody) => {
		dispatch(
			putUserFeesRequest({
				id: userId,
				body: obj,
				current_page: currentPage,
				per_page: perPage,
				type: activeCoin,
				feeType: 'Deposit',
			}),
		);
		dispatch(updateUserFees(obj));
	};
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div
						className={`table ${
							activeCoin === 'crypto'
								? 'table--user-management-fee-management-deposit-crypto'
								: 'table--user-management-fee-management-deposit-fiat'
						}`}
					>
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{usersFees?.data?.length ? (
										usersFees?.data?.map((el: any) => (
											<FeeManagementDepositItem
												key={el.id}
												updateHandler={updateHandler}
												el={el}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{!!usersFees?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!usersFees?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeManagementDeposit;
