import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';

interface IBankCurrencySelectCustomInput {
	label: string;
	value: string | number;
	name: string;
	id: string | number;
}

interface IBankCurrencySelectCustomList {
	arr: IBankCurrencySelectCustomInput[];
	onChange: (object: IBankCurrencySelectCustomInput) => void;
}

const BankCurrencySelectCustomInput: FC<IBankCurrencySelectCustomInput> = ({
	label,
	value,
	name,
	id,
}) => (
	<span className="coin coin--type8 flex-row">
		{label && (
			<span className="coin__icon">
				<img src={`/img/currencies/${label.toLowerCase()}.svg`} alt={label} />
			</span>
		)}
		<span
			className="coin__text coin__text--text-black coin__text--fz-14"
			style={{ display: 'flex', alignItems: 'center' }}
		>
			{label.toUpperCase() || 'Select Currency'}
			{name && (
				<span
					className="coin__text-more"
					style={{
						marginLeft: '6px',
						width: 'calc(100% - 30px)',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
				>
					{name}
				</span>
			)}
		</span>
	</span>
);

const BankCurrencySelectCustomList: FC<IBankCurrencySelectCustomList> = ({ arr, onChange }) => {
	return (
		<>
			{arr?.length ? (
				<ul>
					{arr.map((el) => (
						<li key={el.value}>
							<button type="button" onClick={() => onChange(el)}>
								<span className="coin coin--type10 coin__text-normal">
									<span className="coin__icon">
										<img src={`/img/currencies/${el.label.toLowerCase()}.svg`} alt={el.label} />
									</span>
									<span className="coin__text">
										{el.label.toUpperCase()}
										<span className="coin__text-more">{el.name}</span>
									</span>
								</span>
							</button>
						</li>
					))}
				</ul>
			) : (
				<span className="coin coin--type3 coin__text-normal">
					<span className="coin__text upper__text coin__text---pt-0">No Items</span>
				</span>
			)}
		</>
	);
};

const BankCurrencySelect: FC<any> = ({
	name,
	title,
	touched,
	arr,
	resetCustomSelect,
	setTouched,
	setResetCustomSelect,
	onChange,
	activeValue,
	isDisabled,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			label: '',
			value: '',
			name: '',
			id: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] =
		useState<IBankCurrencySelectCustomInput>(initActiveCurrency);
	const [searchArr, setSearchArr] = useState<any[]>([]);

	useEffect(() => {
		if (arr) {
			setSearchArr(arr);
		}
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: any) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: <BankCurrencySelectCustomList arr={searchArr} onChange={onChangeHandler} />,
		activeElement: activeCurrency.label,
	};

	useEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	return (
		<>
			<p className="input__name">{title}</p>
			<Select
				{...props}
				setTouched={setTouched}
				touched={touched && !activeValue?.value}
				customInput={<BankCurrencySelectCustomInput {...activeCurrency} />}
				setSearchArr={setSearchArr}
				customList={customList}
				arr={arr}
				isDisabled={!!isDisabled}
			/>
		</>
	);
};

export default BankCurrencySelect;
