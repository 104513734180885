import { IUserWalletsItem } from '../../../services/api/users/types';

export enum SortedButton {
	platform_balance = 'Platform Balance',
	deposit = 'Deposit Wallet',
	code_currnecy = 'Cryptocurrency',
	code = 'Currency',
	total = 'Total',
	fireblock = 'On Fireblock',
}

export interface IWalletsTable {
	title: string;
	type: ECoinType;
	perPage: number;
	permission?: boolean;
	walletsList: IUserWalletsItem[] | null;
}

export enum ECoinType {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
}

export interface IWalletsTableHeader {
	type?: ECoinType;
	sortOrder: string;
	setSortOrder: (val: string) => void;
	sortItem: string;
	setSortItem: (val: string) => void;
}

export interface IWalletsTableHeaderButton {
	title: string;
	sortOrder: string;
}

export interface ISortedButton extends IWalletsTableHeader, IWalletsTableHeaderButton {}
