import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EApiCryptoTransactionStatus, EApiTransactionType } from 'services/api/transactions/types';
import { getOneUserRequest } from 'redux/reducers/users/reducer';
import { getOneUser } from 'redux/reducers/users/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';

import Loading from 'layouts-elements/Loading/Loading';

import {
	getCryptoHistory,
	getHistoryLoading,
} from '../../../redux/reducers/transactions/selectors';
import { getCryptoHistoryRequest } from '../../../redux/reducers/transactions/reducer';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import DefaultFields from '../DefaultFields/DefaultFields';
import DetailedViewCryptoForm from '../DetailedCryptoForm/DetailedCryptoForm';
import DetailedCryptoWithdrawalPendingForm from '../DetailedCryptoForm/DetailedCryptoWithdrawalPendingForm';
import {
	getCryptoDetails,
	getDetailedViewLoading,
} from '../../../redux/reducers/detailedView/selectors';
import { getCryptoDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface IDeposiCryptoDetails {
	txId: string | number;
	userId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const WithdrawalCryptoDetails: FC<IDeposiCryptoDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	// const loading = useSelector(getHistoryLoading);
	const loading = useSelector(getDetailedViewLoading);
	const currentItemData = useSelector(getCryptoHistory);
	const cryptoDetails = useSelector(getCryptoDetails);

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (txId && userId) {
			// const params: IGetTransactionHistoryRequestPayload = {
			// 	apiParams: {
			// 		userId,
			// 		type: EApiTransactionType.WITHDRAWAL,
			// 		current_page: 1,
			// 		per_page: 1,
			// 		id: Number(txId),
			// 	},
			// };
			// dispatch(getCryptoHistoryRequest(params));
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId, userId]);

	return (
		<>
			{cryptoDetails && !loading && userData ? (
				<div className="detailed-view" key={cryptoDetails.id}>
					{cryptoDetails.status.includes('pending') ? (
						<DetailedCryptoWithdrawalPendingForm item={cryptoDetails} userData={userData} />
					) : (
						<DetailedViewCryptoForm item={cryptoDetails} userData={userData} />
					)}
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default WithdrawalCryptoDetails;
