import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ISidebarStatesStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): ISidebarStatesStore => state.sidebarStates;
export const getSidebar = createSelector(
	[getAuthState],
	(sidebarStates: ISidebarStatesStore) => sidebarStates,
);

// ====================================================:
export const getMinimizedSidebarState = createSelector(
	[getSidebar],
	(sidebarStates: ISidebarStatesStore): boolean => sidebarStates.minimizedSidebar,
);
// ====================================================:
export const getMobileSidebarState = createSelector(
	[getSidebar],
	(sidebarStates: ISidebarStatesStore): boolean => sidebarStates.mobileSidebar,
);
