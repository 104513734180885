import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyDataRequest } from '../../../redux/reducers/currency/reducer';
import Loading from '../../../layouts-elements/Loading/Loading';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import { getOneUserRequest } from '../../../redux/reducers/users/reducer';
import { getOneUser } from '../../../redux/reducers/users/selectors';
import DetailedDepositFiatForm from '../DetailedDepositFiatForm/DetailedDepositFiatForm';
import {
	getDetailedViewLoading,
	getFiatDetails,
} from '../../../redux/reducers/detailedView/selectors';
import { getFiatDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface IDepositFiatDetails {
	txId: string | number;
}

const DepositFiatDetails: FC<IDepositFiatDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	// const fiatTransactions = useSelector(getFiatDepositsList);
	// const loading = useSelector(getOneUserLoading);
	const loading = useSelector(getDetailedViewLoading);
	const fiatDetails = useSelector(getFiatDetails);

	useEffect(() => {
		if (txId) {
			// const params: IApiGetTransactionParams = {
			// 	type: EApiTransactionType.DEPOSIT,
			// 	current_page: 1,
			// 	per_page: 1,
			// 	id: Number(txId),
			// };
			// dispatch(getFiatDepositsRequest(params));
			dispatch(getFiatDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	useEffect(() => {
		fiatDetails &&
			fiatDetails.user_id &&
			dispatch(getOneUserRequest({ userId: fiatDetails.user_id }));
	}, [dispatch, fiatDetails]);

	useEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{fiatDetails && !loading && userData ? (
				<div className="detailed-view" key={fiatDetails.id}>
					<DetailedDepositFiatForm item={fiatDetails} />
				</div>
			) : (
				<>{loading ? <Loading /> : <TableBodyNoData />}</>
			)}
		</>
	);
};

export default DepositFiatDetails;
