import React from 'react';

const TableBodyNoData = () => {
	return (
		<div className="table-no-data-body">
			<div className="table-no-data-body-plug">
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="30" cy="30" r="30" fill="#F5F5F5" />
					<path
						d="M43.5554 29.4272V16.6254C43.5554 16.6254 39.0721 12.4294 38.4537 11.6523H15.7281V29.4272V48.9337H43.5554V29.4272Z"
						fill="white"
					/>
					<rect
						x="25.7764"
						y="34.3535"
						width="11.2476"
						height="6.76327"
						transform="rotate(-44.0963 25.7764 34.3535)"
						fill="url(#paint0_linear_1306_44897)"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M29.6419 38.1218C33.9169 38.1218 37.3825 34.6563 37.3825 30.3812C37.3825 26.1062 33.9169 22.6406 29.6419 22.6406C25.3668 22.6406 21.9012 26.1062 21.9012 30.3812C21.9012 34.6563 25.3668 38.1218 29.6419 38.1218ZM29.6419 39.1218C32.1763 39.1218 34.4588 38.0431 36.0553 36.3198L37.1083 37.4092C36.9659 37.7943 37.0528 38.244 37.3662 38.5486L42.7385 43.7703C43.1624 44.1823 43.84 44.1727 44.252 43.7488C44.664 43.3249 44.6543 42.6474 44.2305 42.2354L38.8581 37.0136C38.552 36.7161 38.1136 36.6384 37.7385 36.7779L36.6305 35.6316C37.7305 34.1697 38.3825 32.3516 38.3825 30.3812C38.3825 25.5539 34.4692 21.6406 29.6419 21.6406C24.8145 21.6406 20.9012 25.5539 20.9012 30.3812C20.9012 35.2085 24.8145 39.1218 29.6419 39.1218Z"
						fill="#3B97D3"
					/>
					<rect
						x="33.4501"
						y="25.9863"
						width="0.891899"
						height="11.5947"
						rx="0.445949"
						transform="rotate(45.4059 33.4501 25.9863)"
						fill="#3B97D3"
					/>
					<rect
						x="25.2472"
						y="26.5586"
						width="0.891899"
						height="11.5947"
						rx="0.445949"
						transform="rotate(-44.5941 25.2472 26.5586)"
						fill="#3B97D3"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_1306_44897"
							x1="31.4001"
							y1="34.3535"
							x2="30.9416"
							y2="39.733"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#3B97D3" stopOpacity="0.23" />
							<stop offset="0.499676" stopColor="#3B97D3" stopOpacity="0.13" />
							<stop offset="1" stopColor="#3B97D3" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>
				<p>No data to show</p>
			</div>
		</div>
	);
};

export default TableBodyNoData;
