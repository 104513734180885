interface IErrorsMap {
	[index: string]: string;
}

export const notificationsMessagesInfo = {
	loginSuccess: "You've logged in successfully",
	logout: 'Please log in to access the platform',
	CompanySaved: 'Company data saved.',
	registeredSuccess: 'You have successfully registered.',
	documentsSent: 'Documents sent.',
	twoFaSuccess: 'Account 2FA success.',
	passwordSuccessfully: 'Password changed successfully.',
	emailSuccessfully: 'Email changed successfully.',
	generationSuccessful: 'Generation successful.',
	updateBankAccountsRequest: 'Bank account has been updated successfully.',
	confirmationTwoFa: 'Two fa success.',
	successfulRegistration: 'Successful registration.',
	putCurrencyValueRequest: 'The user currencies has been changed.',
	putFeeRequest: (feeType: string) => `${feeType} fee was updated successfully`,
	putFeeSpreadManagementRequest: 'Spread fee was updated successfully.',
	putFeeCurrenciesRequest: (assetName: string, action: string) => `${assetName} was ${action}.`,
	verificationFiles: 'Documents sent.',
	putGeneralWithdrawalLimit: 'General withdrawal limit changed successfully.',
	usersDataSuccessfullyUpdated: 'User data has been successfully updated.',
	userNotValidEmail: 'User email address is not valid.',
	putUserChangeReferral: 'Referral fee was updated successfully.',
	userCreated: 'User created.',
	changeSuccess: 'Data updated successfully.',
	supportSuccess: 'We’ll get back to you shortly.',
	/// ///////////////////////////////////////
	createNewAdmin: (first_name: string, last_name: string) =>
		`Admin ${first_name} ${last_name} was created successfully.`,
	updateAdmin: (first_name: string, last_name: string) =>
		`Admin ${first_name} ${last_name} was updated successfully.`,
	changeAdminStatus: (status: string) => `Admin has been ${status}.`,
	referralDeleteSuccess: 'Referral was deleted successfully.',
};
export const ErrorsInfo: IErrorsMap = {
	the_useremail_field_is_required: 'The useremail field is required.',
	the_company_dataaccount_number_field_is_required:
		'The company dataaccount number field is required.',
	the_company_databank_address_field_is_required: 'The company databank address field is required.',
	the_company_databank_currency_field_is_required:
		'The company databank currency field is required.',
	the_company_databank_name_field_is_required: 'The company databank name field is required.',
	the_company_databeneficiary_address_field_is_required:
		'The company databeneficiary address field is required.',
	the_company_databeneficiary_name_field_is_required:
		'The company databeneficiary name field is required.',
	the_company_dataswift_bic_field_is_required: 'The company dataswift bic field is required.',
	the_user_datafirst_name_field_is_required: 'The user datafirst name field is required.',
	the_user_datalast_name_field_is_required: 'The user datalast name field is required.',
	the_user_dataphone_field_is_required: 'The user dataphone field is required.',
	the_user_dataposition_field_is_required: 'The user dataposition field is required.',
	/// /////////////////////////////////////////////////////////////////////// error ()
};
