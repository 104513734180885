export const downloadFile = (data: string, fileName: string) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style.display = 'none';

	const blob = new Blob([data], { type: 'octet/stream' });
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

export const downloadImg = (data: string, fileName: string) => {
	const link = document.createElement('a');
	link.download = data;
	const blob = new Blob([fileName], { type: 'image/png' });
	link.href = URL.createObjectURL(blob);
	link.click();
	URL.revokeObjectURL(link.href);
};

export const handleDownloadFile = (data: string, fileName: string) => {
	const fileBlob = new Blob([data]);
	const fileURL = window.URL.createObjectURL(fileBlob);
	const fileLink = document.createElement('a');

	fileLink.href = fileURL;
	fileLink.setAttribute('download', fileName);
	document.body.appendChild(fileLink);

	fileLink.click();

	document.body.removeChild(fileLink);
};
