import { notificationContainer } from 'services/utils/notificationContainer';
import { setPopUpData, popUpOpen } from 'redux/reducers/popUp/reducer';
import axios from 'axios';
import { api } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeEvery, takeLatest, select, takeLeading } from 'redux-saga/effects';

import { responseErrors } from 'services/http/responseErrors';
import { IAdminsResponse } from 'services/api/admins/types';
import {
	changeAdminStatusRequest,
	createAdminRequest,
	deleteAdminRequest,
	getAdminsRequest,
	getAdminsSuccess,
	getAllPermissionsListRequest,
	getAllPermissionsListSuccess,
	resetAdminsLoader,
	updateAdminDataRequest,
} from './reducer';
import {
	TPermissionsResponse,
	INewAdminData,
	IChangeStatusPayload,
	IUpdateAdminDataPayload,
} from './types';

import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { ROUTES } from '../../../routes/constants';

function* getAdminsRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;
	try {
		const response: IAdminsResponse = yield call(api.admins.getAdmins, payload);
		yield put(getAdminsSuccess(response));
	} catch (error) {
		yield put(resetAdminsLoader());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getPermissionsListWorker() {
	try {
		const response: TPermissionsResponse = yield call(api.admins.getAllPermissions);
		yield put(getAllPermissionsListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* createNewAdminWorker(action: PayloadAction<INewAdminData>) {
	const { payload } = action;
	try {
		yield call(api.admins.createAdmin, payload);
		yield put(
			setPopUpData({
				title: '',
				message: notificationsMessagesInfo.createNewAdmin(payload.first_name, payload.last_name),
			}),
		);
		payload.history.push(ROUTES.adminManagement.index);
		yield put(popUpOpen('successPopUp'));
		yield put(getAdminsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* deleteAdminWorker(action: PayloadAction<any>) {
	const { payload } = action;
	try {
		yield call(api.admins.deleteAdmin, payload.message);
		const payloadRequest = {
			per_page: payload.per_page,
			current_page: payload.current_page,
		};
		const response: IAdminsResponse = yield call(api.admins.getAdmins, payloadRequest);
		yield put(getAdminsSuccess(response));

		if (response.data.users.length === 0) {
			const payloadRequestNew = {
				per_page: payload.per_page,
				current_page: payload.current_page - 1,
			};
			const responseNew: IAdminsResponse = yield call(api.admins.getAdmins, payloadRequestNew);
			yield put(getAdminsSuccess(responseNew));
		}
		yield put(popUpOpen('AdminDeleted'));
		// yield put(getAdminsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* changeAdminStatusWorker(action: PayloadAction<IChangeStatusPayload>) {
	const { payload } = action;
	try {
		yield call(api.admins.changeAdminStatus, payload);
		yield put(getAdminsRequest({}));
		yield put(
			setPopUpData({
				title: 'Status Updated',
				message: notificationsMessagesInfo.changeAdminStatus(
					!payload.status ? 'blocked' : 'unblocked',
				),
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* updateAdminDataWorker(action: PayloadAction<IUpdateAdminDataPayload>) {
	const { payload } = action;
	try {
		yield call(api.admins.updateAdmin, payload);
		yield put(
			setPopUpData({
				title: '',
				message: notificationsMessagesInfo.updateAdmin(
					payload.obj.first_name,
					payload.obj.last_name,
				),
			}),
		);
		yield put(getAdminsRequest({}));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* adminsSaga() {
	yield takeEvery(getAdminsRequest.type, getAdminsRequestWorker);
	yield takeEvery(getAllPermissionsListRequest.type, getPermissionsListWorker);
	yield takeEvery(createAdminRequest.type, createNewAdminWorker);
	yield takeEvery(deleteAdminRequest.type, deleteAdminWorker);
	yield takeLeading(changeAdminStatusRequest.type, changeAdminStatusWorker);
	yield takeEvery(updateAdminDataRequest.type, updateAdminDataWorker);
}
