import { History } from 'history';

export interface IAuthStore {
	accessToken: string | null;
	socketToken: string | null;
	refreshToken: string | null;
	isAuthenticated: boolean;
	registerLoader: boolean;
	loginLoader: boolean;
	logoutLoader: boolean;
	forgotPasswordLoader: boolean;
	forgotPasswordPopup: boolean;
	createNewPasswordLoader: boolean;
	ipConfirmLoader: boolean;
	emailConfirm: boolean;
	emailConfirmLoader: boolean;
	emailResetConfirmTokenLoader: boolean;
	emailResetConfirmTokenMessage: boolean;
	generateSmsLoader: boolean;
	userData: IUser | null;
	tokenRefreshTime: string | null;
	tokenUpdating: boolean;
	secretTokenForFileDownload: string | null;
	permissions: null | IPermissionsArray;
}

// ==========================================:
export interface ILoginPayload {
	data: ILoginPayloadData;
	history: History;
}
export interface ILoginPayloadData {
	email: string;
	password: string;
	captcha: string;
	totp: string;
	time_zone: number;
	remember?: boolean;
}

export interface IResetPasswordValue {
	c_password: string;
	password: string;
}

export interface ILoginFirstRequest {
	c_password: string;
	password: string;
	token: string;
	totp: string;
}

export interface ILoginResponse {
	token: string;
	socket_token: string;
	user_data: ILoginResponseUser;
	token_expired_at: number;
}

export interface ITokenRefreshResponse {
	token: string;
}

export interface ILoginResponseUser {
	id: number;
	first_name: string;
	email: string;
	email_confirmed: number;
	google2fa_enabled: number;
	type_id: number;
	status_id: number;
	kyc_message: null | string;
	last_login: number;
	blocked: string;
	is_active: boolean;
	last_activity: string;
	invite_key: string;
	created_at: number;
	username: string;
	invite_url: string;
	data: ILoginResponseUserData;
	status: ILoginResponseUserStatus;
	permissions: [];
}

export interface ILoginResponseUserData {
	user_id: number;
	first_name: null | string;
	last_name: null | string;
	phone: null | string;
	dob: null | string;
	country: null | string;
	state: null | string;
	city: null | string;
	street: null | string;
	post_code: null | number;
	created_at: string;
	updated_at: string;
	position: null | number | string;
	company: null | number | string;
	commission: string | number;
}

export interface ILoginResponseUserStatus {
	id: number;
	name: string;
}

// ==========================================:
export interface IRegistrationPayload {
	acceptTerms: boolean;
	captcha: string;
	confirmPassword: string;
	cookiePolicy: boolean;
	email: string;
	password: string;
}

export interface IRegistrResponse {
	status: string;
	email_confirm_token: string;
	user: IRegistrResponseUser;
}

export interface IRegistrResponseUser {
	id: string;
	email: string;
	invite_key: string;
	created_at: number;
	invite_url: string;
}

// ==========================================:

export interface IipConfirmPayload {
	data: IipConfirmPayloadData;
	history: History;
}

export interface IipConfirmPayloadData {
	code: string;
	email: string;
}

// ==========================================:
export interface ILogoutPayload {
	history: History;
}

export interface ILogoutResponse {
	status: string;
}

// ==========================================:
export interface IEmailConfirmPayload {
	token: string | string[] | null;
	timestamp: string | string[] | null;
}

// ==========================================:
export interface IEmailResetConfirmTokenPayload {
	token: string | string[] | null;
	history: History;
}

// ==========================================:
export interface IForgotPasswordPayload {
	email: string;
}

export interface IForgotPasswordResponse {
	status: string;
	password_reset_token: string;
}

// ==========================================:
export interface ICreateNewPasswordPayload {
	data: ICreateNewPasswordFormData;
	history: History;
}

export interface ICreateNewPasswordFormData {
	token: string;
	password: string;
	c_password: string;
}

// ==========================================:
export interface IGenerateSmsData {
	phone: string;
}

export interface IGenerateSmsResponseData {
	status: string;
}
// ==========================================:

export interface IResetTwoFaPayload {
	data: IResetTwoFaRequestPayload;
	history: History;
}
export interface IResetTwoFaRequestPayload {
	email: string;
	reason: string;
	type: 2;
	captcha: string;
}

export type IGenerateSmsRequestPayload = IGenerateSmsData;
export type IGenerateSmsResponsePayload = IGenerateSmsResponseData;

export interface IUser {
	user_id?: number;
	oneify_id?: string | number;
	id: number;
	first_name: string;
	last_name?: string;
	email: string;
	email_confirmed: number;
	google2fa_enabled: number;
	type_id: null | number | string;
	status_id: number;
	kyc_message: null | number | string;
	last_login: null | number | string;
	blocked: string;
	is_active: boolean;
	last_activity: string;
	invite_key: string;
	created_at: number;
	username: string;
	invite_url: string;
	data: IUserData;
	status: IUserDataStatus;
}

export interface IUserData {
	user_id: null | number | string;
	first_name: null | string;
	last_name: null | string;
	phone: null | number | string;
	dob: null | string;
	country: null | string;
	state: null | string;
	city: null | string;
	street: null | string;
	post_code: null | number | string;
	position: null | number | string;
	company: null | number | string;
	commission: string | number;
}

export interface IUserDataStatus {
	id: number;
	name: string;
}

export enum EPermissionNames {
	DASHBOARD = 'permission_dashboard',
	ADMIN_MANAGEMENT = 'admin_management',
	DEPOSIT_ACCOUNT = 'deposit_account',
	USER_MANAGEMENT = 'user_management',
	RESET_REQUEST = 'reset_request',
	FEE_MANAGEMENT = 'default_fee_management',
	TRANSFER_TRADE_REPORT = 'transfer_trade_report',
	WALLET_MANAGEMENT = 'wallet_management',
	REFERRAL_MANAGEMENT = 'referral_management',
	REQUIRED_EMAILS = 'required_emails',
	SECURITY_SETTINGS = 'security_settings',
	MESSAGES = 'message_management',
}

export interface IPermissions {
	id: number;
	name: EPermissionNames;
	pivot: { admin_id: number; permission_id: number; editable: number; only_read: number };
}

export type IPermissionsArray = Array<IPermissions>;
