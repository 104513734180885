import React, { useLayoutEffect, useState } from 'react';
import login from 'assets/dist/img/login-icon.svg';
import LoginFirst from 'components/LoginFirst';
import { Helmet } from 'react-helmet';
import loginBannerImage from '../../assets/dist/img/login-banner@2x-min.png';

const LoginFirstPage = () => {
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);

	useLayoutEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []);

	return (
		<>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
			</Helmet>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="create-account-progress">
							<div className="create-account-progress__step active">
								<div className="create-account-progress__step-text">
									<p>Create Password</p>
								</div>
								<div className="create-account-progress__step-number">1</div>
							</div>
							<div className="create-account-progress__line" />
							<div className="create-account-progress__step">
								<div className="create-account-progress__step-text">
									<p>2FA Setup</p>
								</div>
								<div className="create-account-progress__step-number">2</div>
							</div>
						</div>

						<div className="login-form">
							<div className="login-form__title">
								<p>Create Password</p>
								<p className="login-form__subtitle">Please enter a strong and unique password.</p>
							</div>

							<LoginFirst />
						</div>
					</div>

					<div className="login-banner" id="login_banner">
						<img src={loginBannerImage} alt="" />
						<p className="login-banner__text">
							Multi-currency accounts, foreign exchange and
							<br /> payments that streamline your global operations
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginFirstPage;
