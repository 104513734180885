import { FC, useLayoutEffect } from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { useDispatch } from 'react-redux';
import DetailedView from 'components/DetailedView/DetailedView';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';

interface IDepositHistoryTabViewProp {
	type: string;
}
const DepositHistoryTabView: FC<IDepositHistoryTabViewProp> = () => {
	const { url, path } = useRouteMatch();
	const dispatch = useDispatch();
	const { state } = useLocation();

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const type = url.replace(/(?:deposit|withdrawal)|./gi, (match) => {
		return match === 'deposit' || match === 'withdrawal' ? match : '';
	});

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className="tabs-buttons tabs-buttons--transfer-history">
					{!state && (
						<>
							<NavLink to={`${url}/crypto`} className="button button--size4 button--type3">
								Crypto
							</NavLink>
							<NavLink to={`${url}/fiat`} className="button button--size4 button--type3">
								Fiat
							</NavLink>
						</>
					)}
				</div>
				<Switch>
					<Route
						exact
						path={`${path}/crypto`}
						render={(routeProps) => <CryptoHistory type={type} {...routeProps} />}
					/>
					<Route
						exact
						path={`${path}/fiat`}
						render={(routeProps) => <FiatHistory type={type} {...routeProps} />}
					/>
					<Route path={`${url}/:id`} component={DetailedView} />

					<Redirect to={`${path}/crypto`} />
				</Switch>
			</div>
		</div>
	);
};

export default DepositHistoryTabView;
