import AdminResetRequestsTabs from 'components/ResetRequest/AdminResetRequestTabs/AdminResetRequestsTabs';
import { FC, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';
import { useSelector } from 'react-redux';
import PasswordTab from './Password';
import TwofaTab from './Twofa';
import { getRequests } from '../../../../redux/reducers/users/selectors';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}

const AdminsTable: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	return (
		<div className="table-block table-block--relative">
			<div className="table-wrapper">
				<Switch>
					<Route
						exact
						path={ROUTES.AdminResetRequest.twofa}
						render={() => (
							<TwofaTab
								handlePageChange={handlePageChange}
								setItemsPerPageHandler={setItemsPerPageHandler}
								itemsPerPage={itemsPerPage}
								page={page}
							/>
						)}
					/>
					<Route
						exact
						path={ROUTES.AdminResetRequest.password}
						render={() => (
							<PasswordTab
								handlePageChange={handlePageChange}
								setItemsPerPageHandler={setItemsPerPageHandler}
								itemsPerPage={itemsPerPage}
								page={page}
							/>
						)}
					/>
					{/* <Redirect to={ROUTES.AdminResetRequest.twofa} /> */}
				</Switch>
			</div>
		</div>
	);
};

export default AdminsTable;
