import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { useDispatch } from 'react-redux';
import { dashboardInitialState } from 'redux/reducers/dashboard/reducer';
import { useLayoutEffect } from 'react';
import CryptoTab from './Crypto';
import FiatTab from './Fiat';

const AdminPanel = () => {
	const dispatch = useDispatch();
	useLayoutEffect(() => {
		dispatch(dashboardInitialState());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="admin-panel">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Dashboard</p>
				</div>
			</div>
			<div className="buttons-flexcontainer mb-20">
				<div style={{ marginBottom: '18px' }} className="tabs-buttons tabs-buttons--mb-0">
					<NavLink
						onClick={() => {
							dispatch(dashboardInitialState());
						}}
						to={ROUTES.adminPanel.currency.crypto}
						className="button button--size4 button--type3"
					>
						Crypto
					</NavLink>
					<NavLink
						onClick={() => {
							dispatch(dashboardInitialState());
						}}
						to={ROUTES.adminPanel.currency.fiat}
						className="button button--size4 button--type3"
					>
						Fiat
					</NavLink>
				</div>
			</div>
			<Switch>
				<Route path={ROUTES.adminPanel.currency.crypto} component={CryptoTab} />
				<Route path={ROUTES.adminPanel.currency.fiat} component={FiatTab} />
				<Redirect to={ROUTES.adminPanel.currency.crypto} />
			</Switch>
		</div>
	);
};

export default AdminPanel;
