import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const AdminResetRequestsTabs = () => {
	return (
		<div className="tabs-buttons-nav tabs-buttons-nav--reset-request mb-0">
			<NavLink to={ROUTES.AdminResetRequest.twofa}>2FA</NavLink>
			<NavLink to={ROUTES.AdminResetRequest.password}>Password</NavLink>
		</div>
	);
};

export default AdminResetRequestsTabs;
