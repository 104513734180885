/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */

import { Field, Form, Formik } from 'formik';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import {
	createAdminRequest,
	getAdminsRequest,
	getAllPermissionsListRequest,
	updateAdminDataRequest,
} from 'redux/reducers/admins/reducer';
import { getAdmins, getAdminsPermissionsList } from 'redux/reducers/admins/selectors';
import { IPermissionData } from 'redux/reducers/admins/types';
import IconSvg from 'ui/Svg/IconSvg';
import { ROUTES } from 'routes/constants';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { notificationsInfoFields } from 'services/utils/inputFields/ipuntFields';
import PermissionRow from './PermissionRow';

interface IAdminRegisterForm {
	email?: string;
	first_name: string;
	last_name: string;
}

const CreateAdminPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const permissions = useSelector(getAdminsPermissionsList);
	const admins = useSelector(getAdmins);
	const permission = useSelector(getPermissions)?.[EPermissionNames.ADMIN_MANAGEMENT].editable;
	const state: { id: number } | undefined = history.location.state as { id: number } | undefined;

	const [adminPermissions, setAdminPermissions] = useState<{ [key: string]: IPermissionData }>({});

	useLayoutEffect(() => {
		document.body.style.overflow = 'auto';
	}, []);

	const editableAdmin = admins?.data.users.find((adm) => adm.id === state?.id);
	const lostPermissions =
		permissions?.filter(
			(item) => !editableAdmin?.permissions.some((perm) => perm.id === item.id),
		) || [];

	const handleGoBack = () => {
		history.goBack();
	};

	useLayoutEffect(() => {
		dispatch(getAdminsRequest({}));
		dispatch(getAllPermissionsListRequest());
	}, [dispatch, state]);

	useLayoutEffect(() => {
		if (state?.id) {
			const edPerm = editableAdmin?.permissions || [];
			const allPermissions = [...edPerm, ...lostPermissions];
			const formattedList: {
				[key: string]: { id: number; editable: boolean; only_read: boolean };
			} = {};
			for (let i = 0; i < allPermissions.length; i += 1) {
				const item = allPermissions[i];
				if (item.pivot) {
					formattedList[item.name] = {
						id: item.id,
						editable: item.pivot.editable,
						only_read: item.pivot.only_read,
					};
				} else
					formattedList[item.name] = {
						id: item.id,
						editable: false,
						only_read: false,
					};
			}
			setAdminPermissions(formattedList);
		}
	}, [state, permissions, editableAdmin]);

	useLayoutEffect(() => {
		console.log(adminPermissions);
	}, [adminPermissions]);

	const handleAddPermissions = (title: string, obj: IPermissionData) => {
		setAdminPermissions({ ...adminPermissions, [title]: obj });
	};

	const onSubmit = (values: IAdminRegisterForm) => {
		const permissionsArr = Object.values(adminPermissions);
		const adminRegisterObj = {
			history,
			...values,
			email: values.email || '',
			permissions: permissionsArr,
		};
		if (state) {
			const adminUpdateObj = {
				history,
				id: state.id,
				obj: {
					first_name: values.first_name,
					last_name: values.last_name,
					permissions: permissionsArr,
				},
			};
			dispatch(updateAdminDataRequest(adminUpdateObj));
			history.goBack();
		} else {
			dispatch(createAdminRequest(adminRegisterObj));
		}

		// history.push(ROUTES.adminManagement.index);
	};
	const initialValues = {
		email: '',
		first_name: editableAdmin ? editableAdmin.first_name : '',
		last_name: editableAdmin ? editableAdmin.last_name : '',
	};

	const initialEditValues = {
		first_name: editableAdmin ? editableAdmin.first_name : '',
		last_name: editableAdmin ? editableAdmin.last_name : '',
		email: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.adminValidationMessage.email.required)
			.email(notificationsInfoFields.adminValidationMessage.email.valid)
			.matches(
				/^[\w]+([_.+-][\w]+)*@([\w]+(-[\w]+)*\.)+\w{2,}$/,
				notificationsInfoFields.adminValidationMessage.email.valid,
			)
			.max(60, notificationsInfoFields.adminValidationMessage.email.max),
		first_name: yup
			.string()
			.required(notificationsInfoFields.adminValidationMessage.firstName.required)
			.max(60, notificationsInfoFields.adminValidationMessage.firstName.max),
		last_name: yup
			.string()
			.required(notificationsInfoFields.adminValidationMessage.lastName.required)
			.max(60, notificationsInfoFields.adminValidationMessage.lastName.max),
	});

	const validationEditSchema = yup.object().shape({
		first_name: yup
			.string()
			.required(notificationsInfoFields.adminValidationMessage.firstName.required)
			.max(60, notificationsInfoFields.adminValidationMessage.firstName.max),
		last_name: yup
			.string()
			.required(notificationsInfoFields.adminValidationMessage.lastName.required)
			.max(60, notificationsInfoFields.adminValidationMessage.lastName.max),
	});

	return (
		<Formik
			initialValues={state ? initialEditValues : initialValues}
			validationSchema={state ? validationEditSchema : validationSchema}
			onSubmit={(values, { resetForm, setSubmitting }) => {
				onSubmit(values);
				setSubmitting(false);
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ errors, touched }) => {
				return (
					<>
						<div className="title-block-wrap">
							<div className="title-block">
								<p className="title">Admin Management</p>
							</div>
						</div>

						<div className="title-block mb-30">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button type="button" className="btn btn--icon btn--back" onClick={handleGoBack} />
							<p className="table-title">
								{state
									? `${String(editableAdmin?.first_name)} ${String(editableAdmin?.last_name)}`
									: 'Create New Admin'}
							</p>
						</div>

						<Form className="form form--type3">
							<div className="form-body">
								<div className="create-admin__title">
									<span className="create-admin__number">1</span>
									Name
								</div>

								<div className="input--grid">
									<div className="input-wrapper mb-16">
										<Field
											required
											disable={!permission}
											name="first_name"
											placeholder="Enter First Name"
											component={Input}
											title="First Name"
											className={errors.first_name && touched.first_name ? 'error-border' : ''}
										/>
									</div>
									<div className="input-wrapper mb-16">
										<Field
											required
											disable={!permission}
											name="last_name"
											placeholder="Enter Last Name"
											component={Input}
											title="Last Name"
											className={errors.last_name && touched.last_name ? 'error-border' : ''}
										/>
									</div>
								</div>
								{!state && (
									<div className="input--grid">
										<div className="input-wrapper">
											<Field
												required
												name="email"
												type="email"
												placeholder="Enter Email Address"
												component={Input}
												title="Email Address"
												className={errors?.email && touched.email ? 'error-border' : ''}
											/>
										</div>
									</div>
								)}

								<div className="form__divider" />

								<div className="create-admin__title">
									<span className="create-admin__number">2</span>
									Permissions
								</div>

								<div className="create-admin__table">
									{!!permissions?.length &&
										!state &&
										permissions.map((perm) => (
											<PermissionRow
												key={perm.id}
												id={perm.id}
												title={perm.name}
												permission={!!permission}
												onChange={handleAddPermissions}
											/>
										))}
									{editableAdmin?.permissions.length &&
										state &&
										Object.entries(adminPermissions).map((perm) => {
											return (
												<PermissionRow
													key={perm[1].id}
													id={perm[1].id}
													title={perm[0]}
													permission={!!permission}
													editable={Number(perm[1]?.editable) || undefined}
													only_read={perm[1]?.only_read}
													onChange={handleAddPermissions}
												/>
											);
										})}
								</div>
							</div>
							<div className="create-admin__footer-buttons">
								{!!permission && (
									<>
										<button onClick={handleGoBack} type="button" className="btn btn-danger">
											Cancel
										</button>
										<button type="submit" className="btn btn-primary">
											{state ? 'Update' : 'Create'}
										</button>
									</>
								)}
							</div>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};

export default CreateAdminPage;
