import { call, put, takeEvery, select } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import axios from 'axios';
import {
	changeBankAccountsPage,
	getBankAccountsRequest,
	updateBankAccountRequest,
	deleteBankAccountRequest,
	getBankAccountsSuccess,
	deleteBankAccountError,
	getBankAccountsAllRequest,
	getBankAccountsAllSuccess,
} from './reducer';
import {
	IBankAccountsPageInfo,
	IBankAccountGetResponse,
	IBankAccountRequestPayload,
	IBankAccountUpdateRequestPayload,
} from './types';
import { getBankAccountsPageInfo, getBankAccountsUserId } from './selectors';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';

// =============================================================:

// =============================================================:
function* getBankAccountsRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		const pageInfo: IBankAccountsPageInfo = yield select(getBankAccountsPageInfo);
		const response: IBankAccountGetResponse = yield call(api.bankAccounts.getBankAccounts, {
			userId,
			pageInfo,
		});
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		notificationContainer('Bank accounts were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getBankAccountsAllRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		const pageInfo: IBankAccountsPageInfo = {
			per_page: 99999,
			current_page: 1,
		};
		const response: IBankAccountGetResponse = yield call(api.bankAccounts.getBankAccounts, {
			userId,
			pageInfo,
		});
		yield put(getBankAccountsAllSuccess(response));
	} catch (error) {
		notificationContainer('Bank accounts were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteBankAccountsRequestWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		yield call(api.bankAccounts.deleteBankAccount, { userId, bank_account_id: payload.id });
		yield put(getBankAccountsRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(error.response?.data.errors[0], 'error');
			if (
				error?.response?.data?.errors[0] ===
				'record_can’t_be_deleted_at_the_moment_this_bank_account_is_used_in_one_or_more“pending“_operations_please_wait_untill_all_such_operations_are_processed'
			) {
				notificationContainer(
					'This bank account is used in one or more “Pending“ operations.<br>' +
						'Please wait untill all such operations are processed.',
					'user_blocked',
					'Record can’t be deleted at the moment',
				);
				yield put(deleteBankAccountError());
			}
		}
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankDelete }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// ===========================================================:
function* updateBankAccountsRequestWorker(action: PayloadAction<IBankAccountUpdateRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		const payloadUserId = { ...payload, userId };

		yield call(api.bankAccounts.updateBankAccount, { ...payloadUserId });
		yield put(getBankAccountsRequest());

		yield put(
			setPopUpData({
				title: 'Bank Account updated',
				message: notificationsMessagesPopUp.bankSuccessfully(payload?.data?.bank_name),
			}),
		);

		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotUpdate }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* bankAccountsSaga() {
	yield takeEvery(getBankAccountsRequest.type, getBankAccountsRequestWorker);
	yield takeEvery(updateBankAccountRequest.type, updateBankAccountsRequestWorker);
	yield takeEvery(deleteBankAccountRequest.type, deleteBankAccountsRequestWorker);
	yield takeEvery(changeBankAccountsPage.type, getBankAccountsRequestWorker);
	yield takeEvery(getBankAccountsAllRequest.type, getBankAccountsAllRequestWorker);
}
