/* eslint-disable no-param-reassign */
import { ICurrencyFiat } from 'redux/reducers/currency/types';

export const getDepositableFiat = (arr: ICurrencyFiat) => {
	return arr?.data.reduce((result: string, el) => {
		if (el.depositable === 1) {
			if (result.length > 0) {
				result += ', ';
			}
			result += el.asset.code.toUpperCase();
		}
		return result;
	}, '');
};
