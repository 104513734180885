import { ca } from 'date-fns/locale';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { ECurrency } from 'services/api/dashboard/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	dashboardCryptoRequest,
	dashboardFiatRequest,
	getCryptoDataSuccess,
	getFiatDataSuccess,
} from './reducer';
import {
	IB2c2Balance,
	IDepositBalances,
	ILiquidity,
	IPendingFees,
	IPlatformBalances,
	IPoolBalances,
	IProfitBalanceItem,
	IWithdrawalBalanceItem,
} from './types';
import { IPagination } from '../users/types';

// =============================================================:
function* dashboardCryptoWorker() {
	try {
		yield put(showLoading());
		const platformBalance: IPagination<IPlatformBalances> = yield call(
			api.dashboard.platformBalances,
			ECurrency.CRYPTO,
		);
		const depositBalance: IPagination<IDepositBalances> = yield call(api.dashboard.depositBalances);
		const pendingFee: IPendingFees = yield call(api.dashboard.pendingFee, ECurrency.CRYPTO);
		const profitBalance: Array<IProfitBalanceItem> = yield call(api.dashboard.profitBalances);
		const poolBalance: IPagination<IPoolBalances> = yield call(api.dashboard.poolBalances);
		const liquidity: ILiquidity = yield call(api.dashboard.liquidity, ECurrency.CRYPTO);
		const withdrawBalance: IPagination<IWithdrawalBalanceItem> = yield call(
			api.dashboard.withdrawBalances,
		);
		const b2c2Balance: IPagination<IB2c2Balance> = yield call(
			api.dashboard.b2c2Balances,
			ECurrency.CRYPTO,
		);
		yield put(
			getCryptoDataSuccess({
				platformBalance,
				depositBalance,
				pendingFee,
				profitBalance,
				poolBalance,
				liquidity,
				withdrawBalance,
				b2c2Balance,
			}),
		);
	} catch (error) {
		// notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* dashboardFiatWorker() {
	try {
		yield put(showLoading());
		const platformBalance: IPagination<IPlatformBalances> = yield call(
			api.dashboard.platformBalances,
			ECurrency.FIAT,
		);

		const liquidity: ILiquidity = yield call(api.dashboard.liquidity, ECurrency.FIAT);
		const pendingFee: IPendingFees = yield call(api.dashboard.pendingFee, ECurrency.FIAT);
		const b2c2Balance: IPagination<IB2c2Balance> = yield call(
			api.dashboard.b2c2Balances,
			ECurrency.FIAT,
		);
		yield put(
			getFiatDataSuccess({
				liquidity,
				platformBalance,
				pendingFee,
				b2c2Balance,
			}),
		);
	} catch (error) {
		// notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* dashboardSaga() {
	yield takeEvery(dashboardCryptoRequest.type, dashboardCryptoWorker);
	yield takeEvery(dashboardFiatRequest.type, dashboardFiatWorker);
}
