import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormikTouched } from 'formik';

interface ITextEditorProps {
	value: string;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	touched: boolean | undefined;
	disabled: boolean;
	setTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	error: string | undefined;
}

export const TextEditor = ({
	value,
	setFieldValue,
	touched,
	disabled,
	setTouched,
	error,
}: ITextEditorProps) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const prepareDraft = (text: string) => {
		const draft = htmlToDraft(text);
		const contentStateValue = ContentState.createFromBlockArray(draft.contentBlocks);
		const editorStateValue = EditorState.createWithContent(contentStateValue);
		return editorStateValue;
	};

	useEffect(() => {
		if (value && !touched) {
			setEditorState(prepareDraft(value));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const onEditorStateChange = (state: EditorState) => {
		setTouched('message', true);

		const forFormik = draftToHtml(convertToRaw(state.getCurrentContent()));
		setFieldValue('message', forFormik);
		setEditorState(state);
	};

	const toolbarOptions = {
		options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
		inline: {
			options: ['bold', 'italic', 'underline'],
		},
	};
	return (
		<div>
			<Editor
				editorState={editorState}
				wrapperClassName={
					error && touched ? 'editor__container editor__container--error' : 'editor__container'
				}
				editorClassName="editor__wrapper"
				toolbarClassName={
					disabled ? 'editor__toolbar editor__toolbar--disabled' : 'editor__toolbar'
				}
				onEditorStateChange={onEditorStateChange}
				toolbar={toolbarOptions}
				readOnly={disabled}
				placeholder="Enter Message"
			/>
		</div>
	);
};
