/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useLayoutEffect, useState, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getUsersInit, getUsersRequest } from 'redux/reducers/users/reducer';
import { getUsersData, getUsersDataLoading } from 'redux/reducers/users/selectors';
import { IUsersRequest } from 'services/api/users/types';

import UsersTable from 'components/tables/UsersTable';
import UsersSearch from 'components/tables/UsersTable/UsersSearch';
import { getPermissions, getSecretTokenForFileDownload } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import IconSvg from 'ui/Svg/IconSvg';
import { generateSecretTokenForFileDownloadRequest } from 'redux/reducers/auth/reducer';
import { WEB_API_URL } from 'services/constants/env';
import { resetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import { resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';

const UserManagementPage: FC = memo(() => {
	const users = useSelector(getUsersData);
	const loading = useSelector(getUsersDataLoading);

	const permissions = useSelector(getPermissions);
	const token = useSelector(getSecretTokenForFileDownload);
	const history = useHistory();
	const dispatch = useDispatch();
	const [query, setQuery] = useState<IUsersRequest>({
		current_page: 1,
		per_page: 10,
	});
	const handleGetFile = (fileType: 'csv' | 'xlsx') => {
		if (token && WEB_API_URL) {
			window.location.replace(`${WEB_API_URL}/admin/users/report/${fileType}/${token}`);
		}
		// dispatch(getUsersFileRequest({ fileType }));
	};
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const previousPerPage = useRef(query.per_page);

	useLayoutEffect(() => {
		// Check if per_page has changed
		if (query.per_page !== previousPerPage.current) {
			// Reset current_page to 1
			query.current_page = 1;
			previousPerPage.current = query.per_page;
			dispatch(getUsersInit());
		}

		dispatch(
			getUsersRequest({
				...query,
				per_page: query.per_page || 10,
				current_page: query.current_page,
			}),
		);
	}, [query, dispatch]);

	useLayoutEffect(() => {
		dispatch(generateSecretTokenForFileDownloadRequest());
	}, []);

	const handleCreateUser = () => {
		history.push('/createUser/userInfo');
		dispatch(resetActiveBankAccounts());
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
	};

	return (
		<div className="user-management">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">User Management</p>
				</div>
				<div className="title-block-button">
					{/* <button */}
					{/*	type="button" */}
					{/*	onClick={() => handleGetFile('csv')} */}
					{/*	className="button button--size2 button--type2" */}
					{/* > */}
					{/*	<IconSvg name="csv" w="10" h="5" /> */}
					{/*	Download */}
					{/* </button> */}
					{/* <button */}
					{/*	onClick={() => handleGetFile('xlsx')} */}
					{/*	type="button" */}
					{/*	className="button button--size2 button--type2" */}
					{/* > */}
					{/*	<IconSvg name="xlsx" w="10" h="5" /> */}
					{/*	Download */}
					{/* </button> */}
				</div>
			</div>
			<UsersSearch total={users?.data.length} setQuery={setQuery} tab="user" includeOneifyId />
			<UsersTable
				users={users?.data || []}
				pages={users?.last_page || 1}
				query={query}
				loading={loading}
				setQuery={setQuery}
				itemsPerPage={query.per_page ?? 10}
				setItemsPerPage={(item) => {
					setQuery({ ...query, per_page: Number(item) });
				}}
				setCurrentPage={(page: number) => {
					setQuery({ ...query, current_page: page });
				}}
			/>
		</div>
	);
});

export default UserManagementPage;
