import { FC } from 'react';
import { EApiMessagesType } from 'redux/reducers/messages/types';

interface IMessageTableHeader {
	type: string;
}

const MessageTableHeader: FC<IMessageTableHeader> = ({ type }) => {
	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					{type === EApiMessagesType.PLANNED ? <p>Scheduled at</p> : <p>Timestamp</p>}
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Message</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Users ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	);
};

export default MessageTableHeader;
