import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Popup from 'reactjs-popup';
import { Field, Form, Formik } from 'formik';
import cn from 'classnames';
import { ICurrencyDataItem, IGetCurrencyUserPayload } from '../../../redux/reducers/currency/types';
import { currencyValueRequest } from '../../../redux/reducers/currency/reducer';
import { IDepositAccount } from '../../../redux/reducers/walletBalance/types';
import { getCryptoCurrencyData } from '../../../redux/reducers/currency/selectors';
import { IAddWithdrawalCryptoWorker } from '../../../redux/reducers/transactions/types';
import { addWithdrawalCryptoRequest } from '../../../redux/reducers/transactions/reducer';
import { ICurrencySelectItem } from '../../../ui/Formik/Select/CurrencySelect/types';
import { clearError } from '../../../redux/reducers/errors/reducer';
import SelectDate from '../../../ui/SelectDate';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import Input from '../../../ui/Formik/Input';
import Select from '../../../ui/Formik/Select';
import InputTotp from '../../../ui/Formik/InputTotp';
import CreateDepositAccountPopUp from '../CreateDepositAccount/CreateDepositAccount';
import { IPopUp } from '../types';
import { getBankAccounts } from '../../../redux/reducers/bankAccounts/selectors';
import { IBankAccount } from '../../../redux/reducers/bankAccounts/types';
import {
	EApiCryptoTransactionStatus,
	EApiFiatTransactionStatus,
} from '../../../services/api/transactions/types';
import UserSearch from './UserSearch/UserSearch';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import {
	changeWalletAddressesUserId,
	getUserWalletAddressesRequest,
	getWalletAddressesAllRequest,
} from '../../../redux/reducers/walletAddresses/reducer';
import { getWalletAddresses } from '../../../redux/reducers/walletAddresses/selectors';
import { IWalletAddress } from '../../../redux/reducers/walletAddresses/types';
import WalletAddressNameSelect from '../../../ui/Formik/Select/WalletAddressNameSelect/WalletAddressNameSelect';

export interface IAddCryptoWithdrawalForm {
	date: string;
	asset_code: string;
	indicated_amount: string;
	fee: string;
	status: string;
	note: string;
	twoFa: string;
	tx_hash: string;
}

const AddWithdrawalCryptoTransaction: FC<IPopUp> = ({ open, closeModal, buttonClick }) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(getBankAccounts);
	const walletAddresses = useSelector(getWalletAddresses);
	const [reset, setReset] = useState(false);
	const [userId, setUserId] = useState('');
	const [filteredBankAccounts, setFilteredBankAccounts] = useState<IBankAccount[]>([]);
	const [filteredWalletAddresses, setFilteredWalletAddresses] = useState<IWalletAddress[]>([]);
	const [walletAddress, setWalletAddress] = useState<IWalletAddress | null>(null);
	const [resetWallet, setResetWallet] = useState(false);

	const [indicatedAmount, setIndicatedAmount] = useState(0);
	const [fee, setFee] = useState(0);
	const [netAmount, setNetAmount] = useState(0);
	const [netAmountError, setNetAmountError] = useState('');
	const [createDepositOpen, setCreateDepositOpen] = useState(false);
	const [searchAccount, setSearchAccount] = useState<IDepositAccount[]>([]);
	const [selectedCurrency, setSelectedCurrency] = useState<ICurrencySelectItem | null>(null);
	const [userCurrencies, setUserCurrencies] = useState([]);
	const [filteredCurrencies, setFilteredCurrencies] = useState<ICurrencyDataItem[]>([]);
	const [network, setNetwork] = useState('');

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const statuses = [
		{
			id: 1,
			name: 'Completed',
			value: EApiFiatTransactionStatus.COMPLETED,
		},
		{
			id: 2,
			name: 'Pending',
			value: EApiFiatTransactionStatus.PENDING,
		},
		{
			id: 3,
			name: 'Rejected',
			value: EApiCryptoTransactionStatus.REJECTED,
		},
	];
	const statusFilterOptions = statuses.map(({ name }) => name);

	const handleGetAssetIdByCode = (currentCode: string) => {
		return cryptoCurrencyData.filter(({ code }) => code === currentCode.toLowerCase())[0].id;
	};

	const filterWalletAddressesHandler = (wallets: IWalletAddress[], assetId: number) => {
		return wallets.filter((wallet) => wallet.asset_id === assetId);
	};

	const handleChangeUserId = (currentUserId: string) => {
		setUserId(currentUserId);
		const params: IGetCurrencyUserPayload = {
			apiParams: {
				userId: String(currentUserId),
				value: 'crypto',
			},
		};
		dispatch(currencyValueRequest(params));
	};

	const handleCloseCreateDeposit = () => {
		setCreateDepositOpen(false);
	};
	const handleOpenCreateDeposit = () => {
		setCreateDepositOpen(true);
	};

	const changeIndicatedAmount = (value: string) => {
		setIndicatedAmount(Number(value));
		if (Number(value) < fee) {
			setNetAmountError('Gross amount can`t be bigger than fee');
			setNetAmount(0);
		} else if (Number(value) === fee) {
			setNetAmountError('Gross amount can`t be equal fee');
			setNetAmount(0);
		} else {
			const calc = Number(value) - fee;
			setNetAmount(Number(calc.toFixed(5)));
			setNetAmountError('');
		}
	};
	const changeFee = (value: string) => {
		setFee(Number(value));
		if (Number(value) > indicatedAmount) {
			setNetAmountError('Fee can`t be bigger than gross amount');
			setNetAmount(0);
		} else if (Number(value) === indicatedAmount) {
			setNetAmountError('Fee can`t be equal gross amount');
			setNetAmount(0);
		} else {
			const calc = indicatedAmount - Number(value);
			setNetAmount(Number(calc.toFixed(5)));
			setNetAmountError('');
		}
	};
	const initialValues: IAddCryptoWithdrawalForm = {
		date: '',
		asset_code: '',
		indicated_amount: '',
		fee: '',
		status: '',
		note: '',
		twoFa: '',
		tx_hash: '',
	};

	const validationSchema = yup.object().shape({
		date: yup.string().required('Please, enter date!'),
		asset_code: yup.string().required('Please, choose currency!'),
		indicated_amount: yup.number().required('Please, enter indicated amount!'),
		fee: yup.number().required('Please, enter fee!'),
		status: yup.string().required('Please, enter status!'),
		note: yup.string().required('Please, enter note!'),
		twoFa: yup
			.string()
			.required('Please, enter 2FA code!')
			.max(6, 'Please enter 6 character 2FA code.'),
	});

	useLayoutEffect(() => {
		if (userId) {
			dispatch(changeWalletAddressesUserId(Number(userId)));
			dispatch(getWalletAddressesAllRequest());
			setWalletAddress(null);
			setSelectedCurrency(null);
			setNetwork('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	useEffect(() => {
		if (walletAddresses && selectedCurrency?.id) {
			setFilteredWalletAddresses(
				filterWalletAddressesHandler(walletAddresses, Number(selectedCurrency?.id)),
			);
		}
	}, [walletAddresses, selectedCurrency?.id]);

	useEffect(() => {
		const result = walletAddresses.map((wallet: IWalletAddress) => wallet.asset_id);
		setUserCurrencies(result);
	}, [walletAddresses]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const result = cryptoCurrencyData.filter(({ id }) => userCurrencies.includes(id));
		setFilteredCurrencies(result);
	}, [cryptoCurrencyData, userCurrencies]);

	const handleCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedCurrency(value);
		setWalletAddress(null);
		setNetwork('');
	};

	const handleWalletChange = (value: IWalletAddress) => {
		setWalletAddress(value);
		let finalValue = `${value.chain.name} ${value.chain.code}`;
		if (value.chain.name === value.chain.code) finalValue = value.chain.name;
		setNetwork(finalValue);
	};

	const handleCloseModal = () => {
		setReset(true);
		setFee(0);
		setNetAmount(0);
		setIndicatedAmount(0);
		setWalletAddress(null);
		setSelectedCurrency(null);
		setNetAmountError('');
		setNetwork('');
		closeModal();
	};

	const handleCloseModalClick = () => {
		setReset(true);
		setFee(0);
		setNetAmount(0);
		setIndicatedAmount(0);
		setWalletAddress(null);
		setSelectedCurrency(null);
		setNetAmountError('');
		setNetwork('');
		closeModal();
	};

	return (
		<>
			<Popup open={open} nested onClose={handleCloseModalClick}>
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleCloseModalClick} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Add New Transaction</p>
					</div>
					<Formik
						validateOnBlur
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: IAddCryptoWithdrawalForm, { resetForm, setSubmitting }) => {
							// handleAddTransaction(values);
							// setSubmitting(false);
							// resetForm();
							// handleCloseModal();
							if (userId && walletAddress) {
								const params: IAddWithdrawalCryptoWorker = {
									apiParams: {
										user_id: Number(userId),
										asset_id: handleGetAssetIdByCode(values.asset_code),
										chain_id: Number(walletAddress.chain_id),
										address_id: Number(walletAddress.id),
										status: values.status.toLowerCase(),
										tx_hash: values.tx_hash,
										net_fee: netAmount,
										fee: Number(values.fee),
										gross_fee: Number(values.indicated_amount),
										admin_notes: values.note,
										date: values.date,
										totp: values.twoFa,
									},
									onFinally: (hasError: boolean) => {
										setSubmitting(false);
										dispatch(clearError());
										if (!hasError) {
											resetForm();
											handleCloseModal();
										}
									},
								};
								dispatch(addWithdrawalCryptoRequest(params));
							}
						}}
					>
						{({ isSubmitting, isValid, dirty }) => (
							<Form>
								<div className="popup-body popup-body--transaction">
									<div className="phone-number-input-wrap">
										<UserSearch
											handleChangeUserId={handleChangeUserId}
											resetForm={reset}
											handleResetForm={() => setReset(false)}
										/>
										<div className="input-line">
											<Field name="date" component={SelectDate} />

											<Field
												type="text"
												searchField
												name="asset_code"
												title="Currency"
												component={CurrencySelect}
												arr={filteredCurrencies}
												onChange={handleCurrencyChange}
												className="select--add-fiat-transaction thin--font"
												disabled={!userId || filteredCurrencies.length === 0}
												activeValue={selectedCurrency}
											/>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeIndicatedAmount}
													name="indicated_amount"
													title="Gross amount"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeFee}
													name="fee"
													title="Fee"
												/>
											</div>
											<div className="number__input">
												<div
													className={cn('input input-item--auto-calc', {
														'input--error': netAmountError,
													})}
												>
													<label>
														<p className="input__name">Net Amount</p>
														<div className="input-wrapper">
															<input
																className="input-item"
																placeholder="0.00"
																readOnly
																value={netAmount || ''}
																type="number"
															/>
															<span className="input-icon" />
														</div>
													</label>
													{!!netAmountError && (
														<div className="input-notify">
															<span className="input-notify__text">* {netAmountError}</span>
														</div>
													)}
												</div>
											</div>
											<Field
												required
												type="text"
												name="status"
												title="Status"
												component={Select}
												arr={statusFilterOptions}
												placeholder="Status"
											/>
											<Field
												required
												type="text"
												placeholder="Tx Hash"
												component={Input}
												name="tx_hash"
												title="Tx Hash"
											/>
											<div className="input">
												<label htmlFor="network">
													<p className="input__name">Network</p>
													<div className="input-wrapper">
														<input
															className="input-item"
															name="network"
															type="text"
															readOnly
															placeholder=""
															value={network}
														/>
														<span className="input-icon" />
													</div>
												</label>
											</div>
										</div>
										<div className="input">
											<Field
												required
												type="text"
												name="wallet_address"
												title="Wallet address"
												component={WalletAddressNameSelect}
												arr={filteredWalletAddresses}
												placeholder="Select Wallet address"
												disabled={!filteredWalletAddresses || !selectedCurrency}
												onChange={handleWalletChange}
												activeValue={walletAddress}
												resetCustomSelect={resetWallet}
												setResetCustomSelect={setResetWallet}
											/>
										</div>
										<Field
											required
											type="textarea"
											placeholder="Text"
											component={Input}
											name="note"
											title="Note"
										/>
										<Field
											required
											type="text"
											placeholder="Enter 2fa code"
											component={InputTotp}
											name="twoFa"
											title="2FA Code"
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--column">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={!(isValid && dirty) || isSubmitting || !userId || !walletAddress}
										>
											Confirm
										</button>
										<button
											type="button"
											onClick={handleCloseModal}
											className="btn btn-danger btn--full"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<CreateDepositAccountPopUp
					open={createDepositOpen}
					closeModal={handleCloseCreateDeposit}
					currencySelected={selectedCurrency}
				/>
			</Popup>
		</>
	);
};

export default AddWithdrawalCryptoTransaction;
