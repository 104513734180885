import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navListAfterLogin } from 'routes/routesList';
import { toggleMinimized } from 'redux/reducers/sidebarStates/reducer';
import { getMobileSidebarState } from 'redux/reducers/sidebarStates/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { useHistory } from 'react-router';

import Logo from '../../assets/img/sidebar_logo.svg';
import { SidebarArrow } from '../../assets/inline-svg';
import { logoutRequest } from '../../redux/reducers/auth/reducer';

interface IMenuLink {
	path: string;
	text?: string;
}

const SideBar = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const mobileSidebar1 = useSelector(getMobileSidebarState);
	const sidebar1Ref = useRef<HTMLDivElement | null>(null);
	const handleToggleMinimized = () => dispatch(toggleMinimized());
	const permissions = useSelector(getPermissions);

	const isUserManagementPathname = (pathname: string): boolean =>
		pathname === '/user-management' || pathname === '/2FA';
	const isUserManagementPath = (pathname: string): boolean =>
		String(pathname) === 'user-management' || String(pathname) === 'createUser';
	const location = useLocation<any>();
	const [open, setOpen] = useState<boolean>(isUserManagementPathname(location.pathname));

	const [active, setActive] = useState<boolean>(
		isUserManagementPath(location.pathname.split('/')[1]),
	);
	useLayoutEffect(() => {
		if (isUserManagementPathname(location.pathname)) {
			setOpen(true);
		}

		if (isUserManagementPath(location.pathname.split('/')[1])) {
			setActive(true);
		} else {
			setActive(false);
		}
	}, [location.pathname]);

	const [checkedRequests, setCheckedRequests] = useState(false);
	const resetRequests: IMenuLink[] = [
		{ path: navListAfterLogin.resetRequestUser2fa.path, text: 'Pending Deposits' },
		{ path: navListAfterLogin.resetRequestUserPassword.path, text: 'Pending Withdrawals' },
		{ path: navListAfterLogin.resetRequestAdmin2fa.path, text: 'Pending Reset Requests' },
		{ path: navListAfterLogin.resetRequestAdminPassword.path, text: 'Pending Reset Requests2' },
	];
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIfResetRequestActive = (match: any, location: any) => {
		return resetRequests.some((request) => location.pathname.includes(request.path));
	};
	/// //////////
	const [checkedReports, setCheckedReports] = useState(false);
	const reports: IMenuLink[] = [
		{ path: navListAfterLogin.transfersAndTradeReportsTradesCrypto.path, text: 'Trades' },
		{ path: navListAfterLogin.transfersAndTradeReportsTransfersCrypto.path, text: 'Transfers' },
		{ path: navListAfterLogin.transfersAndTradeReportsDepositCrypto.path, text: 'Deposits' },
		{ path: navListAfterLogin.transfersAndTradeReportsWithdrawalsCrypto.path, text: 'Withdrawals' },
		{ path: navListAfterLogin.transfersAndTradeReportsTradesLimits.path },
		{ path: navListAfterLogin.transfersAndTradeReportsTransfersLimits.path },
		{ path: navListAfterLogin.transfersAndTradeReportsPendingWithdrawal.path },
	];

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIfReportActive = (match: any, location: any) => {
		return reports.some((request) => location.pathname.includes(request.path));
	};
	/// ///
	const [checkedFee, setCheckedFee] = useState(false);
	const fee: IMenuLink[] = [
		{ path: navListAfterLogin.feeManagementWithdrawFee.path, text: 'Withdraw Fee' },
		{
			path: navListAfterLogin.feeManagementDepositFee.path,
			text: 'Deposit Fee',
		},
		{ path: navListAfterLogin.feeManagementTradeFee.path, text: 'Trade Fee' },
		{
			path: navListAfterLogin.feeManagementSpreadFee.path,
			text: 'Spread Fee.',
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIFeeActive = (match: any, location: any) => {
		return fee.some((request) => location.pathname.includes(request.path));
	};
	/// ///

	const getNavLinkClass = (path: string[]) => {
		if (path.indexOf(location.pathname) !== -1) {
			return 'sidebar1-nav__link active';
		}
		return 'sidebar1-nav__link';
	};

	const submenuParentClickHandler = (event: any) => {
		const target = event.target.closest('.sidebar1-nav__link');
		if (target.classList.contains('active')) {
			target.classList.remove('active');
		} else {
			target.classList.add('active');
		}
	};

	return (
		<div ref={sidebar1Ref} className={`sidebar1 ${mobileSidebar1 ? 'active' : ''}`}>
			<button className="sidebar1-nav__logo" type="button" onClick={handleToggleMinimized}>
				<img src={Logo} alt="" />
			</button>
			<div className="sidebar1-top">
				<nav className="sidebar1-nav">
					<ul className="sidebar1-nav__list">
						{!!permissions?.[EPermissionNames.DASHBOARD]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink className="sidebar1-nav__link" to={navListAfterLogin.home.path}>
									<span className="sidebar1-nav__icon">
										<IconSvg name="grid-view" />
									</span>
									Dashboard
								</NavLink>
							</li>
						)}

						{permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read ||
						permissions?.[EPermissionNames.RESET_REQUEST]?.only_read ? (
							<>
								<li className="sidebar1-nav__list-item">
									<div
										className={
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											location.state &&
											(location.state?.fromPendingDepositCrypto ||
												location.state.fromPendingWithdrawalCrypto)
												? 'sidebar1-nav__link active'
												: getNavLinkClass([
														'/reset-request/users/twofa',
														'/reset-request/users/password',
														'/reset-request/admin/twofa',
														'/reset-request/admin/password',
														'/transfers-and-trade-reports/pending-withdrawal/crypto',
														'/transfers-and-trade-reports/pending-deposits/crypto',
														'/transfers-and-trade-reports/pending-withdrawal/fiat',
														'/transfers-and-trade-reports/pending-deposits/fiat',
												  ])
										}
										onClick={submenuParentClickHandler}
									>
										<div className="sidebar1-nav__icon">
											<IconSvg name="pending_approval" />
										</div>
										<div className="sidebar1-nav__link-text">Pending Approval</div>
										<div className="sidebar1-nav__arrow" />
									</div>
									<div className="sidebar1-nav__submenu">
										<ul>
											{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
												<>
													{/* <li>
														<span className="sidebar1-nav__link">Pending Deposits</span>
													</li> */}
													<li>
														<NavLink
															to="/transfers-and-trade-reports/pending-deposits/crypto"
															className={
																// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																// @ts-ignore
																location.state && location.state?.fromPendingDepositCrypto
																	? 'sidebar1-nav__link active'
																	: getNavLinkClass([
																			'/transfers-and-trade-reports/pending-deposits/crypto',
																			'/transfers-and-trade-reports/pending-deposits/fiat',
																	  ])
															}
															activeClassName=""
														>
															Pending Deposits
														</NavLink>
													</li>
													<li>
														<NavLink
															to="/transfers-and-trade-reports/pending-withdrawal/crypto"
															className={
																location.state && location.state?.fromPendingWithdrawalCrypto
																	? 'sidebar1-nav__link active'
																	: getNavLinkClass([
																			'/transfers-and-trade-reports/pending-withdrawal/crypto',
																			'/transfers-and-trade-reports/pending-withdrawal/fiat',
																	  ])
															}
															activeClassName=""
														>
															Pending Withdrawals
														</NavLink>
													</li>
												</>
											)}
											{!!permissions?.[EPermissionNames.RESET_REQUEST]?.only_read && (
												<>
													<li>
														<NavLink
															to="/reset-request/users/twofa"
															className={getNavLinkClass([
																'/reset-request/admin/twofa',
																'/reset-request/admin/password',
																'/reset-request/users/twofa',
																'/reset-request/users/password',
															])}
															activeClassName=""
														>
															Pending Reset Requests
														</NavLink>
													</li>
												</>
											)}
											{/* <li>
												<NavLink to="/reset-request/admin/password">
													Pending Reset Requests2
												</NavLink>
											</li> */}
										</ul>
									</div>
								</li>
							</>
						) : null}

						{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
							<>
								<li className="sidebar1-nav__list-item">
									<div
										className={
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											location.state &&
											(location.state?.fromDepositCryptoReports ||
												location.state.fromWithdrawalCryptoReports ||
												location.state.fromTransfersCrypto ||
												location.state.fromTradeReports ||
												location.state.fromTransfersFiat ||
												location.state.fromLedgerReports)
												? 'sidebar1-nav__link active'
												: getNavLinkClass([
														'/transfers-and-trade-reports/trades',
														// '/transfers-and-trade-reports/ledger-aggregated',
														'/transfers-and-trade-reports/transfers/crypto',
														'/transfers-and-trade-reports/transfers/fiat',
														'/transfers-and-trade-reports/deposit/crypto',
														'/transfers-and-trade-reports/deposit/fiat',
														'/transfers-and-trade-reports/withdrawals/crypto',
														'/transfers-and-trade-reports/ledger',
												  ])
										}
										onClick={submenuParentClickHandler}
									>
										<div className="sidebar1-nav__icon">
											<IconSvg name="reports" />
										</div>
										<div className="sidebar1-nav__link-text">Reports</div>
										<div className="sidebar1-nav__arrow" />
									</div>
									<div className="sidebar1-nav__submenu">
										<ul>
											<li>
												<NavLink
													to="/transfers-and-trade-reports/trades"
													className={
														location.state && location.state.fromTradeReports
															? 'sidebar1-nav__link active'
															: getNavLinkClass(['/transfers-and-trade-reports/trades'])
													}
													activeClassName=""
												>
													Trades
												</NavLink>
											</li>
											<li>
												<NavLink
													to="/transfers-and-trade-reports/transfers/crypto"
													className={
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														location.state &&
														(location.state.fromTransfersCrypto || location.state.fromTransfersFiat)
															? 'sidebar1-nav__link active'
															: getNavLinkClass([
																	'/transfers-and-trade-reports/transfers/crypto',
																	'/transfers-and-trade-reports/transfers/fiat',
															  ])
													}
													activeClassName=""
												>
													Transfers
												</NavLink>
											</li>
											<li>
												<NavLink
													to="/transfers-and-trade-reports/deposit/crypto"
													className={
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														location.state && location.state?.fromDepositCryptoReports
															? 'sidebar1-nav__link active'
															: getNavLinkClass([
																	'/transfers-and-trade-reports/deposit/crypto',
																	'/transfers-and-trade-reports/deposit/fiat',
															  ])
													}
													activeClassName=""
												>
													Deposits
												</NavLink>
											</li>
											<li>
												<NavLink
													to="/transfers-and-trade-reports/withdrawals/crypto"
													className={
														location.state && location.state?.fromWithdrawalCryptoReports
															? 'sidebar1-nav__link active'
															: getNavLinkClass(['/transfers-and-trade-reports/withdrawals/crypto'])
													}
													activeClassName=""
												>
													Withdrawals
												</NavLink>
											</li>
											{/* <li>
												<NavLink
													to="/transfers-and-trade-reports/ledger-aggregated"
													className={getNavLinkClass([
														'/transfers-and-trade-reports/ledger-aggregated',
													])}
													activeClassName=""
												>
													Ledger aggregated
												</NavLink>
											</li> */}
											<li>
												<NavLink
													to="/transfers-and-trade-reports/ledger"
													className={
														location.state && location.state.fromLedgerReports
															? 'sidebar1-nav__link active'
															: getNavLinkClass(['/transfers-and-trade-reports/ledger'])
													}
													activeClassName=""
												>
													Ledger
												</NavLink>
											</li>
										</ul>
									</div>
								</li>
							</>
						)}

						{permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read ||
						permissions?.[EPermissionNames.FEE_MANAGEMENT]?.only_read ||
						permissions?.[EPermissionNames.REQUIRED_EMAILS]?.only_read ? (
							<li className="sidebar1-nav__list-item">
								<div
									className={getNavLinkClass([
										'/fee-management/withdraw-fee',
										'/fee-management/deposit-fee',
										'/fee-management/trade-fee',
										'/fee-management/spread-management-fee',
										'/fee-management/currencles-fee',
										'/required-emails',
										'/transfers-and-trade-reports/trades-limits/crypto',
										'/transfers-and-trade-reports/trades-limits/fiat',
										'/transfers-and-trade-reports/transfer-limits/crypto',
										'/transfers-and-trade-reports/transfer-limits/fiat',
									])}
									onClick={submenuParentClickHandler}
								>
									<div className="sidebar1-nav__icon">
										<IconSvg name="global_settings" />
									</div>
									<div className="sidebar1-nav__link-text">Global Settings</div>
									<div className="sidebar1-nav__arrow" />
								</div>
								<div className="sidebar1-nav__submenu">
									<ul>
										{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
											<>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/trades-limits/crypto"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/trades-limits/crypto',
															'/transfers-and-trade-reports/trades-limits/fiat',
														])}
														activeClassName=""
													>
														Trade Limits
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/transfer-limits/crypto"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/transfer-limits/crypto',
															'/transfers-and-trade-reports/transfer-limits/fiat',
														])}
														activeClassName=""
													>
														Transfer Limits
													</NavLink>
												</li>
											</>
										)}

										{!!permissions?.[EPermissionNames.FEE_MANAGEMENT]?.only_read && (
											<>
												<li>
													<NavLink
														to="/fee-management/withdraw-fee"
														className={getNavLinkClass([
															'/fee-management/withdraw-fee',
															'/fee-management/deposit-fee',
															'/fee-management/trade-fee',
															'/fee-management/spread-management-fee',
															'/fee-management/currencles-fee',
														])}
														activeClassName=""
													>
														Default Fees
													</NavLink>
												</li>
											</>
										)}

										{!!permissions?.[EPermissionNames.REQUIRED_EMAILS]?.only_read && (
											<li>
												<NavLink
													to="/required-emails"
													className={getNavLinkClass(['/required-emails'])}
													activeClassName=""
												>
													Default Emails
												</NavLink>
											</li>
										)}
									</ul>
								</div>
							</li>
						) : null}

						{!!permissions?.[EPermissionNames.USER_MANAGEMENT]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink className="sidebar1-nav__link" to={navListAfterLogin.userManagement.path}>
									<div className="sidebar1-nav__icon">
										<IconSvg name="user_management" />
									</div>
									<div className="sidebar1-nav__link-text">User Management</div>
								</NavLink>
							</li>
						)}

						{!!permissions?.[EPermissionNames.ADMIN_MANAGEMENT]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink
									className="sidebar1-nav__link"
									to={navListAfterLogin.adminManagementPage.path}
								>
									<div className="sidebar1-nav__icon">
										<IconSvg name="admin_management" />
									</div>
									<div className="sidebar1-nav__link-text">Admin Management</div>
								</NavLink>
							</li>
						)}

						{!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink className="sidebar1-nav__link" to={navListAfterLogin.depositAccounts.path}>
									<div className="sidebar1-nav__icon">
										<IconSvg name="deposit-account" />
									</div>
									<div className="sidebar1-nav__link-text">Deposit Accounts</div>
								</NavLink>
							</li>
						)}

						{!!permissions?.[EPermissionNames.WALLET_MANAGEMENT]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink className="sidebar1-nav__link" to={navListAfterLogin.walletBalances.path}>
									<div className="sidebar1-nav__icon">
										<IconSvg name="liquidity_management" />
									</div>
									<div className="sidebar1-nav__link-text">Liquidity Management</div>
								</NavLink>
							</li>
						)}

						{!!permissions?.[EPermissionNames.REFERRAL_MANAGEMENT]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink className="sidebar1-nav__link" to={navListAfterLogin.referralSystem.path}>
									<div className="sidebar1-nav__icon">
										<IconSvg name="referral_management" />
									</div>
									<div className="sidebar1-nav__link-text">Referral Management</div>
								</NavLink>
							</li>
						)}
						{!!permissions?.[EPermissionNames.MESSAGES]?.only_read && (
							<li className="sidebar1-nav__list-item">
								<NavLink
									to={navListAfterLogin.messages.path}
									className={getNavLinkClass(['messages/planned', 'messages/sent'])}
									activeClassName=""
								>
									<div className="sidebar1-nav__icon">
										<IconSvg name="messages" />
									</div>
									<div className="sidebar1-nav__link-text">Messages</div>
								</NavLink>
							</li>
						)}

						<li className="sidebar1-nav__list-item">
							<div className="sidebar1-nav__link" onClick={submenuParentClickHandler}>
								<div className="sidebar1-nav__icon">
									<IconSvg name="account_settings" />
								</div>
								<div className="sidebar1-nav__link-text">Account Settings</div>
								<div className="sidebar1-nav__arrow" />
							</div>
							<div className="sidebar1-nav__submenu">
								<ul>
									<li>
										<button
											className="sidebar1-nav__link"
											type="button"
											onClick={() => {
												dispatch(logoutRequest({ history }));
											}}
										>
											Log Out
										</button>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default SideBar;
