/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IWalletAddressStore,
	IWalletAddressGetResponse,
	IWalletAddressRequestPayload,
	IWalletAddressesPageInfo,
	IUserWalletAddressGetRequestPayload,
} from './types';

// ==========================================:
export const initialState: IWalletAddressStore = {
	data: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 10,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	userId: 1,
	loading: false,
	userWalletAddressData: {
		address: '',
		qr: '',
		tag: '',
	},
};

// ==========================================:2
const walletAddresses = createSlice({
	name: '@@walletAddresses',
	initialState,
	reducers: {
		getWalletAddressesRequest: (state) => {
			const newState = state;
			newState.data = {
				current_page: 1,
				data: [],
				first_page_url: '',
				from: 1,
				last_page: 1,
				last_page_url: '',
				links: [],
				next_page_url: '',
				path: '',
				per_page: 10,
				prev_page_url: '',
				to: 1,
				id: 1,
				total: 1,
			};
			newState.loading = true;
		},
		getWalletAddressesSuccess: (state, action: PayloadAction<IWalletAddressGetResponse>) => {
			const newState = state;
			newState.data = action.payload;
			newState.loading = false;
		},
		getWalletAddressesAllRequest: (state) => {
			const newState = state;
			newState.data = {
				current_page: 1,
				data: [],
				first_page_url: '',
				from: 1,
				last_page: 1,
				last_page_url: '',
				links: [],
				next_page_url: '',
				path: '',
				per_page: 10,
				prev_page_url: '',
				to: 1,
				id: 1,
				total: 1,
			};
			newState.loading = true;
		},
		getUserWalletAddressesRequest: (
			state,
			action: PayloadAction<IUserWalletAddressGetRequestPayload>,
		) => {
			const newState = state;
			newState.loading = true;
		},
		getUserWalletAddressesSuccess: (state, action: PayloadAction<any>) => {
			const newState = state;
			const { payload } = action;
			if (payload) {
				newState.userWalletAddressData.address = payload.address;
				newState.userWalletAddressData.qr = payload.qr;
				newState.userWalletAddressData.tag = payload.tag;
			}
			newState.loading = false;
		},
		clearUserWalletAddresses: (state) => {
			const newState = state;
			newState.userWalletAddressData = {
				address: '',
				qr: '',
				tag: '',
			};
		},
		deleteWalletAddressRequest: (state, action: PayloadAction<{ id: number }>) => {
			const newState = state;

			newState.loading = true;
		},
		deleteWalletAddressError: (state) => {
			const newState = state;
			newState.loading = false;
		},
		updateWalletAddressRequest: (state, action: PayloadAction<IWalletAddressRequestPayload>) => {
			const newState = state;

			newState.loading = true;
		},
		changeWalletAddressesPage: (state, action: PayloadAction<IWalletAddressesPageInfo>) => {
			const newState = state;
			newState.data.current_page = action.payload.current_page;
			newState.data.per_page = action.payload.per_page;
			newState.loading = true;
		},
		changeWalletAddressesUserId: (state, action: PayloadAction<number>) => {
			const newState = state;
			newState.userId = action.payload;
		},
	},
});

export default walletAddresses.reducer;
export const {
	getWalletAddressesRequest,
	getWalletAddressesSuccess,
	getWalletAddressesAllRequest,
	updateWalletAddressRequest,
	deleteWalletAddressRequest,
	deleteWalletAddressError,
	changeWalletAddressesPage,
	changeWalletAddressesUserId,
	getUserWalletAddressesRequest,
	getUserWalletAddressesSuccess,
	clearUserWalletAddresses,
} = walletAddresses.actions;
