import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ISettingsApi } from './types';
// ==========================================:

export const settings: ISettingsApi = {
	verification: (payload) =>
		http.post(endpoint.settings.VERIFICATION, payload).then((response) => {
			return response.data;
		}),
};
