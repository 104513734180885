import { FC, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { routesListBeforeLogin, routesListAfterLogin } from 'routes/routesList';
import LocaleStorage from 'services/HOC/LocaleStorage/index';
import { checkAndRefreshToken } from 'services/utils/tokenRefresh';
import { useSelector } from 'react-redux';
import { getPermissions, getUserData } from 'redux/reducers/auth/selectors';
import { IRouteItem } from 'routes/types';
import Dashboard from 'layouts/Dashboard';
import Auth from 'layouts/Auth';
import { ROUTES } from 'routes/constants';
import TermsOfUse from '../TermsUse/TermsUse';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
// ==========================================:

const RoutesList: FC<{
	routes: IRouteItem[];
	redirect: string;
	loggedIn: boolean;
}> = ({ routes, redirect, loggedIn }) => {
	const permissions = useSelector(getPermissions);
	// eslint-disable-next-line array-callback-return,consistent-return
	const patch = routes?.map((route) => {
		if (route.permission && permissions?.[route.permission]?.only_read) {
			return route.path;
		}
	});
	const patchNoUndefined = patch.filter((item) => item !== undefined);
	if (!loggedIn) {
		return (
			<Switch>
				{routes?.map((route) => (
					<Route
						key={route.path}
						exact={!route.isNotExact}
						path={route.path}
						component={route.component}
					/>
				))}
				<Redirect to={redirect} />
			</Switch>
		);
	}

	if (permissions) {
		return (
			<Switch>
				{routes?.map((route) => {
					if (route.permission && permissions?.[route.permission]?.only_read) {
						return (
							<Route
								key={route.path}
								exact={!route.isNotExact}
								path={route.path}
								component={route.component}
							/>
						);
					}
					return null;
				})}
				<Route key="/terms" path="/terms" component={TermsOfUse} />
				<Route key="/privacy" path="/privacy" component={PrivacyPolicy} />
				<Redirect to={patchNoUndefined[0] || 'admin-panel/crypto'} />
			</Switch>
		);
	}

	return <></>;
};

const App: FC = () => {
	const location = useLocation();
	const login = useSelector(getUserData);

	useLayoutEffect(() => {
		checkAndRefreshToken();
	}, [location.pathname]);
	return (
		<LocaleStorage>
			<div className="wrapper">
				{login !== null ? (
					<Auth>
						<RoutesList routes={routesListAfterLogin} redirect={ROUTES.adminPanel.index} loggedIn />
					</Auth>
				) : (
					<Dashboard>
						<RoutesList routes={routesListBeforeLogin} redirect="/login" loggedIn={false} />
					</Dashboard>
				)}
			</div>
		</LocaleStorage>
	);
};

export default App;
