import React, { ChangeEvent, FC, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { TableFilterRange } from 'assets/inline-svg';
import cn from 'classnames';

export interface IRangeSelectProps {
	title: string;
	onChange: (from: string, to: string) => void;
}

const RangeSelect: FC<IRangeSelectProps> = ({ onChange, title }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownElement = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownElement, () => setIsOpen(false));
	const [from, setFrom] = useState<string>('');
	const [fromError, setFromError] = useState<string>('');
	const [to, setTo] = useState<string>('');
	const [toError, setToError] = useState<string>('');

	const handleValidate = () => {
		if (!from) {
			setFromError('Enter from value');
			setToError('');
			return false;
		}
		if (!to) {
			setToError('Enter to value');
			setFromError('');
			return false;
		}
		if (Number(from) > Number(to)) {
			setToError('From greater then to');
			return false;
		}
		setToError('');
		setFromError('');
		return true;
	};

	const handleChangeFrom = (e: ChangeEvent<HTMLInputElement>) => {
		setFrom(e.target.value);
	};
	const handleChangeTo = (e: ChangeEvent<HTMLInputElement>) => {
		setTo(e.target.value);
	};

	const handleChangeRange = () => {
		const validate = handleValidate();
		if (validate) {
			onChange(from, to);
			setTo('');
			setFrom('');
			setIsOpen(false);
		}
	};

	return (
		<div
			ref={dropdownElement}
			className={`select select--table select--range ${isOpen ? 'active' : ''}`}
		>
			<button
				type="button"
				className="select__current"
				onClick={() => setIsOpen((state) => !state)}
			>
				{title}
				<span className="select__current-arrow">
					<TableFilterRange />
				</span>
			</button>
			<div className="select__drop select__drop--range">
				<div className="form__range-inputs">
					<div className={cn('input', { 'input--error': fromError })}>
						<label htmlFor="from">
							<p className="input__name">From</p>
							<div className="input-wrapper">
								<input
									type="number"
									name="from"
									placeholder="From"
									className="input-item"
									value={from}
									onChange={handleChangeFrom}
								/>
								<span className="input-icon" />
							</div>
							{!!fromError && (
								<div className="input-notify">
									<span className="input-notify__text">{fromError}</span>
								</div>
							)}
						</label>
					</div>
					<div className={cn('input', { 'input--error': toError })}>
						<label htmlFor="to">
							<p className="input__name">To</p>
							<div className="input-wrapper">
								<input
									name="to"
									type="number"
									placeholder="To"
									className="input-item"
									value={to}
									onChange={handleChangeTo}
								/>
								<span className="input-icon" />
							</div>
							{!!toError && (
								<div className="input-notify">
									<span className="input-notify__text">{toError}</span>
								</div>
							)}
						</label>
					</div>
				</div>
				<button type="button" onClick={handleChangeRange} className="btn btn-primary">
					Filter
				</button>
			</div>
		</div>
	);
};

export default RangeSelect;
