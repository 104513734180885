import { useDispatch, useSelector } from 'react-redux';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import { setUserData } from 'redux/reducers/users/reducer';
import ReferralItem from './ReferralItem';

const ReferralManagement = () => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserCreateData);

	const updateHandler = ({ commission }: any) => {
		dispatch(setUserData({ ...userData, user_data: { ...userData?.user_data, commission } }));
	};
	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--trade-management">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Referral %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								<ReferralItem updateHandler={updateHandler} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferralManagement;
