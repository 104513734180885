import { call, put, select, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import axios from 'axios';
import {
	getWalletAddressesRequest,
	updateWalletAddressRequest,
	deleteWalletAddressRequest,
	getWalletAddressesSuccess,
	changeWalletAddressesPage,
	deleteWalletAddressError,
	getUserWalletAddressesSuccess,
	getUserWalletAddressesRequest,
	getWalletAddressesAllRequest,
} from './reducer';
import {
	IUserWalletAddressGetRequestPayload,
	IUserWalletAddressGetRequestResponse,
	IWalletAddressesPageInfo,
	IWalletAddressGetResponse,
	IWalletAddressRequestPayload,
} from './types';
import { getWalletAddressesPageInfo, getWalletAddressesUserId } from './selectors';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';
// =============================================================:

// =============================================================:
function* getWalletAddressesRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		const pageInfo: IWalletAddressesPageInfo = yield select(getWalletAddressesPageInfo);
		const response: IWalletAddressGetResponse = yield call(api.walletAddresses.getWalletAddresses, {
			userId,
			pageInfo,
		});

		yield put(getWalletAddressesSuccess(response));
	} catch (error) {
		notificationContainer('Wallet address were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getWalletAddressesAllRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		const pageInfo: IWalletAddressesPageInfo = {
			per_page: 99999,
			current_page: 1,
		};
		const response: IWalletAddressGetResponse = yield call(api.walletAddresses.getWalletAddresses, {
			userId,
			pageInfo,
		});

		yield put(getWalletAddressesSuccess(response));
	} catch (error) {
		notificationContainer('Wallet address were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteWalletAddressesRequestWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		yield call(api.walletAddresses.deleteWalletAddress, { userId, address_id: payload.id });
		yield put(getWalletAddressesRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(error.response?.data.errors[0], 'error');
			if (
				error?.response?.data?.errors[0] ===
				'record_can’t_be_deleted_at_the_moment_this_wallet_is_used_in_one_or_more“pending“_operations_please_wait_untill_all_such_operations_are_processed'
			) {
				notificationContainer(
					'This wallet address is used in one or more “Pending“ operations.<br>' +
						'Please wait untill all such operations are processed.',
					'user_blocked',
					'Record can’t be deleted at the moment',
				);
				yield put(deleteWalletAddressError());
			}
		}
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletDeleted }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* updateWalletAddressesRequestWorker(action: PayloadAction<IWalletAddressRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		yield call(api.walletAddresses.updateWalletAddress, { userId, ...payload });
		yield put(getWalletAddressesRequest());
		yield put(
			setPopUpData({
				title: 'Wallet Address updated',
				message: notificationsMessagesPopUp.walletSuccessfully(payload?.data?.nickname),
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletUpdated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

function* getUserWalletAddressesRequestWorker(
	action: PayloadAction<IUserWalletAddressGetRequestPayload>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: IUserWalletAddressGetRequestResponse = yield call(
			api.walletAddresses.getUserWalletAddresses,
			payload,
		);

		yield put(getUserWalletAddressesSuccess(response));
	} catch (error) {
		notificationContainer('Wallet address were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* walletAddressesSaga() {
	yield takeEvery(getWalletAddressesRequest.type, getWalletAddressesRequestWorker);
	yield takeEvery(getWalletAddressesAllRequest.type, getWalletAddressesAllRequestWorker);
	yield takeEvery(getUserWalletAddressesRequest.type, getUserWalletAddressesRequestWorker);
	yield takeEvery(updateWalletAddressRequest.type, updateWalletAddressesRequestWorker);
	yield takeEvery(deleteWalletAddressRequest.type, deleteWalletAddressesRequestWorker);
	yield takeEvery(changeWalletAddressesPage.type, getWalletAddressesRequestWorker);
}
