import ResetTwoFaAdminItem from 'components/ResetRequest/ResetTwoFaAdminItem';
import React, { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { rejectAdmin2FA, resetAdmin2FA } from 'redux/reducers/users/reducer';
import { getAdminTwoFaResetRequests, getUserLoader } from 'redux/reducers/users/selectors';
import ReactPaginate from 'react-paginate';
import PerPageDropdown from '../../../../../services/utils/PaginationPerPageDropdown';
import Loading from '../../../../../layouts-elements/Loading/Loading';
import TableBodyNoData from '../../../../../ui/TableBodyNoData/TableBodyNoData';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}

const TwofaTab: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	const dispatch = useDispatch();
	const requests = useSelector(getAdminTwoFaResetRequests);
	const isLoading = useSelector(getUserLoader);
	const permissions = useSelector(getPermissions);

	const handleApprove = (id: number) => {
		dispatch(resetAdmin2FA({ id }));
	};

	const handleReject = (id: number, message: string) => {
		dispatch(rejectAdmin2FA({ id, message }));
	};

	return (
		<div className="table table--user-management-reset-request--admin">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Admin ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>IP address</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reason</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{isLoading ? (
					<Loading />
				) : (
					<>
						{requests?.data.length ? (
							requests?.data?.map((item: any) => (
								<ResetTwoFaAdminItem
									key={item.id}
									data={item}
									handleApprove={handleApprove}
									handleReject={handleReject}
									permission={!!permissions?.[EPermissionNames.RESET_REQUEST].editable}
								/>
							))
						) : (
							<TableBodyNoData />
						)}
					</>
				)}
			</div>
			<div className="table-footer">
				<div className="pagination-block pagination-block--full">
					<PerPageDropdown
						options={[10, 20, 50, 100]}
						setPerPage={setItemsPerPageHandler}
						perPage={itemsPerPage}
					/>
					<ReactPaginate
						breakLabel="..."
						pageCount={requests?.last_page || 1}
						onPageChange={handlePageChange}
						forcePage={page - 1}
						className="pagination"
						activeClassName="active"
						previousClassName="pagination__arrow pagination__arrow--prev"
						nextClassName="pagination__arrow pagination__arrow--next"
						previousLabel=""
						nextLabel=""
					/>
				</div>
			</div>
		</div>
	);
};

export default TwofaTab;
