import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const SuccessUpdatePopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">{title || 'Successful'}</p>
				</div>
				<div className="popup-img popup-icon--type2">
					<svg
						width="72"
						height="72"
						viewBox="0 0 72 72"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
						<path
							d="M36 61C49.75 61 61 49.75 61 36C61 22.25 49.75 11 36 11C22.25 11 11 22.25 11 36C11 49.75 22.25 61 36 61Z"
							fill="#30C06E"
							stroke="#30C06E"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M25.375 36L32.45 43.075L46.625 28.925"
							stroke="white"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				{!!message && (
					<div className="popup-text">
						<p>{message}</p>
					</div>
				)}
				<div className="popup-text popup-text--type2 popup-text--center">
					<p>The user fee has been changed</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button onClick={closeModal} type="button" className="btn btn-primary btn--full">
							Ok
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessUpdatePopUp;
