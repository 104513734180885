import { AxiosError } from 'axios';
import L from 'i18n-react';
import { notificationContainer } from 'services/utils/notificationContainer';
import { setErrorMsg } from 'redux/reducers/errors/reducer';
import { store } from 'redux/store';
import { customResponseErrors } from './customResponseError/customResponseErrors';
// ==========================================:
const responseErrors = (error: AxiosError, depositableList = '') => {
	const { response } = error;
	if (!response) {
		notificationContainer(String(L.translate(`Errors.server_error`)), 'error');
	}

	if (response) {
		const { status, data } = response;
		const errorList: Array<string> = [];

		if (typeof data === 'object') {
			if (data?.message) {
				errorList.push(data?.message);
			} else {
				Object.keys(data?.errors).forEach((item) =>
					errorList.push(data?.errors[item as keyof typeof data.errors]),
				);
			}
		}
		const errorMessage = String(L.translate(`Errors.${errorList.flat()[0]}`));
		switch (status) {
			case 400: // Bad request
				if (errorMessage.includes('invalid_credentials')) {
					notificationContainer('You have entered an invalid username or password.', 'error');
					store.dispatch(setErrorMsg('invalid_credentials'));
					return;
				}
				if (errorMessage.includes('invalid_email')) {
					notificationContainer('No account is associated with this email.', 'error');
					store.dispatch(setErrorMsg('invalid_email'));
					return;
				}
				if (errorMessage.includes('asset_is_not_depositable')) {
					notificationContainer(`Only deposits in ${depositableList} are allowed`, 'error');
					return;
				}
				if (errorMessage.includes('_numeric_value_out_of_range')) {
					notificationContainer(
						`Maximum deposit amount is 999,999. Please contact support for additional information`,
						'error',
					);
					return;
				}
				customResponseErrors(errorMessage, 'error', response);
				break;

			// case 403: // Form validation
			// 	notificationContainer(errorMessage, 'error');
			// 	break;
			case 404: // Not found
				if (
					errorMessage.includes('model_not_found') &&
					window.location.href.indexOf('first-login-2fa') > -1
				) {
					notificationContainer('You have already activated your account.', 'error');
					return;
				}
				notificationContainer(errorMessage, 'error');
				break;
			case 422: // Form validation
				if (errorMessage.includes('the_email_has_already_been_taken.')) {
					notificationContainer('The email has already been taken.', 'error');
					return;
				}
				customResponseErrors(errorMessage, 'error', response);
				break;

			case 429: // Too Many Attempts
				notificationContainer(String(L.translate(`Errors.too_many_requests.`)), 'error');
				break;

			case 500: // Internal Server Error
				notificationContainer(String(L.translate(`Errors.server_error.`)), 'error');
				break;
			default:
				break;
		}
	}
};

export { responseErrors };
