/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useState } from 'react';
import { ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersRequest } from 'redux/reducers/users/reducer';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import ReactPaginate from 'react-paginate';
import { getUsersData } from 'redux/reducers/users/selectors';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import { IUser } from 'redux/reducers/users/types';
import { getMessages, getOneMessage } from 'redux/reducers/messages/selectors';

import ManageUsersHeader from './ManageUsersHeader';
import ManageUsersRow from './ManageUsersRow';

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
];

interface IManageUsersTable {
	onClose: () => void;
	onCheckSingleUser: (id: number, target?: boolean) => void;
	checkedUsers: number[];
	disabled: boolean;
	setUsers: React.Dispatch<React.SetStateAction<number[]>>;
}
const ManageUsersTable: FC<IManageUsersTable> = ({
	onClose,
	checkedUsers,
	onCheckSingleUser,
	disabled,
	setUsers,
}) => {
	const dispatch = useDispatch();
	const users = useSelector(getUsersData);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const message = useSelector(getOneMessage);
	const [perPage, setPerPage] = useState<number>(10);
	const pageCount = users?.last_page ? users.last_page : 1;
	const [currentPage, setCurrentPage] = useState(users?.current_page || 1);
	const currentUsers = users?.data.map((user) => user.id) || [];

	const [query, setQuery] = useState({});

	const [checked, setChecked] = useState(false);

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (!currentSearchValue) {
			setQuery({});
		} else {
			setQuery({
				[searchField.value]: currentSearchValue,
			});
		}
	};

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	useEffect(() => {
		dispatch(
			getUsersRequest({
				per_page: perPage,
				current_page: currentPage,
				status: 'approved',
				...query,
			}),
		);
	}, [currentPage, perPage, dispatch, query]);

	useEffect(() => {
		if (currentUsers.every((id) => checkedUsers.includes(id))) {
			setChecked(true);
		} else {
			setChecked(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users?.current_page, users?.data]);

	const handleHeaderChecked = (target: boolean) => {
		if (target) {
			currentUsers && setUsers([...checkedUsers, ...currentUsers]);
		} else {
			setUsers(checkedUsers.filter((user) => !currentUsers?.includes(user)));
		}
		setChecked(!checked);
	};

	return (
		<>
			<div className="title-block title-block--deposit">
				<button type="button" className="btn btn--icon btn--back" onClick={onClose} />
				<p className="title-message">Manage users for message</p>
				{message && <span className="title__message-id">Message ID:{message.id}</span>}
			</div>
			<div className="active-trade_filters active-message_filters">
				<div className="active-trade_filters--search">
					<SearchWithSelect
						handleSearch={handleSearch}
						selectArr={FiltersSearchArray}
						resetValue={resetSearchValue}
					/>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--type2 table--manage-users">
						<div className="table-header">
							<ManageUsersHeader
								checked={checked}
								onCheck={handleHeaderChecked}
								disabled={disabled}
							/>
						</div>
						<div className="table-body">
							{users?.data && users.data.length ? (
								users.data.map((user) => (
									<ManageUsersRow
										onCheckSingleUser={onCheckSingleUser}
										checkedUsers={checkedUsers}
										checked={checked}
										data={user}
										key={user.id}
										disabled={disabled}
									/>
								))
							) : (
								<TableBodyNoData />
							)}
						</div>
					</div>
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							<ReactPaginate
								breakLabel="..."
								pageCount={pageCount}
								onPageChange={handlePageClick}
								forcePage={currentPage - 1}
								className="pagination"
								activeClassName="active"
								previousClassName="pagination__arrow pagination__arrow--prev"
								nextClassName="pagination__arrow pagination__arrow--next"
								previousLabel=""
								nextLabel=""
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManageUsersTable;
