import { ChangeEvent, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getAdminRequiredEmailsRequest, setAdminToggler } from 'redux/reducers/users/reducer';
import { getRequiredEmails } from 'redux/reducers/users/selectors';
import { ERequiredEmails } from 'redux/reducers/users/types';

const RequiredEmailsPage = () => {
	const dispatch = useDispatch();
	const requiredEmails = useSelector(getRequiredEmails);
	const permission = useSelector(getPermissions)?.[EPermissionNames.REQUIRED_EMAILS].editable;

	useLayoutEffect(() => {
		dispatch(getAdminRequiredEmailsRequest({}));
	}, [dispatch]);

	const handleTogglerChange = (e: ChangeEvent<HTMLInputElement>) => {
		const toggler = e.target.name as ERequiredEmails;
		dispatch(setAdminToggler({ toggler }));
	};

	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Required Emails</p>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--mob-header table--required-emails">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Status</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>User</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Admin</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body" onChange={handleTogglerChange}>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Registration</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
										<p>Verified</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_registration_pending"
													checked={!!requiredEmails?.user_registration_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_registration_verified"
													checked={!!requiredEmails?.user_registration_verified}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_registration_pending"
													checked={!!requiredEmails?.admin_registration_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_registration_verified"
													checked={!!requiredEmails?.admin_registration_verified}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Login Notification</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_login_notification"
													checked={!!requiredEmails?.user_login_notification}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_login_notification"
													checked={!!requiredEmails?.admin_login_notification}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">2FA</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Connected</p>
										<p>Disconnected</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_google2fa_connected"
													checked={!!requiredEmails?.user_google2fa_connected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_google2fa_disconnected"
													checked={!!requiredEmails?.user_google2fa_disconnected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_google2fa_connected"
													checked={!!requiredEmails?.admin_google2fa_connected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_google2fa_disconnected"
													checked={!!requiredEmails?.admin_google2fa_disconnected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Reset</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Password Reset</p>
										<p>2FA Reset</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_reset_google2fa"
													checked={!!requiredEmails?.user_reset_google2fa}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_reset_password"
													checked={!!requiredEmails?.user_reset_password}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_reset_google2fa"
													checked={!!requiredEmails?.admin_reset_google2fa}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_reset_password"
													checked={!!requiredEmails?.admin_reset_password}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
										<p>Completed</p>
										<p>Rejected</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_withdraw_pending"
													checked={!!requiredEmails?.user_withdraw_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_withdraw_completed"
													checked={!!requiredEmails?.user_withdraw_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_withdraw_rejected"
													checked={!!requiredEmails?.user_withdraw_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_withdraw_pending"
													checked={!!requiredEmails?.admin_withdraw_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_withdraw_completed"
													checked={!!requiredEmails?.admin_withdraw_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_withdraw_rejected"
													checked={!!requiredEmails?.admin_withdraw_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
										<p>Completed</p>
										<p>Rejected</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_deposit_pending"
													checked={!!requiredEmails?.user_deposit_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_deposit_completed"
													checked={!!requiredEmails?.user_deposit_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_deposit_rejected"
													checked={!!requiredEmails?.user_deposit_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_deposit_pending"
													checked={!!requiredEmails?.admin_deposit_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_deposit_completed"
													checked={!!requiredEmails?.admin_deposit_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_deposit_rejected"
													checked={!!requiredEmails?.admin_deposit_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Trade Confirmation</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
								</div>
								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="user_trade_confirmation"
													checked={!!requiredEmails?.user_trade_confirmation}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													disabled={!permission}
													type="checkbox"
													className="hidden"
													name="admin_trade_confirmation"
													checked={!!requiredEmails?.admin_trade_confirmation}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RequiredEmailsPage;
