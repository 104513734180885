import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import Select from '../index';
import { INetworkSelect, INetworkSelectCustomList, INetworkSelectItem } from './types';

const NetworkSelectCustomInput: FC<INetworkSelectItem> = ({ code, name }) => {
	return (
		<>
			{code && name ? (
				<span className="coin coin--type3" style={{ display: name && code ? '' : 'none' }}>
					<span className="coin__text-wrap">
						<span className="coin__text upper__text">
							{code}
							<span className="coin__text-more">{name}</span>
						</span>
					</span>
				</span>
			) : (
				<span>Select Network</span>
			)}
		</>
	);
};

const NetworkSelectCustomList: FC<INetworkSelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr &&
			arr.map((el) => {
				if (el.code !== 'Rinkeby') {
					return (
						<li key={el.code}>
							<button type="button" onClick={() => onChange(el)}>
								<span className="coin coin--type3">
									<span className="coin__text-wrap">
										<span className="coin__text upper__text coin__text---pt-0">
											{el.code} <br />
											<span className="coin__text-more coin__text-more--type2">{el.name}</span>
										</span>
									</span>
								</span>
							</button>
						</li>
					);
				}
				return null;
			})}
	</>
);

const NetworkSelect: FC<INetworkSelect> = ({
	activeValue,
	onChange,
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	...props
}) => {
	const initActiveNetwork = useMemo(
		() => ({
			code: '',
			name: '',
		}),
		[],
	);
	const [activeNetwork, setActiveNetwork] = useState<any>(initActiveNetwork);
	const [searchCurrency, setSearchCurrency] = useState<any[]>([]);

	useLayoutEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useLayoutEffect(() => {
		setActiveNetwork(activeValue || initActiveNetwork);
	}, [initActiveNetwork, activeValue]);

	useLayoutEffect(() => {
		if (resetCustomSelect) {
			setActiveNetwork(initActiveNetwork);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveNetwork, resetCustomSelect, setResetCustomSelect]);

	const onChangeHandler = (el: INetworkSelectItem) => {
		setActiveNetwork(el);
		!!onChange && onChange(el);
	};

	const customList = {
		list: <NetworkSelectCustomList arr={searchCurrency} onChange={onChangeHandler} />,
		activeElement: activeNetwork.code,
	};

	return (
		<Select
			{...props}
			customInput={<NetworkSelectCustomInput {...activeNetwork} />}
			customList={customList}
			arr={arr}
		/>
	);
};
export default NetworkSelect;
