import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { responseErrors } from 'services/http/responseErrors';
import { popUpOpen } from '../popUp/reducer';
import {
	twoCodeRequest,
	twoFaCodeByTokenRequest,
	twoFaEnableByTokenRequest,
	twoFoInitState,
	twoRequest,
	twoSuccess,
} from './reducer';
import {
	ITwoFaRequest,
	ITwoFaResponse,
	ICodeTwoFaByTokenRequest,
	ITwoFaEnableByTokenRequest,
} from './types';
import { clearError, setErrorMsg } from '../errors/reducer';

// =============================================================:

// =============================================================:
function* twoRequestWorker() {
	try {
		yield put(showLoading());
		const response: ITwoFaResponse = yield call(api.twoFa.twoFa);
		yield put(twoSuccess(response));
	} catch (error) {
		notificationContainer('Error', 'error');
		yield put(twoFoInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* twoRequestCodeWorker(action: PayloadAction<ITwoFaRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.twoFa.twoFaEnable, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* generateCodeByTokenWorker({ payload }: PayloadAction<ICodeTwoFaByTokenRequest>) {
	try {
		const response: ITwoFaResponse = yield call(api.twoFa.generateCodeByToken, payload);
		yield put(twoSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'model_not_found') {
				if (window.location.href.indexOf('first-login-2fa') > -1) {
					notificationContainer(
						'You have already activated your account.',
						'user_blocked',
						'Error',
					);
				} else {
					notificationContainer(
						'You have already reset your 2FA.<br>Please wait for the request approval of contact support for more details',
						'error',
					);
				}
			} else {
				responseErrors(error);
			}
			// console.log(error?.response?.data?.errors);
		}
	}
}

function* enebleTwoFaByTokenWorker({ payload }: PayloadAction<ITwoFaEnableByTokenRequest>) {
	try {
		yield put(clearError());
		yield call(api.twoFa.twoFaEnableByToken, payload);
		yield put(popUpOpen('SuccessUpdateTwoFaPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else if (error?.response?.data?.errors[0] === 'model_not_found') {
				notificationContainer(
					'You have already reset your 2FA.<br>Please wait for the request approval of contact support for more details',
					'error',
				);
			} else {
				responseErrors(error);
			}
			// console.log(error?.response?.data?.errors);
		}
	}
}

// =============================================================:
export function* twoFaSaga() {
	yield takeEvery(twoRequest.type, twoRequestWorker);
	yield takeEvery(twoCodeRequest.type, twoRequestCodeWorker);
	yield takeEvery(twoFaCodeByTokenRequest, generateCodeByTokenWorker);
	yield takeEvery(twoFaEnableByTokenRequest, enebleTwoFaByTokenWorker);
}
