/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-duplicates
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICurrencyDataItem,
	IDepositAccount,
	ILiquidityCryptoResponse,
	ILiquidityPayload,
	IWalletBalanceStore,
} from './types';
import { IPagination } from '../users/types';
import { IGetCurrencyUserPayload } from '../currency/types';

// ==========================================:
export const initialState: IWalletBalanceStore = {
	assets: [],
	assetsLoading: false,

	liquidityReport: null,
	liquidityReportLoading: false,
	pendingFeesReport: null,
	pendingFeesReportLoading: false,
	liquidityCrypto: null,
	liquidityCryptoLoading: false,
	liquidityFiat: null,
	liquidityFiatLoading: false,
	//
	PlatformBalancesCrypto: null,
	PlatformBalancesCryptoLoading: false,
	PlatformBalancesFiat: null,
	PlatformBalancesFiatLoading: false,
	//
	DepositsWalletsCrypto: null,
	DepositsWalletsCryptoLoading: false,
	DepositsWalletsFiat: null,
	DepositsWalletsFiatLoading: false,
	//
	PendingFeesCrypto: null,
	PendingFeesCryptoLoading: false,
	PendingFeesFiat: null,
	PendingFeesFiatLoading: false,
	PendingFeesTotal: null,
	PendingFeesTotalLoading: false,
	//
	PlatformBalancesUserCrypto: null,
	PlatformBalancesUserCryptoLoading: false,
	PlatformBalancesUserFiat: null,
	PlatformBalancesUserFiatLoading: false,
	//
	PlatformDepositWallets: null,
	PlatformDepositWalletsLoading: false,
	//
	DepositAccounts: null,
	DepositAccountsLoading: false,

	UserDepositAccounts: null,
	// buttons
	buttonsLoading: false,
};
// ==========================================:
const walletBalance = createSlice({
	name: '@@walletBalance',
	initialState,
	reducers: {
		getAssetsRequest: (state) => {
			const newState = state;
			newState.assetsLoading = true;
		},
		getAssetsSuccess: (state, action: PayloadAction<ICurrencyDataItem[]>) => {
			const { payload } = action;
			state.assets = payload.sort((a, b) => {
				if (a.code > b.code) return 1;
				if (a.code < b.code) return -1;
				return 0;
			});
			state.assetsLoading = false;
		},
		getLiquidityReportRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.liquidityReportLoading = true;
		},
		getLiquidityReportSuccess: (state) => {
			const newState = state;
			newState.liquidityReportLoading = false;
		},
		getPendingFeesReportRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.liquidityReportLoading = true;
		},
		getPendingFeesReportSuccess: (state) => {
			const newState = state;
			newState.liquidityReportLoading = false;
		},
		getLiquidityRequest: (state, action: PayloadAction<ILiquidityPayload>) => {
			const newState = state;
			newState.liquidityCryptoLoading = true;
			newState.liquidityFiatLoading = true;
		},
		getLiquidityCryptoSuccess: (state, { payload }: PayloadAction<ILiquidityCryptoResponse>) => {
			const newState = state;
			newState.liquidityCrypto = payload;
			newState.liquidityCryptoLoading = false;
		},
		getLiquidityFiatSuccess: (state, { payload }: PayloadAction<ILiquidityCryptoResponse>) => {
			const newState = state;
			newState.liquidityFiat = payload;
			newState.liquidityFiatLoading = false;
		},
		//
		getPlatformBalancesRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesCryptoLoading = true;
			newState.PlatformBalancesFiatLoading = true;
		},
		getPlatformBalancesCryptoSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesCrypto = payload;
			newState.PlatformBalancesCryptoLoading = false;
		},
		getPlatformBalancesFiatSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesFiat = payload;
			newState.PlatformBalancesFiatLoading = false;
		},
		//
		getDepositsWalletsRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.DepositsWalletsCryptoLoading = true;
		},
		getDepositsWalletsCryptoSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.DepositsWalletsCrypto = payload;
			newState.DepositsWalletsCryptoLoading = false;
		},
		getDepositsWalletsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.DepositsWalletsFiat = payload;
			newState.DepositsWalletsFiatLoading = false;
		},
		//
		getPendingFeesRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.PendingFeesCryptoLoading = true;
			newState.PendingFeesFiatLoading = true;
		},
		getPendingFeesCryptoSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PendingFeesCrypto = payload;
			newState.PendingFeesCryptoLoading = false;
		},
		getPendingFeesFiatSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PendingFeesFiat = payload;
			newState.PendingFeesFiatLoading = false;
		},
		getPendingFeesTotalSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PendingFeesTotal = payload;
			newState.PendingFeesTotalLoading = false;
		},
		// User
		getPlatformBalancesUserRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesUserCryptoLoading = true;
			newState.PlatformBalancesUserFiatLoading = true;
		},
		getPlatformBalancesUserCryptoSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesUserCrypto = payload;
			newState.PlatformBalancesUserCryptoLoading = false;
		},
		getPlatformBalancesUserFiatSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformBalancesUserFiat = payload;
			newState.PlatformBalancesUserFiatLoading = false;
		},
		getDepositWalletsUserRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformDepositWalletsLoading = true;
		},
		getDepositWalletsUserSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.PlatformDepositWallets = payload;
			newState.PlatformDepositWalletsLoading = false;
		},
		//
		getDepositAccountRequest: (state, action) => {
			const newState = state;
			newState.DepositAccountsLoading = true;
		},

		getDepositAccountSuccess: (
			state,
			{ payload }: PayloadAction<IPagination<IDepositAccount> | null>,
		) => {
			const newState = state;
			newState.DepositAccountsLoading = false;
			newState.DepositAccounts = payload;
		},

		getUserDepositAccountRequest: (state, action) => {},
		getUserDepositAccountSuccess: (
			state,
			{ payload }: PayloadAction<IPagination<IDepositAccount>>,
		) => {
			const newState = state;
			newState.UserDepositAccounts = payload;
		},
		postButtonsRequest: (state, action: PayloadAction<any>) => {
			const newState = state;
			newState.buttonsLoading = true;
		},
		postButtonsCryptoSuccess: (state) => {
			const newState = state;
			newState.buttonsLoading = false;
		},
		updateDepositAccountRequest: (state, action) => {},
		createDepositAccountRequest: (state, action) => {},
		deleteDepositAccountRequest: (state, action) => {},
	},
});

export default walletBalance.reducer;
export const {
	getAssetsRequest,
	getAssetsSuccess,

	getLiquidityReportRequest,
	getLiquidityReportSuccess,
	getPendingFeesReportRequest,
	getPendingFeesReportSuccess,
	getLiquidityRequest,
	getLiquidityCryptoSuccess,
	getLiquidityFiatSuccess,
	//
	getPlatformBalancesRequest,
	getPlatformBalancesCryptoSuccess,
	getPlatformBalancesFiatSuccess,
	//
	getDepositsWalletsRequest,
	getDepositsWalletsCryptoSuccess,
	getDepositsWalletsSuccess,
	//
	getPendingFeesRequest,
	getPendingFeesCryptoSuccess,
	getPendingFeesFiatSuccess,
	getPendingFeesTotalSuccess,
	getDepositAccountRequest,
	getUserDepositAccountRequest,
	getUserDepositAccountSuccess,
	// User
	getPlatformBalancesUserRequest,
	getPlatformBalancesUserCryptoSuccess,
	getPlatformBalancesUserFiatSuccess,
	getDepositWalletsUserRequest,
	getDepositWalletsUserSuccess,
	//
	//
	postButtonsRequest,
	postButtonsCryptoSuccess,
	getDepositAccountSuccess,
	updateDepositAccountRequest,
	createDepositAccountRequest,
	deleteDepositAccountRequest,
} = walletBalance.actions;
