import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import ReactPaginate from 'react-paginate';

import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import UsersTableHeader from './UsersTableHeader';
import { IUsersTableProps } from './types';
import UsersTableRow from './UsersTableRow';
import TradeHistoryRow from '../../AccountDetails/TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import Loading from '../../../layouts-elements/Loading/Loading';

const UsersTable: FC<IUsersTableProps> = ({
	users,
	pages,
	query,
	itemsPerPage,
	setItemsPerPage,
	setCurrentPage,
	setQuery,
	loading,
}) => {
	const permission = useSelector(getPermissions)?.[EPermissionNames.USER_MANAGEMENT].editable;

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management">
						<UsersTableHeader setQuery={setQuery} />
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{users?.length ? (
										users?.map((user: any) => (
											<UsersTableRow
												key={user.id}
												user={user}
												query={query}
												permission={!!permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!users && (
					<div className="pagination-block pagination-block--full">
						<PerPageDropdown
							options={[10, 20, 50, 100]}
							setPerPage={setItemsPerPage}
							perPage={itemsPerPage}
						/>
						{/* {pages > 1 && (
						)} */}
						<ReactPaginate
							breakLabel="..."
							pageCount={pages}
							onPageChange={handlePageClick}
							className="pagination"
							activeClassName="active"
							previousClassName="pagination__arrow pagination__arrow--prev"
							nextClassName="pagination__arrow pagination__arrow--next"
							previousLabel=""
							nextLabel=""
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default UsersTable;
