import React, { useLayoutEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { IResetPasswordValue } from 'redux/reducers/auth/types';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';
import { ReactComponent as KeyIcon } from 'assets/img/admin/login/key.svg';
import { Helmet } from 'react-helmet';
import loginBannerImage from 'assets/dist/img/login-banner@2x-min.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import { notificationContainer } from '../../../services/utils/notificationContainer';

const CreateNewPasswordPage = () => {
	const dispatch = useDispatch();
	const [isShowPass, setIsShowPass] = useState(false);
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const location = useLocation();

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const onSubmit = (obg: IResetPasswordValue) => {
		if (location.search) {
			if (!reCaptchaKey) {
				notificationContainer(
					'Please complete the captcha field.',
					'user_blocked',
					'Captcha Error',
				);
			} else {
				dispatch(createNewPasswordRequest({ ...obg, token: location.search.slice(1) }));
			}
		}
	};

	useLayoutEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []);

	const initialValues = {
		password: '',
		c_password: '',
	};

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required('Please, enter your password')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(
					'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
				),
			)
			.max(25, String('Password is too long. Maximum 25 characters.')),
		c_password: yup
			.string()
			.required('Passwords must match')
			.max(25, String('Password is too long. Maximum 25 characters.'))
			.oneOf([yup.ref('password'), null], String('Passwords must match!')),
	});

	return (
		<>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
			</Helmet>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="login-form__title mb-30">
							<p>Create New Password</p>
							<p className="login-form__subtitle">Please enter a strong and unique password.</p>
						</div>
						<div className="login-form">
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={(values, { resetForm, setSubmitting }) => {
									onSubmit(values);
									setSubmitting(false);
									// resetForm();
								}}
								validateOnBlur
								enableReinitialize
							>
								{({ isSubmitting }) => (
									<Form className="form">
										<div className="login-form-item">
											<Field
												required
												name="password"
												type={isShowPass ? 'text' : 'password'}
												ariaLabel="show-password"
												placeholder="Enter your password"
												component={Input}
												title="Password"
												isShowPass={isShowPass}
												setIsShowPass={setIsShowPass}
											/>
										</div>
										<div className="login-form-item">
											<Field
												required
												name="c_password"
												type={isShowPass ? 'text' : 'password'}
												ariaLabel="show-password"
												placeholder="Enter your password"
												component={Input}
												title="Confirm password"
												isShowPass={isShowPass}
												setIsShowPass={setIsShowPass}
											/>
										</div>
										<div className="login-recaptcha">
											<ReCAPTCHA
												ref={reCaptchaRef}
												onChange={onReCaptchaChange}
												sitekey={siteKey || ''}
												hl="en"
											/>
										</div>
										<div className="login-form-item mb-0">
											<button
												type="submit"
												aria-label="form-submit"
												className="btn btn-primary btn--full"
												disabled={isSubmitting}
											>
												Confirm
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>

					<div className="login-banner" id="login_banner">
						<img src={loginBannerImage} alt="" />
						<p className="login-banner__text">
							Multi-currency accounts, foreign exchange and
							<br /> payments that streamline your global operations
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default CreateNewPasswordPage;
