import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import FeManagementTabs from './FeManagementTabs/FeManagementTabs';
import WithdrawFeeTab from './WithdrawFee/WithdrawFeeTab';
import DepositFeeTab from './DepositFee/DepositFeeTab';
import TradeFee from './TradeFee/TradeFee';
import SpreadManagement from './SpreadManagement/SpreadManagement';
import CurrenclesFee from './Currencles/CurrenclesFee';

const FeManagement = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Default Fees</p>
				</div>
			</div>
			<>
				<FeManagementTabs />
				<Switch>
					<Route exact path={`${path}/withdraw-fee`} component={WithdrawFeeTab} />
					<Route exact path={`${path}/deposit-fee`} component={DepositFeeTab} />
					<Route exact path={`${path}/trade-fee`} component={TradeFee} />
					<Route exact path={`${path}/spread-management-fee`} component={SpreadManagement} />
					<Route exact path={`${path}/currencles-fee`} component={CurrenclesFee} />
					<Redirect to={`${path}/withdraw-fee`} />
				</Switch>
			</>
		</>
	);
};

export default FeManagement;
