import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import { ROUTES } from 'routes/constants';
import {
	authInitState,
	loginRequest,
	loginSuccess,
	logoutRequest,
	refreshTokenRequest,
	refreshTokenSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	generateSecretTokenForFileDownloadRequest,
	generateSecretTokenForFileDownloadSuccess,
	getCurrentAdminPermissionsRequest,
	getCurrentAdminPermissionsSuccess,
	resetTwoFaRequest,
	loginFirst,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IForgotPasswordPayload,
	IResetTwoFaRequestPayload,
	ILoginFirstRequest,
} from './types';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { clearError, setErrorMsg } from '../errors/reducer';
// =============================================================:
// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield put(clearError());
		const response: ILoginResponse = yield call(api.auth.login, payload.data);
		if (response?.user_data?.status?.name === 'pending') {
			notificationContainer(
				'Your account has not been verified. You will receive an email once completed.',
				'user_blocked',
				'Account Pending Verification',
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
		} else if (response?.user_data?.status === 'blocked') {
			notificationContainer(
				'Your account has been blocked. If you believe this is an error please contact support.',
				'user_blocked',
				'Account Blocked',
			);
		} else {
			notificationContainer(notificationsMessagesInfo.loginSuccess, 'success', 'Welcome back!');
			yield put(loginSuccess(response));
			// yield put(getCurrentAdminPermissionsRequest(response.user_data.id));
			payload.history.push('/');
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'the_totp_field_is_required') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else {
				responseErrors(error);
			}
			// console.log(error?.response?.data?.errors);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(clearError());
		yield put(showLoading());
		yield call(api.auth.forgotPassword, payload);
		yield put(forgotPasswordSuccess());
		// notificationContainer('Request has been sent', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else if (error?.response?.data?.errors[0] === 'your_request_is_pending') {
				notificationContainer(
					'Password reset was already requested and is under review.<br>Please wait for the resolution or contact support for more details',
					'error',
				);
			} else {
				responseErrors(error);
			}
			console.log(error?.response?.data?.errors);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* logoutRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		notificationContainer(notificationsMessagesInfo.logout, 'success', 'You have been logged out');

		payload.history.push('/');
		yield put(authInitState());
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* refreshTokenWorker() {
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.refreshToken);
		yield put(refreshTokenSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error');
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* generateSecretTokenForFileDownloadWorker() {
	try {
		const response: { secret: string } = yield call(
			api.auth.postGenerateSecretTokenForFileDownload,
		);
		yield put(generateSecretTokenForFileDownloadSuccess(response.secret));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getAdminPermissionsWorker(action: PayloadAction<number>) {
	const { payload } = action;
	try {
		const response: { secret: string } = yield call(api.auth.getCurrentAdminPermissions, payload);
		yield put(getCurrentAdminPermissionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* resetTwoFaRequestWorker({ payload }: PayloadAction<IResetTwoFaRequestPayload>) {
	try {
		yield call(api.auth.resetTwoFa, payload);
		yield put(popUpOpen('resetTwoFaSuccess'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'already_exist') {
				notificationContainer(
					'2FA reset was already requested and is under review.<br>Please wait for the resolution or contact support for more details',
					'error',
				);
			} else {
				responseErrors(error);
			}
			// console.log(error?.response?.data?.errors);
		}
	}
}

function* loginFirstWorker({ payload }: PayloadAction<ILoginFirstRequest>) {
	try {
		yield put(clearError());
		yield call(api.auth.loginFirst, payload);
		yield put(popUpOpen('TwoFaSetupSuccess'));
		// window.location.replace(ROUTES.login);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
			// console.log(error?.response?.data?.errors);
			if (error?.response?.data?.errors[0] === 'model_not_found') {
				notificationContainer('You have already activated your account.', 'error');
			} else {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			}
		}
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(refreshTokenRequest.type, refreshTokenWorker);
	yield takeEvery(
		generateSecretTokenForFileDownloadRequest.type,
		generateSecretTokenForFileDownloadWorker,
	);
	yield takeEvery(getCurrentAdminPermissionsRequest.type, getAdminPermissionsWorker);
	yield takeEvery(resetTwoFaRequest, resetTwoFaRequestWorker);
	yield takeEvery(loginFirst, loginFirstWorker);
}
