import { FC } from 'react';

interface IManageUsersHeader {
	onCheck: (target: boolean) => void;
	checked: boolean;
	disabled: boolean;
}

const ManageUsersHeader: FC<IManageUsersHeader> = ({ onCheck, checked, disabled }) => {
	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<input
						type="checkbox"
						checked={checked}
						onChange={(event) => onCheck(event.target.checked)}
						disabled={disabled}
					/>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Full Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Email</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Company Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Country</p>
				</div>
			</div>
		</div>
	);
};

export default ManageUsersHeader;
