import { TApiTransactionHistoryStatus } from 'services/api/transactions/types';

export const statusClassNames: Record<TApiTransactionHistoryStatus, string> = {
	completed: 'green',
	failed: 'red',
	rejected: 'red',
	pending: 'yellow',
	pending_signing: 'yellow',
	admin_pending: 'yellow',
	cancelled: 'red',
	broadcasting: 'blue',
};

export const statusTitle: Record<TApiTransactionHistoryStatus, string> = {
	completed: 'Completed',
	failed: 'Canceled',
	rejected: 'Rejected',
	pending: 'Pending',
	admin_pending: 'Pending',
	cancelled: 'Cancelled',
	broadcasting: 'New',
	pending_signing: 'Pending Signing',
};
