/* eslint-disable no-param-reassign */
import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import InputTotp from 'ui/Formik/InputTotp';
import Select from 'ui/Formik/Select';
import { getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import { changeBankAccountsUserId } from 'redux/reducers/bankAccounts/reducer';
import { postFiatDepositRequest } from 'redux/reducers/transactions/reducer';
import SelectDate from 'ui/SelectDate';
import { currencyValueRequest } from 'redux/reducers/currency/reducer';
import { IGetCurrencyUserPayload } from 'redux/reducers/currency/types';
import UserSearch from './UserSearch/UserSearch';
import { IPopUp } from '../types';
import { getDepositAccounts } from '../../../redux/reducers/walletBalance/selectors';
import { IDepositAccount } from '../../../redux/reducers/walletBalance/types';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import { ICurrencySelectItem } from '../../../ui/Formik/Select/CurrencySelect/types';
import { getDepositAccountRequest } from '../../../redux/reducers/walletBalance/reducer';
import CreateDepositAccountPopUp from '../CreateDepositAccount/CreateDepositAccount';
import { clearError } from '../../../redux/reducers/errors/reducer';
import { IPostFiatDepositWorker } from '../../../redux/reducers/transactions/types';
import { EApiFiatTransactionStatus } from '../../../services/api/transactions/types';

export interface IAddFiatDepositForm {
	date: string;
	asset_code: string;
	indicated_amount: string;
	fee: string;
	status: string;
	note: string;
	twoFa: string;
}

const AddFiatDepositTransaction: FC<IPopUp> = ({ open, closeModal, buttonClick }) => {
	const dispatch = useDispatch();
	const depositAccounts = useSelector(getDepositAccounts);
	const [reset, setReset] = useState(false);
	const [userId, setUserId] = useState('');
	const handleChangeUserId = (currentUserId: string) => {
		setUserId(currentUserId);
		const params: IGetCurrencyUserPayload = {
			apiParams: {
				userId: String(currentUserId),
				value: 'fiat',
			},
		};
		dispatch(currencyValueRequest(params));
	};
	const [bankAccount, setBankAccount] = useState<IDepositAccount | null>(null);
	const handleChangeBankAccount = (currentBankAccount: IDepositAccount) => {
		setBankAccount(currentBankAccount);
	};

	const [indicatedAmount, setIndicatedAmount] = useState(0);
	const [fee, setFee] = useState(0);
	const [netAmount, setNetAmount] = useState(0);
	const [netAmountError, setNetAmountError] = useState('');

	const [createDepositOpen, setCreateDepositOpen] = useState(false);

	const [searchAccount, setSearchAccount] = useState<IDepositAccount[]>([]);

	const handleCloseCreateDeposit = () => {
		setCreateDepositOpen(false);
	};
	const handleOpenCreateDeposit = () => {
		setCreateDepositOpen(true);
	};

	const changeIndicatedAmount = (value: string) => {
		setIndicatedAmount(Number(value));
		if (Number(value) < fee) {
			setNetAmountError('Gross amount can`t be bigger than fee');
			setNetAmount(0);
		} else if (Number(value) === fee) {
			setNetAmountError('Gross amount can`t be equal fee');
			setNetAmount(0);
		} else {
			setNetAmount(Number(value) - fee);
			setNetAmountError('');
		}
	};
	const changeFee = (value: string) => {
		setFee(Number(value));
		if (Number(value) > indicatedAmount) {
			setNetAmountError('Fee can`t be bigger than gross amount');
			setNetAmount(0);
		} else if (Number(value) === indicatedAmount) {
			setNetAmountError('Fee can`t be equal gross amount');
			setNetAmount(0);
		} else {
			setNetAmount(indicatedAmount - Number(value));
			setNetAmountError('');
		}
	};
	const initialValues: IAddFiatDepositForm = {
		date: '',
		asset_code: '',
		indicated_amount: '',
		fee: '',
		status: '',
		note: '',
		twoFa: '',
	};

	const validationSchema = yup.object().shape({
		date: yup.string().required('Please, enter date!'),
		asset_code: yup.string().required('Please, choose currency!'),
		indicated_amount: yup.number().required('Please, enter indicated amount!'),
		fee: yup.number().required('Please, enter fee!'),
		status: yup.string().required('Please, enter status!'),
		note: yup.string().required('Please, enter note!'),
		twoFa: yup
			.string()
			.required('Please, enter 2FA code!')
			.max(6, 'Please enter 6 character 2FA code.'),
	});
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const statuses = [
		{
			id: 1,
			name: 'Completed',
			value: EApiFiatTransactionStatus.COMPLETED,
		},
		{
			id: 2,
			name: 'Rejected',
			value: EApiFiatTransactionStatus.REJECTED,
		},
		{
			id: 3,
			name: 'Pending',
			value: EApiFiatTransactionStatus.PENDING,
		},
		{
			id: 4,
			name: 'Cancelled',
			value: EApiFiatTransactionStatus.CANCELED,
		},
	];
	const statusFilterOptions = statuses.map(({ name }) => name);

	useLayoutEffect(() => {
		if (userId) {
			dispatch(changeBankAccountsUserId(Number(userId)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	const handleGetAssetIdByCode = (currentCode: string) => {
		return fiatCurrencyData.filter(({ code }) => code === currentCode.toLowerCase())[0].id;
	};

	const [selectedCurrency, setSelectedCurrency] = useState<ICurrencySelectItem | null>(null);

	const handleCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedCurrency(value);
		dispatch(getDepositAccountRequest({ per_page: 100, asset_id: value?.id || 1 }));
		setBankAccount(null);
	};

	const handleCloseModal = () => {
		setReset(true);
		setFee(0);
		setNetAmount(0);
		setIndicatedAmount(0);
		setBankAccount(null);
		closeModal();
	};

	const handleCloseModalClick = () => {
		closeModal();
		setNetAmountError('');
	};

	return (
		<>
			<Popup open={open} nested onClose={handleCloseModalClick}>
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleCloseModalClick} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Add New Transaction</p>
					</div>
					<Formik
						validateOnBlur
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: IAddFiatDepositForm, { resetForm, setSubmitting }) => {
							// handleAddTransaction(values);
							// setSubmitting(false);
							// resetForm();
							// handleCloseModal();
							if (userId && bankAccount) {
								const params: IPostFiatDepositWorker = {
									apiParams: {
										user_id: userId,
										date: values.date,
										asset_id: handleGetAssetIdByCode(values.asset_code).toString(),
										indicated_amount: values.indicated_amount,
										fee: values.fee,
										net_fee: Number(values.fee),
										amount: netAmount.toString(),
										status: values.status.toLowerCase(),
										note: values.note,
										totp: values.twoFa,
										bank_account_id: bankAccount?.id.toString() || '',
										admin_requisite_id: bankAccount?.id.toString() || '',
									},
									onFinally: (hasError: boolean) => {
										setSubmitting(false);
										dispatch(clearError());
										if (!hasError) {
											resetForm();
											handleCloseModal();
										}
									},
								};
								dispatch(postFiatDepositRequest(params));
							}
						}}
					>
						{({ isSubmitting, isValid, dirty }) => (
							<Form>
								<div className="popup-body popup-body--transaction">
									<div className="phone-number-input-wrap">
										<UserSearch
											handleChangeUserId={handleChangeUserId}
											resetForm={reset}
											handleResetForm={() => setReset(false)}
										/>
										<div className="input-line">
											<Field name="date" component={SelectDate} />

											<Field
												type="text"
												searchField
												name="asset_code"
												title="Currency"
												component={CurrencySelect}
												arr={fiatCurrencyData}
												onChange={handleCurrencyChange}
												className="select--add-fiat-transaction thin--font"
											/>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeIndicatedAmount}
													name="indicated_amount"
													title="Gross amount"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeFee}
													name="fee"
													title="Fee"
												/>
											</div>
											<div className="number__input">
												<div
													className={cn('input input-item--auto-calc', {
														'input--error': netAmountError,
													})}
												>
													<label>
														<p className="input__name">Net Amount</p>
														<div className="input-wrapper">
															<input
																className="input-item"
																placeholder="0.00"
																readOnly
																value={netAmount || ''}
																type="number"
															/>
															<span className="input-icon" />
														</div>
													</label>
													{!!netAmountError && (
														<div className="input-notify">
															<span className="input-notify__text">* {netAmountError}</span>
														</div>
													)}
												</div>
											</div>
											<Field
												required
												type="text"
												name="status"
												title="Status"
												component={Select}
												arr={statusFilterOptions}
												placeholder="Status"
											/>
										</div>
										<Field
											type="text"
											name="bank_account"
											title="Deposit Account"
											placeholder="Deposit Account"
											className={cn('select--bank-accounts select--add-transaction', {
												disabled: !selectedCurrency,
											})}
											disabled={!selectedCurrency}
											component={Select}
											customList={{
												activeElement: bankAccount?.bank_name || '',
												list: (
													<>
														{!!searchAccount?.length &&
															searchAccount.map((el: IDepositAccount) => (
																<li key={el.id}>
																	<button
																		type="button"
																		className="bank-account__item"
																		onClick={() => handleChangeBankAccount(el)}
																	>
																		<p className="bank-account__name">{el.bank_name}</p>
																	</button>
																</li>
															))}
													</>
												),
											}}
											buttonName="Add"
											buttonClick={handleOpenCreateDeposit}
											searchField
											setSearchAccount={setSearchAccount}
											arr={depositAccounts?.data}
										/>

										<Field
											required
											type="textarea"
											placeholder="Text"
											component={Input}
											name="note"
											title="Note"
										/>
										<Field
											required
											type="text"
											placeholder="Enter 2fa code"
											component={InputTotp}
											name="twoFa"
											title="2FA Code"
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--column">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={!(isValid && dirty) || isSubmitting || !userId}
										>
											Confirm
										</button>
										<button
											type="button"
											onClick={handleCloseModal}
											className="btn btn-danger btn--full"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<CreateDepositAccountPopUp
					open={createDepositOpen}
					closeModal={handleCloseCreateDeposit}
					currencySelected={selectedCurrency}
				/>
			</Popup>
		</>
	);
};

export default AddFiatDepositTransaction;
