/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLiquidityRequest,
	getPlatformBalancesUserRequest,
} from 'redux/reducers/walletBalance/reducer';
import { ILiquidityPayload } from 'redux/reducers/walletBalance/types';
import {
	getWalletBalancePlatformBalancesUserCrypto,
	getWalletBalancePlatformBalancesUserFiat,
	getWalletBalancePlatformBalancesUserFiatLoading,
} from 'redux/reducers/walletBalance/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import Item from './Item/Item';
import PerPageDropdown from '../../../../../../services/utils/PaginationPerPageDropdown';
import Loading from '../../../../../../layouts-elements/Loading/Loading';

export interface ILocalFilter {
	value: string;
	state: number;
}

const Fiat = () => {
	const dispatch = useDispatch();
	const item = useSelector(getWalletBalancePlatformBalancesUserFiat);
	const loading = useSelector(getWalletBalancePlatformBalancesUserFiatLoading);
	const perPage = 10;
	const lastPage = item?.balances?.total || 1;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);

	useLayoutEffect(() => {
		const params: any = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getPlatformBalancesUserRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsPerPage]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const params: ILiquidityPayload = {
			apiParams: {
				per_page: itemsPerPage,
				current_page: selected + 1,
				currency: 'fiat',
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(getPlatformBalancesUserRequest(params));
	};
	const filterActiveUp = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};

	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});
	const changeFilter = (changeFilterValue: string) => {
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};

	const setItemsPerPageHandler = (num: number | string) => {
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<div className="table-block mt-20">
				<div className="table-wrapper">
					<div className="table table-walletBalance--deposit-wallet-totals-fiat">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('asset_code');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Currency
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('asset_code')}`} />
												<div className={`arrow-top ${filterActiveUp('asset_code')}`} />
											</span>
										</button>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<button
											onClick={() => {
												changeFilter('total_balance');
											}}
											type="button"
											className="td-sorting-arrow-button"
										>
											Total
											<span className="td-sorting">
												<div className={`arrow-bottom ${filterActiveDown('total_balance')}`} />
												<div className={`arrow-top ${filterActiveUp('total_balance')}`} />
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									<Item itemList={item?.balances.data} localFilter={localFilter} />
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						<div className="table-footer">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={setItemsPerPageHandler}
									perPage={itemsPerPage}
								/>
								<ReactPaginate
									breakLabel="..."
									pageCount={Math.ceil(lastPage / itemsPerPage)}
									onPageChange={handlePageClick}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="pagination-block">
				{lastPage > 1 ? (
					<ReactPaginate
						breakLabel="..."
						pageCount={lastPage}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				) : null}
			</div> */}
		</>
	);
};

export default Fiat;
