import React, { FC, useLayoutEffect, useState } from 'react';
import { EWithdrawalLimitType } from 'services/api/transactions/types';
import { IGetTradeLimitPayload } from 'redux/reducers/transactions/types';
import { useDispatch, useSelector } from 'react-redux';
import { getTradesLimits, getTradesLimitsLoading } from 'redux/reducers/transactions/selectors';
import {
	getTradingLimitsCryptoRequest,
	getTradingLimitsFiatRequest,
} from 'redux/reducers/transactions/reducer';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import TradesLimitsRow from './TradesLimitsRow/TradesLimitsRow';
import Loading from '../../../../layouts-elements/Loading/Loading';
import TradesTableRow from '../../TradesTab/TradesTableRow/TradesTableRow';

export interface ITransfersLimitsTable {
	type: EWithdrawalLimitType;
}

const TradesLimitsTable: FC<ITransfersLimitsTable> = ({ type }) => {
	const dispatch = useDispatch();
	const tradingLimits = useSelector(getTradesLimits);
	const tradingLimitsLoading = useSelector(getTradesLimitsLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const pageCount = tradingLimits?.last_page || 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradingLimits?.last_page]);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || tradingLimits?.total);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		const params: IGetTradeLimitPayload = {
			current_page: currentPage,
			per_page: perPage,
		};
		type === EWithdrawalLimitType.CRYPTO && dispatch(getTradingLimitsCryptoRequest(params));
		type === EWithdrawalLimitType.FIAT && dispatch(getTradingLimitsFiatRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, currentPage, perPage]);

	const updateHandler = () => {
		const params: IGetTradeLimitPayload = {
			current_page: currentPage,
			per_page: perPage,
		};
		type === EWithdrawalLimitType.CRYPTO && dispatch(getTradingLimitsCryptoRequest(params));
		type === EWithdrawalLimitType.FIAT && dispatch(getTradingLimitsFiatRequest(params));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--type2 table--transactions-trading-limits">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Minimum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Maximum</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!tradingLimitsLoading && (
								<>
									{' '}
									{tradingLimits?.data.length ? (
										tradingLimits?.data.map((el) => (
											<TradesLimitsRow
												key={el.id}
												{...el}
												permission={!!permission}
												updateHandler={updateHandler}
												type={type}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{tradingLimitsLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!tradingLimits?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{pageCount > 1 && (
								<div className="pagination-block">
									<Pagination
										pageCount={pageCount}
										currentPage={currentPage}
										onPageChange={setCurrentPage}
									/>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TradesLimitsTable;
