import React, { FC, useState } from 'react';
import IconSvg from '../Svg/IconSvg';

export type TSortedDirection = 'asc' | 'desc' | '';
export interface ISortedButton {
	title?: string;
	direction: TSortedDirection;
	handleSort: (direction: TSortedDirection) => void;
}

const SortedButton: FC<ISortedButton> = ({ title, direction, handleSort }) => {
	const changeDirection = () => {
		switch (direction) {
			case 'asc':
				return 'desc';
			case 'desc':
				return '';
			case '':
				return 'asc';
			default:
				return 'asc';
		}
	};

	const onSortButtonClick = () => {
		const currentDirection = changeDirection();
		handleSort(currentDirection);
	};

	return (
		<button type="button" className="td-sorting-arrow-button" onClick={onSortButtonClick}>
			{title || ''}
			<span className="td-sorting-arrow">
				<span className={`td-sorting-arrow__item ${direction === 'desc' ? 'active' : ''}`}>
					<IconSvg name="sort-arrow-up" w="6" h="3" />
				</span>
				<span className={`td-sorting-arrow__item ${direction === 'asc' ? 'active' : ''}`}>
					<IconSvg name="sort-arrow-down" w="6" h="3" />
				</span>
			</span>
		</button>
	);
};

export default SortedButton;
