import React, { FC, useState } from 'react';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
}

const Item: FC<Props> = ({ item }) => {
	const { code, balances } = item;
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p>{code.toUpperCase()}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Platform Balance</p>
					<div className="td__wrap">
						{roundingNumber(balances.valueCrypto, code)} {code.toUpperCase()}
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
