// eslint-disable import/namespace
import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { getBankAccountsWithSelectFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getDepositAccounts, getUserDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import {
	getDepositAccountRequest,
	getUserDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import BankAccountsWithSelectHeader from './BankAccountsWithSelectHeader';
import BankAccountsWithSelectRow from './BankAccountsWithSelectRow';

const FiltersSearchArray = [
	{ name: 'Beneficiary Name', value: ETransactionsFilters.BENEFICIARY_NAME },
	{ name: 'Beneficiary Address', value: ETransactionsFilters.BENEFICIARY_ADDRESS },
	{ name: 'Bank Name', value: ETransactionsFilters.BANK_NAME },
	{ name: 'Bank Address', value: ETransactionsFilters.BANK_ADDRESS },
	{ name: 'SWIFT/BIC', value: ETransactionsFilters.SWIFT_BIC },
	{ name: 'Account Number', value: ETransactionsFilters.ACCOUNT_NUMBER },
];

export interface IBankAccountsWithSelectProps {
	userId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}

const BankAccountsWithSelect: FC<IBankAccountsWithSelectProps> = ({
	userId,
	resetStates,
	withoutAction,
	editMode,
}) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(userId ? getUserDepositAccounts : getDepositAccounts);
	const activeFilters = useSelector(getBankAccountsWithSelectFilters);

	const [currentPage, setCurrentPage] = useState(bankAccounts?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || bankAccounts?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = bankAccounts?.last_page ? bankAccounts.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccounts?.last_page]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, resetStates]);

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		!userId && dispatch(getDepositAccountRequest(params));
		!!userId && dispatch(getUserDepositAccountRequest({ userId, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, userId]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(
			deleteFilter({ type: filterType, filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT }),
		);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		}
	};

	return (
		<>
			<div className="filters__search-block filters__search-block--bank-accounts-with-select">
				<SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
					isResetable
					handleReset={handleResetFilters}
				/>
			</div>
			{/* <ActiveFilters
				classNames="active_filters--bank-accounts-with-select"
				activeFilters={activeFilters}
				handleDeleteFilter={handleDeleteFilter}
				handleResetFilters={handleResetFilters}
			/> */}
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--bank-accounts-with-select">
						<div className="table-header">
							<BankAccountsWithSelectHeader />
						</div>
						<div className="table-body">
							{bankAccounts?.data.length ? (
								bankAccounts?.data.map((item: any) => (
									<BankAccountsWithSelectRow
										withoutAction={withoutAction}
										editMode={editMode}
										key={item.id}
										{...item}
									/>
								))
							) : (
								<TableBodyNoData />
							)}
						</div>
					</div>
				</div>
			</div>

			{!!bankAccounts?.data.length && (
				<div className="table-footer table-footer--bank-accounts-with-select">
					<div className="pagination-block pagination-block--full">
						<PerPageSelect onChange={handleSetPerPage} />
						{pageCount > 1 && (
							<ReactPaginate
								breakLabel="..."
								pageCount={pageCount}
								onPageChange={handlePageClick}
								forcePage={currentPage - 1}
								className="pagination"
								activeClassName="active"
								previousClassName="arrow arrow--prev"
								nextClassName="arrow arrow--next"
								previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
								nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default BankAccountsWithSelect;
