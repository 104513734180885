import { FC, useState } from 'react';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import { ECryptoSortedFields } from 'services/api/transactions/types';
import { IHistoryHeaderProps, THistoryFiltersTypeOption } from '../types';
import { statusOptions, typeOptions } from '../utils';

const CryptoHistoryHeader: FC<IHistoryHeaderProps> = ({ selectedOptions, onChange, setSorted }) => {
	const handleTypeChange = (typeSelected: THistoryFiltersTypeOption) => {
		onChange({ ...selectedOptions, type: typeSelected });
	};

	const [sortedGross, setSortedGross] = useState<TSortedDirection>('');
	const [sortedFee, setSortedFee] = useState<TSortedDirection>('');
	const [sortedNet, setSortedNet] = useState<TSortedDirection>('');

	const onSortedGross = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.GROSS, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedGross(direction);
		setSortedFee('');
		setSortedNet('');
	};

	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedFee(direction);
		setSortedNet('');
		setSortedGross('');
	};

	const onSortedNet = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.NET, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedNet(direction);
		setSortedFee('');
		setSortedGross('');
	};

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<FilterSelect
						title="Type"
						options={typeOptions}
						selected={selectedOptions.type}
						onChange={handleTypeChange}
					/>
				</div>
			</div>

			<div className="td">
				<div className="td-name td--width_90">
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<SortedButton direction={sortedGross} title="Gross" handleSort={onSortedGross} />
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Destination</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx Hash</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>TxID</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name">
					<p>Status</p>
				</div>
			</div>
		</div>
	);
};

export default CryptoHistoryHeader;
