import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { IBankAccountsStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): IBankAccountsStore => state.bankAccounts;
export const getBankAccountsStoreData = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts,
);

export const getBankAccounts = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts.data.data,
);
export const getBankAccountsLoading = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts.loading,
);

export const getBankAccountsPageInfo = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => ({
		per_page: bankAccounts.data.per_page,
		current_page: bankAccounts.data.current_page,
	}),
);

export const getBankAccountsUserId = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts.userId,
);

export const getBankAccountsPagesCount = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts.data.total,
);

export const getActiveBankAccounts = createSelector(
	[getAuthState],
	(bankAccounts: IBankAccountsStore) => bankAccounts.activeBankAccounts,
);
