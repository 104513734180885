import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { numberInputFix } from '../../../../services/utils/numberInputFix';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */

const SpreadManagementItem: FC<any> = ({ data, updateHandler, permission }) => {
	const id = data?.id;
	const code = data?.code;
	const spread = data?.spread;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		spread: spread || '',
	};

	const splitText = (text: string) => {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	};

	const [oneText, twoText] = splitText(code.toUpperCase());

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_pair_id: id,
							spread: Number(values.spread),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr is-edit" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Pair</p>
								<div className="td__coin-pair">
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
										<p>{oneText}</p>
									</div>
									<div className="td__coin-pair-divider">×</div>
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
										<p>{twoText}</p>
									</div>
								</div>
							</div>

							<div className="td">
								<p className="td-hidden-name">Spread %</p>
								<input
									className="table__input"
									type="number"
									onKeyDown={(event) => numberInputFix(event)}
									name="spread"
									onChange={(event) =>
										handleFeeFixedChange(event, props.setFieldValue, 'spread', 'percent')
									}
									onWheel={(event) => event.currentTarget.blur()}
									onBlur={props.handleBlur}
									value={props.values.spread}
									placeholder="0"
									max={20}
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon-danger btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon-success btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Pair</p>
						<div className="td__coin-pair">
							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
								<p>{oneText}</p>
							</div>
							<div className="td__coin-pair-divider">×</div>
							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
								<p>{twoText}</p>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Spread %</p>
						<div className="td__wrap">
							<p>{roundingNumber(spread)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={switchOnChangeableMode}
									className="btn--icon btn--edit"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SpreadManagementItem;
