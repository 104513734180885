import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ITwoFaRequest,
	ITwoFaResponse,
	ITwoFoStore,
	ICodeTwoFaByTokenRequest,
	ITwoFaEnableByTokenRequest,
} from './types';
/* eslint-disable @typescript-eslint/no-unused-vars */
// ==========================================:
export const initialState: ITwoFoStore = {
	twoFaDate: null,
	twoFaKeyLoading: false,
};

// ==========================================:
const twoFa = createSlice({
	name: '@@twoFa',
	initialState,
	reducers: {
		twoRequest: (state) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaKeyLoading = true;
		},
		twoFaCodeByTokenRequest: (state, action: PayloadAction<ICodeTwoFaByTokenRequest>) => {},
		twoSuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateTwoFa = state;
			stateTwoFa.twoFaDate = payload;
		},
		twoCodeRequest: (state, action: PayloadAction<ITwoFaRequest>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaKeyLoading = true;
		},
		twoFaEnableByTokenRequest: (state, action: PayloadAction<ITwoFaEnableByTokenRequest>) => {},
		twoFoInitState: () => initialState,
	},
});
export default twoFa.reducer;
export const {
	twoRequest,
	twoCodeRequest,
	twoSuccess,
	twoFoInitState,
	twoFaCodeByTokenRequest,
	twoFaEnableByTokenRequest,
} = twoFa.actions;
