import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { EApiMessagesType, EMessagesFilters } from 'redux/reducers/messages/types';
import ActiveMessageFilters from 'ui/ActiveFilters/ActiveMessageFilters';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
	addFilter,
	deleteFilter,
	getMessagesRequest,
	resetFilters,
} from 'redux/reducers/messages/reducer';
import { getMessagesState } from 'redux/reducers/messages/selectors';
import { useHistory } from 'react-router';

import { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import Loading from 'layouts-elements/Loading/Loading';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import MessageTableHeader from './MessagesHeader';
import MessagesTableRow from './MessagesTableRow';
import CreateMessageForm from '../CreateMessageForm';

export interface IMessageTable {
	type: EApiMessagesType;
	newMessageForm: boolean;
	onToogleForm: () => void;
}

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
];

const MessagesTable: React.FC<IMessageTable> = ({ type, newMessageForm, onToogleForm }) => {
	const dispatch = useDispatch();
	const { messagesFilters, messages, loading } = useSelector(getMessagesState);
	const history = useHistory();

	const [reset, setReset] = useState(false);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState(1);
	const pageCount = messages?.last_page || 1;
	const [messageId, setMessageId] = useState<number | null>(null);
	const [duplicate, setDuplicate] = useState(false);

	const handleResetFilters = () => {
		setReset(true);
		setResetSearchValue(!resetSearchValue);
		dispatch(resetFilters());
	};

	const handleDateChange = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: EMessagesFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
			}),
		);
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		setReset(false);
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as EMessagesFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as EMessagesFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
				}),
			);
		}
	};

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const handleEditMessage = (id: number) => {
		onToogleForm();
		setMessageId(id);
	};

	const handleDuplicateMessage = (id: number) => {
		onToogleForm();
		setMessageId(id);
		setDuplicate(true);
	};

	useEffect(() => {
		const params = convertFilterObjectToParamsObject(messagesFilters);
		dispatch(
			getMessagesRequest({
				per_page: perPage,
				current_page: currentPage,
				type,
				...params,
			}),
		);
	}, [currentPage, perPage, messagesFilters, dispatch, type]);

	const handleCloseForm = () => {
		onToogleForm();
		setMessageId(null);
		setDuplicate(false);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	return (
		<div className="user-management">
			{!newMessageForm && (
				<>
					<ActiveMessageFilters
						reset={reset}
						resetValue={resetSearchValue}
						handleResetFilters={handleResetFilters}
						handleDateChange={handleDateChange}
						searchArray={FiltersSearchArray}
						handleSearch={handleSearch}
					/>
					<div className="table-block">
						<div className="table-wrapper">
							<div className="table table--type2 table--messages">
								<div className="table-header">
									<MessageTableHeader type={type} />
								</div>
								<div className="table-body">
									{!loading && (
										<>
											{messages?.data && messages?.data.length ? (
												messages?.data.map((message) => {
													return (
														<MessagesTableRow
															key={message.id}
															item={message}
															type={type}
															onEdit={handleEditMessage}
															onDuplicate={handleDuplicateMessage}
															setPage={setCurrentPage}
														/>
													);
												})
											) : (
												<TableBodyNoData />
											)}
										</>
									)}
									{loading ? <Loading /> : null}
								</div>
							</div>
							<div className="table-footer">
								<div className="pagination-block pagination-block--full">
									<PerPageSelect onChange={handleSetPerPage} />
									<ReactPaginate
										breakLabel="..."
										pageCount={pageCount}
										onPageChange={handlePageClick}
										forcePage={currentPage - 1}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel=""
										nextLabel=""
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{newMessageForm && (
				<CreateMessageForm
					closeForm={handleCloseForm}
					messageId={messageId}
					duplicate={duplicate}
					setDuplicate={setDuplicate}
					type={type}
				/>
			)}
		</div>
	);
};

export default MessagesTable;
