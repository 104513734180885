/* eslint-disable react/no-unstable-nested-components */

import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import CustomTooltip from './CustomTooltip/CustomTooltip';
import ActiveShape from './ActiveShape/ActiveShape';
import { IBalanceChartProps } from './types';

const PIE_COLORS = ['#6A5BC2', '#fefb96', '#d8a9ff', '#e5eaff', '#4a4e82', '#cfd8d9', '#FF9A8D'];

const BalanceChart: FC<IBalanceChartProps> = ({ list, activeIndex }) => {
	const isNarrow = useMediaQuery({
		query: `(max-width: 790px)`,
	});

	const chartWidth = isNarrow ? 262 : 450;
	const chartHeight = isNarrow ? 262 : 240;
	return (
		<div className="balance-info-schedule">
			<PieChart width={chartWidth} height={chartHeight}>
				<Pie
					data={list}
					dataKey="valueEuro"
					nameKey="name"
					outerRadius={100}
					startAngle={30}
					endAngle={390}
					stroke="none"
					animationBegin={200}
					animationDuration={700}
					activeShape={(props) => <ActiveShape shapeProps={props} isLegend={!isNarrow} />}
				>
					{list?.map((item, index) => (
						<>
							{item.name && <Cell key={item.name} fill={PIE_COLORS[index % PIE_COLORS.length]} />}
						</>
					))}
				</Pie>

				<Tooltip content={<CustomTooltip />} />
			</PieChart>
		</div>
	);
};

export default BalanceChart;
