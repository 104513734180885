import {
	THistoryFiltersStatusOption,
	THistoryFiltersTypeOption,
	IHistoryFiltersSelectedOptions,
} from './types';

export const typeOptions: THistoryFiltersTypeOption[] = [
	{
		id: 1,
		name: 'All',
		value: undefined,
	},
	{
		id: 2,
		name: 'Deposit',
		value: 'deposit',
	},
	{
		id: 3,
		name: 'Withdrawal',
		value: 'withdrawal',
	},
];

export const statusOptions: THistoryFiltersStatusOption[] = [
	{
		id: 1,
		name: 'All',
		value: undefined,
	},
	{
		id: 2,
		name: 'Completed',
		value: 'completed',
	},
	{
		id: 3,
		name: 'Failed',
		value: 'failed',
	},
	{
		id: 3,
		name: 'Pending',
		value: 'pending',
	},
];

// 📌 Initial values

export const initSelectedOptions: IHistoryFiltersSelectedOptions = {
	type: typeOptions[0],
	status: statusOptions[0],
};
