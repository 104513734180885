import React, { useState } from 'react';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';

const DepositWallet = ({ activeTab, setActiveTab }: any) => {
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto />;
			case EPaymentMethodTab.FIAT:
				return <Fiat />;
			default:
				return <Crypto />;
		}
	};

	const chooseTitle = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return 'User Deposit Wallets';
			case EPaymentMethodTab.FIAT:
				return 'User Balances';
			default:
				return 'User Deposit Wallets';
		}
	};

	return (
		<>
			<div className="table-title">
				<p>{chooseTitle()}</p>
			</div>
			{chooseTab()}
		</>
	);
};

export default DepositWallet;
