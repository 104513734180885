import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLiquidityReportRequest } from 'redux/reducers/walletBalance/reducer';
import { generateSecretTokenForFileDownloadRequest } from 'redux/reducers/auth/reducer';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import { CSVFileIcon, XLSFileIcon } from '../../../assets/inline-svg';
import { ILiquidityReportPayload } from '../../../redux/reducers/walletBalance/types';
import { getSecretTokenForFileDownload } from '../../../redux/reducers/auth/selectors';

const Liquidity = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const dispatch = useDispatch();
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto />;
			case EPaymentMethodTab.FIAT:
				return <Fiat />;
			default:
				return <Crypto />;
		}
	};
	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};

	// ...............................................................
	// ...............................................................
	// ...............................................................
	const key = useSelector(getSecretTokenForFileDownload);
	const [fileTypeState, setFileTypeState] = useState('');
	const handleGetFile = (fileType: string) => {
		dispatch(generateSecretTokenForFileDownloadRequest());
		setFileTypeState(fileType);
	};
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setTimeout(() => {
			const params: ILiquidityReportPayload = {
				apiParams: {
					fileName: fileTypeState,
					key,
					currency: activeTab,
				},
				// onFinally: () => setFirstLoading(false),
			};
			dispatch(getLiquidityReportRequest(params));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);
	// ...............................................................
	// ...............................................................
	// ...............................................................
	return (
		<>
			<div className="currencyButtonFile">
				<div className="currencyButton">
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
						onClick={setToCryptoTab}
					>
						Crypto
					</button>
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
						onClick={setToFiatTab}
					>
						Fiat
					</button>
				</div>
				<div className="fileButton">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="btn btn--csv" onClick={() => handleGetFile('csv')} />
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="btn btn--xlsx" onClick={() => handleGetFile('xlsx')} />
				</div>
			</div>
			{chooseTab()}
		</>
	);
};
export default Liquidity;
