import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { EApiTransactionType, ITransactionsApi } from './types';

export const transactions: ITransactionsApi = {
	// new
	getAllBankAccount: () =>
		http.get(endpoint.transactions.ALL_BANK_ACCOUNT).then(({ data }) => data),
	//
	getCryptoHistory: ({ userId, ...params }) =>
		http.get(endpoint.transactions.GET_CRYPTO_HISTORY(userId), { params }).then(({ data }) => data),
	getFiatHistory: ({ userId, ...params }) =>
		http.get(endpoint.transactions.GET_FIAT_HISTORY(userId), { params }).then(({ data }) => data),
	getTradeHistory: ({ userId, ...params }) =>
		http.get(endpoint.transactions.GET_TRADE_HISTORY(userId), { params }).then(({ data }) => data),
	getCryptoTransfers: ({ ...params }) =>
		http.get(endpoint.transactions.GET_CRYPTO_TRANSFERS, { params }).then(({ data }) => data),
	getFiatTransfers: ({ ...params }) =>
		http.get(endpoint.transactions.GET_FIAT_TRANSFERS, { params }).then(({ data }) => data),
	getCryptoDeposits: ({ ...params }) =>
		http.get(endpoint.transactions.GET_CRYPTO_DEPOSITS, { params }).then(({ data }) => data),
	getFiatDeposits: ({ ...params }) =>
		http.get(endpoint.transactions.GET_FIAT_DEPOSITS, { params }).then(({ data }) => data),
	postFiatDeposits: (payload) =>
		http.post(endpoint.transactions.POST_FIAT_DEPOSIT, payload).then(({ data }) => data),
	getCryptoTransactions: ({ ...params }) =>
		http.get(endpoint.transactions.GET_CRYPTO_TRANSACTIONS, { params }).then(({ data }) => data),
	getFiatTransactions: ({ ...params }) =>
		http.get(endpoint.transactions.GET_FIAT_TRANSACTIONS, { params }).then(({ data }) => data),
	putFiatTransactions: (params, type, id) => {
		if (type === EApiTransactionType.WITHDRAWAL) {
			return http
				.put(endpoint.transactions.PUT_FIAT_TRANSACTIONS_WITHDRAWALS(id), params)
				.then(({ data }) => data);
		}
		return http
			.put(endpoint.transactions.PUT_FIAT_TRANSACTIONS_DEPOSITS(id), params)
			.then(({ data }) => data);
	},
	putCryptoWithdrawalApprove: (id) =>
		http.put(endpoint.transactions.PUT_CRYPTO_WITHDRAWAL_APPROVE(id)).then(({ data }) => data),
	putCryptoWithdrawalReject: (id) =>
		http.put(endpoint.transactions.PUT_CRYPTO_WITHDRAWAL_REJECT(id)).then(({ data }) => data),
	putCryptoNote: (payload) =>
		http.put(endpoint.transactions.PUT_CRYPTO_NOTE, payload).then(({ data }) => data),
	getPooledDeposits: ({ ...params }) =>
		http.get(endpoint.transactions.GET_POOLED_DEPOSITS, { params }).then(({ data }) => data),
	getPooledDepositsFile: ({ fileType, token, adminId }) =>
		http
			.get(endpoint.transactions.GET_POOLED_DEPOSITS_FILE(fileType, token || '', String(adminId)))
			.then(({ data }) => data),
	getCryptoWithdrawalLimits: () =>
		http.get(endpoint.transactions.GET_WITHDRAW_LIMITS_CRYPTO).then(({ data }) => data),
	getFiatWithdrawalLimits: () =>
		http.get(endpoint.transactions.GET_WITHDRAW_LIMITS_FIAT).then(({ data }) => data),
	putWithdrawalLimits: (payload) =>
		http.put(endpoint.transactions.PUT_WITHDRAW_LIMIT, payload).then(({ data }) => data),
	getGeneralWithdrawalLimit: () =>
		http.get(endpoint.transactions.GET_WITHDRAW_LIMIT_TOTAL).then(({ data }) => data),
	putGeneralWithdrawalLimit: (payload) =>
		http.put(endpoint.transactions.PUT_WITHDRAW_LIMIT_TOTAL, payload).then(({ data }) => data),
	getTrades: ({ ...params }) =>
		http.get(endpoint.transactions.GET_TRADES, { params }).then(({ data }) => data),
	getTradesFile: ({ fileType, token, adminId }) =>
		http
			.get(endpoint.transactions.GET_TRADES_FILE(fileType, token || '', String(adminId)))
			.then(({ data }) => data),
	getTradingLimitsCrypto: (params) =>
		http.get(endpoint.transactions.GET_TRADES_LIMITS_CRYPTO, { params }).then(({ data }) => data),
	getTradingLimitsFiat: (params) =>
		http.get(endpoint.transactions.GET_TRADES_LIMITS_FIAT, { params }).then(({ data }) => data),
	putTradingLimit: (payload) =>
		http.put(endpoint.transactions.PUT_TRADES_LIMITS, payload).then(({ data }) => data),
	getCryptoTransactionFile: ({ token, type, fileType, adminId }) =>
		http
			.get(
				endpoint.transactions.GET_CRYPTO_TRANSACTION_FILE(
					String(token),
					fileType,
					type,
					String(adminId),
				),
			)
			.then(({ data }) => data),
	getFiatTransactionFile: ({ token, type, fileType, adminId }) =>
		http
			.get(
				endpoint.transactions.GET_FIAT_TRANSACTION_FILE(
					token || '',
					fileType,
					type,
					String(adminId),
				),
			)
			.then(({ data }) => data),
	getProfitWallets: ({ ...params }) =>
		http.get(endpoint.transactions.GET_PROFIT_WALLETS, { params }).then(({ data }) => data),
	getProfitWalletsFile: ({ fileType, token, adminId }) =>
		http
			.get(endpoint.transactions.GET_PROFIT_WALLETS_FILE(fileType, token || '', String(adminId)))
			.then(({ data }) => data),

	getPendingDeposit: ({ type, params }) => {
		return http
			.get(endpoint.transactions.GET_PENDING_DEPOSITS(type), { params })
			.then(({ data }) => data);
	},
	putPendingDepositReject: (id) =>
		http.put(endpoint.transactions.PUT_PENDING_DEPOSIT_REJECT(id)).then(({ data }) => data),
	putPendingDepositApprove: (id) =>
		http.put(endpoint.transactions.PUT_PENDING_DEPOSIT_APPROVE(id)).then(({ data }) => data),
	putPendingDepositNote: (payload) =>
		http
			.put(endpoint.transactions.PUT_PENDING_DEPOSIT_NOTE(payload.transaction_id), {
				note: payload.note,
			})
			.then(({ data }) => data),

	getPendingWithdrawal: ({ type, params }) =>
		http
			.get(endpoint.transactions.GET_PENDING_WITHDRAWALS(type), { params })
			.then(({ data }) => data),
	getPendingWithdrawalFile: ({ fileType, token, adminId, assetType }) =>
		http
			.get(
				endpoint.transactions.GET_PENDING_WITHDRAWALS_FILE(
					fileType,
					String(token),
					String(adminId),
					assetType,
				),
			)
			.then(({ data }) => data),
	putPendingWithdrawalStatus: (payload: any) =>
		http
			.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_STATUS(payload.id), payload.payloadData)
			.then(({ data }) => data),
	putPendingWithdrawalCryptoApprove: (id: number) =>
		http
			.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_CRYPTO_APPROVE(id))
			.then(({ data }) => data),
	putPendingWithdrawalCryptoReject: (id: number) =>
		http
			.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_CRYPTO_REJECT(id))
			.then(({ data }) => data),
	putPendingWithdrawalApprove: (id) =>
		http.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_APPROVE(id)).then(({ data }) => data),
	putPendingWithdrawalReject: (id) =>
		http.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_REJECT(id)).then(({ data }) => data),
	putPendingWithdrawalCancel: (id) =>
		http.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_CANCEL(id)).then(({ data }) => data),
	putPendingWithdrawalNote: (payload) =>
		http
			.put(endpoint.transactions.PUT_PENDING_WITHDRAWAL_NOTE(payload.transaction_id), {
				note: payload.note,
			})
			.then(({ data }) => data),

	// Manual transactions
	postAddDepositCrypto: (payload) =>
		http.post(endpoint.transactions.POST_ADD_DEPOSIT_CRYPTO, payload).then(({ data }) => data),
	postAddWithdrawalFiat: (payload) =>
		http.post(endpoint.transactions.POST_ADD_WITHDRAWAL_FIAT, payload).then(({ data }) => data),
	postAddWithdrawalCrypto: (payload) =>
		http.post(endpoint.transactions.POST_ADD_WITHDRAWAL_CRYPTO, payload).then(({ data }) => data),
	postAddTrade: (payload) =>
		http.post(endpoint.transactions.POST_ADD_TRADE, payload).then(({ data }) => data),
	getLedgerTransactions: ({ ...params }) =>
		http.get(endpoint.transactions.GET_LEDGER_TRANSACTIONS, { params }).then(({ data }) => data),
};
