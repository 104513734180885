import { ChangeEvent, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import { setUserData } from 'redux/reducers/users/reducer';

const TradeManagementTabView = () => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserCreateData);

	// useLayoutEffect(() => {
	// 	if (
	// 		!userData?.user_data?.liquidity_provider &&
	// 		userData?.user_data?.liquidity_provider !== ''
	// 	) {
	// 		dispatch(
	// 			setUserData({
	// 				...userData,
	// 				user_data: { ...userData?.user_data, liquidity_provider: 'b2c2' },
	// 			}),
	// 		);
	// 	}
	// }, [dispatch, userData]);

	const sendButton = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;

		dispatch(
			setUserData({
				...userData,
				user_data: { ...userData?.user_data, liquidity_provider: checked ? 'b2c2' : '' },
			}),
		);
	};

	// useLayoutEffect(() => {
	// 	dispatch(
	// 		setUserData({
	// 			...userData,
	// 			user_data: { ...userData?.user_data, liquidity_provider: 'b2c2' },
	// 		}),
	// 	);
	// }, [dispatch, userData]);

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--trade-management">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Liquidity Provider</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Action</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Liquidity Provider</p>
										<div className="td__wrap">
											<p>B2C2</p>
										</div>
									</div>

									<div className="td td--right">
										<p className="td-hidden-name">Action</p>
										<div className="switch">
											<label className="switch__label">
												<input
													onChange={sendButton}
													disabled
													// checked={userData?.user_data?.liquidity_provider === 'b2c2'}
													checked
													type="checkbox"
													className="hidden"
													// defaultValue={1}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeManagementTabView;
