/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// import IconSvg from 'ui/Svg/IconSvg';
import { useDispatch } from 'react-redux';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { changeAdminStatusRequest, updateAdminDataRequest } from 'redux/reducers/admins/reducer';
import { ROUTES } from 'routes/constants';
import { notificationsInfoFields } from 'services/utils/inputFields/ipuntFields';
import AdminStatusUpdater from 'layouts-elements/PopUp/AdminStatusUpdater';
import { IUsersTableRowProps } from '../types';
import { IPermissionData } from '../../../../redux/reducers/admins/types';

const AdminsTableRow: FC<IUsersTableRowProps & { permission: boolean }> = ({
	user,
	query,
	permission,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isEdit, setIsEdit] = useState(false);
	const [inputFirstNameVal, setInputFirstNameVal] = useState('');
	const [inputLastNameVal, setInputLastNameVal] = useState('');
	const [inputEmailVal, setInputEmailVal] = useState('');
	const [inputFirstNameError, setInputFirstNameError] = useState('');
	const [inputLastNameError, setInputLastNameError] = useState('');
	const [inputEmailError, setInputEmailError] = useState('');
	const [openStatusUpdater, setOpenStatusUpdater] = useState(false);

	const closeStatusUpdater = useCallback(() => {
		setOpenStatusUpdater(false);
	}, []);

	const [adminPermissions, setAdminPermissions] = useState<{ [key: string]: IPermissionData }>({});

	const firstNameRef = useRef<any>();
	const lastNameRef = useRef<any>();
	const emailRef = useRef<any>();

	const { id, email, first_name, last_name, is_active } = user;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// const lostPermissions =
	// 	permissions?.filter((item) => !user?.permissions.some((perm) => perm.id === item.id)) || [];

	const handleDeleteAdmin = () => {
		const payload = {
			...query,
		};
		dispatch(setPopUpData({ message: String(id), payload }));
		dispatch(popUpOpen('deleteAdminPopUp'));
	};
	const handleChangeStatus = () => {
		dispatch(changeAdminStatusRequest({ id, status: !is_active }));
		closeStatusUpdater();
	};
	const handleEditAdmin = () => {
		// history.push(`${ROUTES.adminManagement.EDIT_ADMIN}/${id}`, { id });
		setIsEdit(!isEdit);
		setInputFirstNameVal(first_name);
		setInputLastNameVal(last_name);
		setInputEmailVal(email);
	};

	const submitHandler = () => {
		if (firstNameRef.current.value === '') {
			setInputFirstNameError(notificationsInfoFields.adminValidationMessage.firstName.required);
			return;
		}
		if (lastNameRef.current.value === '') {
			setInputLastNameError(notificationsInfoFields.adminValidationMessage.lastName.required);
			return;
		}
		if (firstNameRef.current.value.length > 60) {
			setInputFirstNameError(notificationsInfoFields.adminValidationMessage.firstName.max);
			return;
		}
		if (lastNameRef.current.value.length > 60) {
			setInputLastNameError(notificationsInfoFields.adminValidationMessage.lastName.max);
			return;
		}
		// if (emailRef.current.value === '') {
		// 	setInputEmailError('Please enter email.');
		// }

		setInputFirstNameError('');
		setInputLastNameError('');
		const permissionsArr = Object.values(adminPermissions);
		const adminUpdateObj = {
			history,
			id,
			obj: {
				first_name: firstNameRef.current.value,
				last_name: lastNameRef.current.value,
				permissions: permissionsArr,
			},
		};
		dispatch(updateAdminDataRequest(adminUpdateObj));
		setIsEdit(false);
	};

	useLayoutEffect(() => {
		if (id) {
			const allPermissions = user?.permissions || [];
			const formattedList: {
				[key: string]: { id: number; editable: boolean; only_read: boolean };
			} = {};
			for (let i = 0; i < allPermissions.length; i += 1) {
				const item = allPermissions[i];
				if (item.pivot) {
					formattedList[item.name] = {
						id: item.id,
						editable: item.pivot.editable === 1,
						only_read: item.pivot.only_read === 1,
					};
				} else {
					formattedList[item.name] = {
						id: item.id,
						editable: false,
						only_read: false,
					};
				}
			}
			setAdminPermissions(formattedList);
		}
	}, [id, user]);

	useLayoutEffect(() => {
		if (!isEdit) {
			setInputFirstNameError('');
			setInputLastNameError('');
			setInputEmailError('');
		}
	}, [isEdit]);

	useLayoutEffect(() => {
		setInputFirstNameVal(first_name);
		setInputLastNameVal(last_name);
		setInputEmailVal(email);
	}, [email, first_name, last_name, user]);

	return (
		<div className={isEdit ? 'tr is-edit' : 'tr'}>
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<div className="td__wrap">
					<Link
						to={{ pathname: `${ROUTES.adminManagement.EDIT_ADMIN}/${String(id)}`, state: { id } }}
						className="link"
					>
						{id}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full Name</p>
				{isEdit ? (
					<div className="table__input-wrap">
						<div>
							<input
								type="text"
								className="table__input"
								placeholder="First Name"
								value={inputFirstNameVal}
								onChange={(e) => {
									setInputFirstNameVal(e.target.value);
								}}
								ref={firstNameRef}
							/>
							{inputFirstNameError && <p className="table__input-error">{inputFirstNameError}</p>}
						</div>
						<div>
							<input
								type="text"
								className="table__input"
								placeholder="Last Name"
								value={inputLastNameVal}
								onChange={(e) => {
									setInputLastNameVal(e.target.value);
								}}
								ref={lastNameRef}
							/>
							{inputLastNameError && <p className="table__input-error">{inputLastNameError}</p>}
						</div>
					</div>
				) : (
					<div className="td__wrap">
						{first_name} {last_name}
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<div className="td__wrap">
					{/* {isEdit ? (
						<div>
							<input
								type="text"
								className="table__input"
								placeholder="Email"
								value={inputEmailVal}
								onChange={(e) => {
									setInputEmailVal(e.target.value);
								}}
								ref={emailRef}
							/>
						</div>
					) : (
						<Link
							to={{ pathname: `${ROUTES.adminManagement.EDIT_ADMIN}/${String(id)}`, state: { id } }}
							className="link"
						>
							{email}
						</Link>
					)} */}
					<Link
						to={{ pathname: `${ROUTES.adminManagement.EDIT_ADMIN}/${String(id)}`, state: { id } }}
						className="link"
					>
						{email}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action </p>
				<div className="table-buttons flex-e">
					{isEdit ? (
						<>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={handleEditAdmin}
								className="btn--icon-danger btn--cancel"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn--icon-success btn--check"
								onClick={submitHandler}
							/>
						</>
					) : (
						<>
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="button" onClick={handleEditAdmin} className="btn--icon btn--edit" />
								</>
							)}
							{permission && is_active ? (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={() => setOpenStatusUpdater(true)}
									className="btn--icon btn--block"
								/>
							) : (
								''
							)}
							{permission && !is_active ? (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={() => setOpenStatusUpdater(true)}
									className="btn--icon-success btn--unblock"
								/>
							) : (
								''
							)}
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={handleDeleteAdmin}
										className="btn--icon-danger btn--delete"
									/>
								</>
							)}
						</>
					)}
				</div>
				<AdminStatusUpdater
					open={openStatusUpdater}
					closeModal={closeStatusUpdater}
					onConfirm={handleChangeStatus}
					event={is_active ? 'block' : 'unblock'}
				/>
			</div>
		</div>
	);
};

export default AdminsTableRow;
