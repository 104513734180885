import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IPropsPopUp } from './types';

const VerificationPopUp: FC<IPropsPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header popup-header--mb-20">
							<p className="popup-header__title">Documents have been sent</p>
						</div>
						<div className="popup-text">
							<p>
								Thank you! Your Document Files have been sent to the Administrator for
								consideration.
							</p>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeModal} type="button" className="btn btn-primary btn--full">
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default VerificationPopUp;
