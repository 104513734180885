import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import { getSecretTokenForFileDownload } from '../../../redux/reducers/auth/selectors';
import { ILiquidityReportPayload } from '../../../redux/reducers/walletBalance/types';
import {
	getLiquidityReportRequest,
	getPendingFeesReportRequest,
} from '../../../redux/reducers/walletBalance/reducer';
import { CSVFileIcon, XLSFileIcon } from '../../../assets/inline-svg';
import { generateSecretTokenForFileDownloadRequest } from '../../../redux/reducers/auth/reducer';

const PendingFees = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const dispatch = useDispatch();
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto />;
			case EPaymentMethodTab.FIAT:
				return <Fiat />;
			// case EPaymentMethodTab.TOTALS:
			// 	return <Totals />;
			default:
				return <Crypto />;
		}
	};
	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};
	// const setToTotalsTab = () => {
	// 	setActiveTab(EPaymentMethodTab.TOTALS);
	// };
	// ...............................................................
	// ...............................................................
	// ...............................................................

	const key = useSelector(getSecretTokenForFileDownload);
	const [fileTypeState, setFileTypeState] = useState('');
	const handleGetFile = (fileType: string) => {
		dispatch(generateSecretTokenForFileDownloadRequest());
		setFileTypeState(fileType);
	};
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setTimeout(() => {
			const params: ILiquidityReportPayload = {
				apiParams: {
					fileName: fileTypeState,
					key,
					currency: activeTab,
				},
				// onFinally: () => setFirstLoading(false),
			};
			dispatch(getPendingFeesReportRequest(params));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);
	// ...............................................................
	// ...............................................................
	// ...............................................................
	return (
		<div className="user-management-body user-management-body--type2">
			<div className="currencyButtonFile">
				<div className="currencyButton">
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
						onClick={setToCryptoTab}
					>
						Crypto
					</button>
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
						onClick={setToFiatTab}
					>
						Fiat
					</button>
					{/* <button */}
					{/*	type="button" */}
					{/*	className={`button button--size4 button--type3 ${ */}
					{/*		activeTab === EPaymentMethodTab.TOTALS ? 'active' : '' */}
					{/*	}`} */}
					{/*	onClick={setToTotalsTab} */}
					{/* > */}
					{/*	Totals */}
					{/* </button> */}
				</div>
				<div className="fileButton">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="btn btn--csv" onClick={() => handleGetFile('csv')} />
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="btn btn--xlsx" onClick={() => handleGetFile('xlsx')} />
				</div>
			</div>
			{chooseTab()}
		</div>
	);
};
export default PendingFees;
