import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDetailedViewStore } from './types';
import {
	ICreateCryptoDetailsRequest,
	ICreateFiatDetailsRequest,
	ICreateTradeDetailsRequest,
	IDeleteFiles,
} from '../../../services/api/detailedView/types';

// TODO: Add proper types
export const initialState: IDetailedViewStore = {
	loading: false,
	tradeDetails: undefined,
	fiatDetails: undefined,
	cryptoDetails: undefined,
};

const detailedView = createSlice({
	name: '@@detailedView',
	initialState,
	reducers: {
		hideLoading: (state) => {
			const newState = state;
			newState.loading = false;
		},

		getTradeDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getTradeDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		createTradeDetailsRequest: (state, { payload }: PayloadAction<ICreateTradeDetailsRequest>) => {
			const newState = state;
			newState.loading = true;
		},
		createTradeDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		deleteTradeDetailsFilesRequest: (state, { payload }: PayloadAction<IDeleteFiles>) => {
			const newState = state;
			newState.loading = true;
		},
		deleteTradeDetailsFilesSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		getFiatDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getFiatDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.fiatDetails = payload;
			newState.loading = false;
		},

		createFiatDetailsRequest: (state, { payload }: PayloadAction<ICreateFiatDetailsRequest>) => {
			const newState = state;
			newState.loading = true;
		},
		createFiatDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.fiatDetails = payload;
			newState.loading = false;
		},

		deleteFiatDetailsFilesRequest: (state, { payload }: PayloadAction<IDeleteFiles>) => {
			const newState = state;
			newState.loading = true;
		},
		deleteFiatDetailsFilesSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.fiatDetails = payload;
			newState.loading = false;
		},

		getCryptoDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getCryptoDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.cryptoDetails = payload;
			newState.loading = false;
		},

		createCryptoDetailsRequest: (
			state,
			{ payload }: PayloadAction<ICreateCryptoDetailsRequest>,
		) => {
			const newState = state;
			newState.loading = true;
		},
		createCryptoDetailsSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.cryptoDetails = payload;
			newState.loading = false;
		},

		deleteCryptoDetailsFilesRequest: (state, { payload }: PayloadAction<IDeleteFiles>) => {
			const newState = state;
			newState.loading = true;
		},
		deleteCryptoDetailsFilesSuccess: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.cryptoDetails = payload;
			newState.loading = false;
		},
	},
});

export default detailedView.reducer;

export const {
	hideLoading,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	getFiatDetailsRequest,
	getFiatDetailsSuccess,
	getCryptoDetailsRequest,
	getCryptoDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	deleteTradeDetailsFilesRequest,
	deleteTradeDetailsFilesSuccess,
	deleteCryptoDetailsFilesRequest,
	deleteCryptoDetailsFilesSuccess,
	deleteFiatDetailsFilesRequest,
	deleteFiatDetailsFilesSuccess,
} = detailedView.actions;
