import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	I2FAResetPayload,
	I2FARejectPayload,
	ICheckFireblockIdRequest,
} from 'redux/reducers/users/types';
import { IUsersApi } from './types';
/* eslint-disable @typescript-eslint/naming-convention */
// ==========================================:
export const users: IUsersApi = {
	getUsers: (payload) =>
		http.get(endpoint.users.ALL_USERS, { params: payload }).then(({ data }) => data),
	getUsersPermission: (payload) =>
		http.get(endpoint.users.ALL_USERS_PERMISSION, { params: payload }).then(({ data }) => data),
	updateUserBlockStatus: (payload) => http.put(endpoint.user.USER_BLOCK(payload.id, payload.event)),
	updateUserArchiveStatus: (payload) =>
		http.put(endpoint.user.USER_ARCHIVED(payload.userId, payload.event)),

	getOneUser: ({ userId }) =>
		http.get(endpoint.users.GET_ONE_USER(userId)).then(({ data }) => data),

	getUserWallets: ({ user_id }) =>
		http.get(endpoint.user.USER_WALLETS(user_id)).then(({ data }) => data),

	getUserReferral: ({ userId, per_page, current_page }) =>
		http
			.get(endpoint.user.USER_REFERRAL(userId), { params: { per_page, current_page } })
			.then(({ data }) => data),

	deleteUserReferral: ({ userId, referral_id }) =>
		http.delete(endpoint.user.DEL_USER_REFERRAL(userId), { data: { referral_id } }),
	// getUserLiquidityProvider: (payload) =>
	// 	http
	// 		.put(endpoint.user.USER_LIQUIDITY_PROVIDER(payload.userId), payload.liquidity_provider)
	// 		.then(({ data }) => data),
	referralSystem: (userId) => http.get(endpoint.user.REFERRAL_SYSTEM(userId)),
	getUserLiquidityProvider: ({ userId, liquidity_provider }) =>
		http
			.put(endpoint.user.USER_LIQUIDITY_PROVIDER(userId), {
				liquidity_provider,
			})
			.then((response) => response.data),

	getUserTradingLimits: ({ type, id, params }) =>
		http
			.get(
				type ? endpoint.tradingLimits.LIMITS_CRYPTO(id) : endpoint.tradingLimits.LIMITS_FIAT(id),
				{ params },
			)
			.then(({ data }) => data),
	// getUserTradingLimitsCrypto: async ({ id, params }) => {
	// 	return http.get(endpoint.tradingLimits.LIMITS_CRYPTO(id), { params }).then(({ data }) => data);
	// },

	// getUserTradingLimitsFiat: async ({ id, params }) => {
	// 	return http.get(endpoint.tradingLimits.LIMITS_FIAT(id), { params }).then(({ data }) => data);
	// },

	getUserTransferLimits: ({ user_id, type }) =>
		http.get(endpoint.transferLimits.LIMITS(user_id, type)).then(({ data }) => data),

	updateUserTradingLimit: (payload) =>
		http.put(endpoint.tradingLimits.UPDATE_LIMIT(payload.userId), payload),

	updateUserTransferLimit: (payload) =>
		http.put(endpoint.transferLimits.UPDATE_LIMIT(payload.id), payload.data),

	createUser: (payload: any) =>
		http.post(endpoint.user.CREATE_USER, payload).then(({ data }) => data),
	getResetRequests: (payload: any) =>
		http.get(endpoint.users.RESET_REQUESTS, { params: payload }).then(({ data }) => data),
	reset2FA: (payload) => http.put<I2FAResetPayload>(endpoint.users.TWO_FA_RESET, payload),
	reject2FA: (payload) => http.put<I2FARejectPayload>(endpoint.users.TWO_FA_REJECT, payload),
	getUserRequiredEmails: ({ id, type }) =>
		http
			.get(type ? endpoint.user.ADMIN_EMAILS(id) : endpoint.user.USER_EMAILS(id))
			.then(({ data }) => data),
	getUserRequiredEmailsTypeUser: ({ id }) =>
		http.get(endpoint.user.USER_EMAILS(id)).then(({ data }) => data),
	getUserRequiredEmailsTypeAdmin: ({ id }) =>
		http.get(endpoint.user.ADMIN_EMAILS(id)).then(({ data }) => data),
	postUserRequiredEmails: ({ id, settings }) =>
		http.put(endpoint.user.USER_ADMIN_EMAILS(id), settings).then(({ data }) => data),
	getAdminRequiredEmails: () =>
		http.get(endpoint.users.ADMIN_REQUIRED_EMAILS).then(({ data }) => data),
	postAdminRequiredEmails: ({ id, settings }) =>
		http.put(endpoint.users.ADMIN_REQUIRED_EMAILS, settings).then(({ data }) => data),
	updateUser: ({ id, settings }) =>
		http.put(`${endpoint.user.CREATE_USER}/${id}`, settings).then(({ data }) => data),
	getAdminResetData: (payload) =>
		http.get(endpoint.users.ADMIN_RESET_REQUESTS, { params: payload }).then(({ data }) => data),
	resetAdminPassword: (payload) => http.put(endpoint.users.ADMIN_PASSWORD_RESET, payload),
	rejectAdminPassword: (payload) => http.put(endpoint.users.ADMIN_PASSWORD_REJECT, payload),
	getUserFee: ({ id, type, current_page, per_page }) => {
		return http
			.get(endpoint.user.USER_FEE(id, type, current_page, per_page))
			.then(({ data }) => data);
	},
	putUserFee: ({ id, body }) => {
		return http.put(endpoint.user.PUT_USER_FEE(id), body).then(({ data }) => data);
	},
	getUserSpreadFee: ({ id, current_page, per_page }) => {
		return http
			.get(endpoint.user.USER_FEE_SPREAD(id, current_page, per_page))
			.then(({ data }) => data);
	},
	putUserSpreadFee: ({ id, body }) => {
		return http.put(endpoint.user.PUT_USER_FEE_SPREAD(id), body).then(({ data }) => data);
	},
	getAdminTwoFaRequestsData: (payload) =>
		http
			.get(endpoint.users.GET_ADMIN_TWO_FA_REQUESTS, { params: payload })
			.then(({ data }) => data),
	approveAdminTwoFaRequest: (payload) =>
		http.put(endpoint.users.APPROVE_ADMIN_TWO_FA_REQUEST, payload),
	rejectAdminTwoFaRequest: (payload) =>
		http.put(endpoint.users.REJECT_ADMIN_TWO_FA_REQUEST, payload),
	putUserReferral: (payload) => http.put(endpoint.user.PUT_USER_REFERRAL, payload),
	getUsersPasswordResetRequests: (payload) =>
		http
			.get(endpoint.users.USER_PASSWORD_RESET_REQUESTS, { params: payload })
			.then(({ data }) => data),
	resetUserPassword: (payload) => http.put(endpoint.users.USER_PASSWORD_RESET, payload),
	rejectUserPassword: (payload) => http.put(endpoint.users.USER_PASSWORD_REJECT, payload),
	getUsersFile: ({ token, fileType }) =>
		http.get(endpoint.users.GET_USERS_FILE(token || '', fileType)).then(({ data }) => data),
	setUserStatus: ({ id, status }) =>
		http.put(endpoint.users.SET_USER_STATUS(id), { status }).then(({ data }) => data),
	setUserFireblockId: (payload) =>
		http
			.put(endpoint.users.USER_FIREBLOCK_ID(payload.userId), { ...payload.data })
			.then(({ data }) => data),
	postAllTransferToPool: (payload) => {
		const { totp } = payload;
		return http
			.post(endpoint.user.ALL_TRANSFER_TO_POOL(payload.userId), { totp })
			.then(({ data }) => data);
	},
	generateCodeForZip: (userId: number) =>
		http.post(endpoint.user.GENERATE_ZIP_SECRET(userId)).then(({ data }) => data),
	getCheckValidFireblockId: (params: ICheckFireblockIdRequest) =>
		http.get(endpoint.users.CHECK_VALID_FIREBLOCKS(params)).then(({ data }) => data),
};
