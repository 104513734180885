/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReferralItem, IReferralsStore } from 'redux/reducers/referrals/types';
import {
	IAddReferralUserRequest,
	IReferralsResponse,
	IReferralsUserRequest,
	ICommissionResponse,
	IUpdateCommissionRequest,
	TReferralUsersList,
	IGetReferralFilePayload,
} from 'services/api/referrals/types';
import { IApiPaginationPayload } from 'services/api/types';

export const initialState: IReferralsStore = {
	commission: 0,
	referrals: null,
	freeReferrals: null,
	referralsOneUser: null,
	loadingCommission: false,
	loadingReferrals: false,
	loadingFreeReferrals: false,
	loadingReferralsOneUser: false,
};

const referrals = createSlice({
	name: '@@referrals',
	initialState,
	reducers: {
		getCommissionRequest: (state) => {
			state.loadingCommission = true;
		},

		getCommissionSuccess: (state, { payload }: PayloadAction<ICommissionResponse>) => {
			state.commission = payload.commission;
			state.loadingCommission = false;
		},

		putCommissionRequest: (state, { payload }: PayloadAction<IUpdateCommissionRequest>) => {
			state.loadingCommission = true;
		},

		getFreeReferralsRequest: (state, { payload }: PayloadAction<IApiPaginationPayload>) => {
			state.loadingFreeReferrals = true;
		},

		getFreeReferralsSuccess: (state, { payload }: PayloadAction<IReferralsResponse>) => {
			state.loadingFreeReferrals = false;
			state.freeReferrals = payload;
		},

		getReferralsRequest: (state, action: PayloadAction<IApiPaginationPayload>) => {
			state.loadingReferrals = true;
		},

		getReferralsSuccess: (state, { payload }: PayloadAction<TReferralUsersList>) => {
			state.loadingReferrals = false;
			state.referrals = payload;
		},

		getReferralsByUserRequest: (state, action: PayloadAction<IReferralsUserRequest>) => {
			state.loadingReferralsOneUser = true;
		},

		getReferralsByUserSuccess: (state, { payload }: PayloadAction<TReferralUsersList>) => {
			state.loadingReferralsOneUser = false;
			state.referralsOneUser = payload;
		},

		addReferralByUserRequest: (state, action: PayloadAction<IAddReferralUserRequest>) => {
			state.loadingReferralsOneUser = true;
		},

		delReferralByUserRequest: (state, action: PayloadAction<IAddReferralUserRequest>) => {
			state.loadingReferralsOneUser = true;
		},
		getReferralsFileRequest: (state, action?: PayloadAction<IGetReferralFilePayload>) => {},
		getReferralsFileUserIdRequest: (state, action?: PayloadAction<IGetReferralFilePayload>) => {},
	},
});

export default referrals.reducer;
export const {
	getCommissionRequest,
	getCommissionSuccess,
	putCommissionRequest,
	getFreeReferralsRequest,
	getFreeReferralsSuccess,
	getReferralsRequest,
	getReferralsSuccess,
	getReferralsByUserRequest,
	getReferralsByUserSuccess,
	addReferralByUserRequest,
	delReferralByUserRequest,
	getReferralsFileRequest,
	getReferralsFileUserIdRequest,
} = referrals.actions;
