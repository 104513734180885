import React, { FC, useState } from 'react';
import CryptocurrencySelect from 'ui/CryptocurrencySelect/CryptocurrencySelect';
import UsersSearch from '../../../../tables/UsersTable/UsersSearch';
import { IUsersRequest } from '../../../../../services/api/users/types';

interface IFiltersProps {
	filters: any;
	onChange: (any: any) => void;
	// eslint-disable-next-line react/require-default-props
	type?: 'crypto' | 'fiat' | null;
	setCurrentPage: (num: number) => void;
	setSearch: (num: any) => void;
	includeOneifyId?: boolean;
}

const Filters: FC<IFiltersProps> = ({
	filters,
	onChange,
	setSearch,
	type,
	setCurrentPage,
	includeOneifyId,
}) => {
	const [resetSelected, setResetSelected] = useState(false);
	/// new
	const selectChangeCryptocurrency = (value: string | undefined) => {
		onChange({ ...filters, currency: value });
		setCurrentPage(1);
	};
	const resetSelectHandler = () => {
		setResetSelected(!resetSelected);
	};
	return (
		<div className="filters filters--deposit-wallet-user mt-20">
			<UsersSearch
				total={1}
				setQuery={setSearch}
				leftColum={false}
				selectFieldChildren
				resetSelect={resetSelectHandler}
				isResetable
				includeOneifyId={includeOneifyId}
			>
				<div className="filter">
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrency}
						resetSelected={resetSelected}
						type={type}
						selectTitle="Currency"
						disableSearch
						withIcons
					/>
				</div>
			</UsersSearch>
		</div>
	);
};

Filters.defaultProps = {
	includeOneifyId: false,
};

export default Filters;
