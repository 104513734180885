import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IBankAccountApi } from './types';
// ==========================================:
export const bankAccounts: IBankAccountApi = {
	getBankAccounts: (payload) =>
		http
			.get(endpoint.bankAccounts.BANK_ACCOUNTS(payload.userId), { params: payload.pageInfo })
			.then(({ data }) => data),
	deleteBankAccount: (payload) =>
		http
			.delete(endpoint.bankAccounts.BANK_ACCOUNT(payload.userId), {
				data: payload,
			})
			.then(({ data }) => data),
	updateBankAccount: (payload) =>
		http.put(endpoint.bankAccounts.BANK_ACCOUNT(payload.userId), payload).then(({ data }) => data),
};
