import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { EWithdrawalLimitType } from 'services/api/transactions/types';
import { ROUTES } from 'routes/constants';
import TransfersLimitsTable from './TransfersLimitsTable/TransfersLimitsTable';

const TransfersLimitsTab = () => (
	<div className="user-management">
		<div className="user-management-body user-management-body--type2">
			<div className="tabs-buttons tabs-buttons-flex">
				<NavLink
					to={ROUTES.transfersAndTradeReports.transferLimits.crypto}
					className="button button--size4 button--type3"
				>
					Crypto
				</NavLink>
				<NavLink
					to={ROUTES.transfersAndTradeReports.transferLimits.fiat}
					className="button button--size4 button--type3"
				>
					Fiat
				</NavLink>
			</div>

			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.transferLimits.crypto}>
					<TransfersLimitsTable type={EWithdrawalLimitType.CRYPTO} />
				</Route>
				<Route exact path={ROUTES.transfersAndTradeReports.transferLimits.fiat}>
					<TransfersLimitsTable type={EWithdrawalLimitType.FIAT} />
				</Route>
				<Redirect to={ROUTES.transfersAndTradeReports.transferLimits.crypto} />
			</Switch>
		</div>
	</div>
);

export default TransfersLimitsTab;
