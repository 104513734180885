import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/sidebar_logo.svg';

const Header: FC = () => {
	return (
		<header className="header header--center">
			<div className="header__logo">
				<Link to="/">
					<img src={Logo} alt="" />
				</Link>
			</div>
		</header>
	);
};

export default Header;
