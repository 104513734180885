import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { dashboardFiatRequest } from 'redux/reducers/dashboard/reducer';
import { getDashboard } from 'redux/reducers/dashboard/selectors';
import StatBlock from '../Crypto/StatBlock/StatBlock';
import { EStatType } from '../Crypto/StatBlock/types';

const FiatTab = () => {
	const dispatch = useDispatch();
	const fiatData = useSelector(getDashboard);
	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(dashboardFiatRequest());
	}, [dispatch]);

	return (
		<>
			<StatBlock title="Total Liquidity" data={fiatData.liquidity} type={EStatType.LIQUIDITY} />
			<StatBlock
				title="Platform Balances"
				data={fiatData.platformBalance} // fiatData.platformBalance
				type={EStatType.PLATFORM_BALANCES}
			/>
			<StatBlock title="Pending Fees" data={fiatData.pendingFee} type={EStatType.PENDING_FEES} />
			<StatBlock title="B2C2 Balances" data={fiatData.b2c2Balance} type={EStatType.B2C2_BALANCES} />
		</>
	);
};

export default FiatTab;
