/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-duplicates
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	ICurrencyStore,
	IGetCurrencyUserPayload,
	IPutCurrencyUserPayload,
} from 'redux/reducers/currency/types';
// ==========================================:
export const initialState: ICurrencyStore = {
	currencyData: [],
	currencyLoading: false,
	currencyCrypto: null,
	currencyCryptoLoading: false,
	currencyFiat: null,
	currencyFiatLoading: false,
	putCurrencyLoading: false,
	updateState: false,
};

// ==========================================:2
const currency = createSlice({
	name: '@@currency',
	initialState,
	reducers: {
		currencyDataRequest: (state) => {
			state.currencyLoading = true;
		},
		currencyDataSuccess: (state, { payload }: PayloadAction<any[]>) => {
			state.currencyData = payload;
			state.currencyLoading = false;
		},
		currencyValueRequest: (state, action: PayloadAction<IGetCurrencyUserPayload>) => {
			state.currencyCryptoLoading = true;
			state.currencyFiatLoading = true;
		},
		currencyCryptoSuccess: (state, { payload }: PayloadAction<any[]>) => {
			state.currencyCrypto = payload;
			state.currencyCryptoLoading = false;
			state.currencyFiatLoading = false;
		},
		currencyFiatSuccess: (state, { payload }: PayloadAction<any[]>) => {
			state.currencyFiat = payload;
			state.currencyFiatLoading = false;
			state.currencyCryptoLoading = false;
		},
		// put
		putCurrencyValueRequest: (state, actions: PayloadAction<IPutCurrencyUserPayload>) => {
			state.currencyLoading = true;
		},
		putCurrencyValueRequestSuccess: (state) => {
			state.currencyLoading = false;
			state.updateState = !state.updateState;
		},
		updateState: (state) => {
			const newState = state;
			newState.updateState = !newState.updateState;
		},
	},
});
export default currency.reducer;
export const {
	currencyDataRequest,
	currencyDataSuccess,
	currencyValueRequest,
	currencyCryptoSuccess,
	currencyFiatSuccess,
	putCurrencyValueRequest,
	putCurrencyValueRequestSuccess,
} = currency.actions;
