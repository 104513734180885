import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { roundingNumber } from '../../../../../../../services/utils/roundingNumber';
import { popUpOpen, setPopUpData } from '../../../../../../../redux/reducers/popUp/reducer';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
}

/* eslint-disable @typescript-eslint/naming-convention */
const Item: FC<any> = ({ itemList, localFilter, permission }) => {
	const dispatch = useDispatch();
	const [itemFilter, setItemFilter] = useState(itemList === null ? [] : itemList);
	useLayoutEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem > secondItem ? -1 : 1;
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem < secondItem ? -1 : 1;
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);
	return (
		<>
			{itemFilter?.length
				? itemFilter?.map((e: any) => {
						return (
							<div key={uuidv4()} className="tr">
								<div className="td">
									<p className="td-hidden-name">Cryptocurrency</p>
									<div className="td__wrap td__wrap--column">
										{e.fireblocks_balances?.map((chain: any) => {
											if (e.asset_code.toLowerCase() === 'eth' && chain.chain_code === 'Rinkeby') {
												return null;
											}
											return (
												<div className="coin coin--type12">
													<div className="coin__icon">
														<img
															// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
															src={`/img/currencies/${e.asset_code.toLowerCase()}.svg`}
															alt={e.asset_code}
														/>
													</div>
													<div className="coin__text-wrap">
														<p key={uuidv4()} className="coin__text">
															{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
															{`${e.asset_code.toUpperCase()} ${
																e?.fireblocks_balances.length > 1
																	? `(${String(chain.chain_code)})`
																	: ''
															}`}{' '}
														</p>
														<span className="coin__text-more">{e.asset_name}</span>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Platform Balances</p>
									<div className="td__wrap">
										<p>
											{roundingNumber(e.balance, e.asset_code.toLowerCase())}
											<span>≈ {roundingNumber(e.balance_eur)} EUR</span>
										</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Deposit Wallets</p>
									<div className="td__wrap td__wrap--column">
										{e.fireblocks_balances?.map((chain: any) => {
											if (e.asset_code.toLowerCase() === 'eth' && chain.chain_code === 'Rinkeby') {
												return null;
											}
											return (
												<p key={uuidv4()}>
													{roundingNumber(chain.fireblocks_balances, e.asset_code.toLowerCase())}
													<span>≈ {roundingNumber(chain.fireblocks_balances_in_eur)} EUR</span>
												</p>
											);
										})}
									</div>
								</div>

								<div className="td td--right">
									<div className="buttonsChains">
										{permission &&
											e.fireblocks_balances?.map((chain: any) => {
												if (
													e.asset_code.toLowerCase() === 'eth' &&
													chain.chain_code === 'Rinkeby'
												) {
													return null;
												}
												return (
													<button
														key={uuidv4()}
														onClick={() => {
															const data = {
																title: `Send to Pool Wallet`,
																// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																currency: `${e.asset_code.toUpperCase()} (${String(
																	chain.chain_code,
																)})`,
																depositAmount: `${roundingNumber(
																	chain.fireblocks_balances,
																	'crypto',
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																)} ${e.asset_code.toUpperCase()}`,
																walletName: `Pool Wallet ${
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	e.fireblocks_balances.length > 1 ? chain.chain_code : ''
																}`,
																payload: {
																	type: 'User balances deposit wallet totals',
																	asset_id: chain.asset_id,
																	chain_id: chain.chain_id,
																},
																total_balance: chain.fireblocks_balances,
															};
															dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
															dispatch(setPopUpData(data));
														}}
														className="btn btn-primary btn--full"
														type="button"
													>
														Send to Pool Wallet{' '}
														{e.fireblocks_balances.length > 1 ? chain.chain_code : ''}
													</button>
												);
											})}
									</div>
								</div>
							</div>
						);
				  })
				: ''}
		</>
	);
};

export default Item;
