import { useLayoutEffect } from 'react';

const useOutsideClick = (ref: { current: HTMLElement | null }, handler: () => void) => {
	useLayoutEffect(() => {
		const listener = (event: Event) => {
			const target = event.target as HTMLDivElement;
			if (ref.current && !ref.current.contains(target)) {
				handler();
			}
		};

		document.addEventListener('mousedown', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
		};
	}, [handler, ref]);
};

export default useOutsideClick;
