import React, { FC, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EApiTransactionStatus } from 'services/api/transactions/types';
import IconSvg from '../Svg/IconSvg';

export interface ITableItemSelect {
	selectArray: IHistoryFiltersOptionStringValue[];
	activeElement: IHistoryFiltersOptionStringValue;
	onChange: (activeElement: IHistoryFiltersOptionStringValue) => void;
}

const TableItemSelect: FC<ITableItemSelect> = ({ selectArray, activeElement, onChange }) => {
	const [opened, setOpened] = useState(false);
	const handleToggleDropdown = () => setOpened(!opened);
	const handleCloseDropdown = () => setOpened(false);
	const dropdownElement = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownElement, handleCloseDropdown);

	const handleChooseDropdownItem = (newActiveElement: IHistoryFiltersOptionStringValue) => {
		onChange(newActiveElement);
		handleCloseDropdown();
	};

	const getButtonClassName = (type: string) => {
		switch (type) {
			case EApiTransactionStatus.COMPLETED:
				return 'green';
			case EApiTransactionStatus.FAILED:
				return 'red';
			case EApiTransactionStatus.PENDING:
				return 'yellow';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'yellow';
			default:
				return '';
		}
	};

	return (
		<div className={`select select--table ${opened ? 'active' : ''}`} ref={dropdownElement}>
			<button type="button" className="select__current" onClick={handleToggleDropdown}>
				{activeElement?.name || ''}
				<span className="select__current-arrow">
					<IconSvg name="keyboard-arrow-down" w="9" h="6" />
				</span>
			</button>
			<AnimatePresence>
				{opened && (
					<motion.div
						className="select__drop"
						style={{ display: 'block' }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.3 } }}
						exit={{ opacity: 0, transition: { duration: 0.3 } }}
					>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									{!!selectArray?.length &&
										selectArray.map((item) => (
											<li key={item.id}>
												<button
													type="button"
													// className={getButtonClassName(item.value)}
													onClick={() => handleChooseDropdownItem(item)}
												>
													{item.name}
												</button>
											</li>
										))}
								</ul>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default TableItemSelect;
