import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import './index.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

interface IPerPageProps {
	options: Array<number | 'All'>;
	setPerPage: (item: string | number) => void;
	perPage: number;
}

const PerPageDropdown: FC<IPerPageProps> = ({ options, setPerPage, perPage }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const dropdownRef = useRef(null);
	const selectRef = useRef(null);

	const handleSelectItem = (item: number | string) => {
		setPerPage(item);
		setIsOpen(!isOpen);
	};

	const onWindowClick = useCallback(
		(e: Event) => {
			const path = e.composedPath();
			if (dropdownRef?.current && selectRef?.current) {
				if (!path.includes(dropdownRef?.current) && !path.includes(selectRef?.current) && isOpen) {
					setIsOpen(false);
				}
			}
		},
		[isOpen],
	);

	useLayoutEffect(() => {
		window.addEventListener('click', onWindowClick, true);
		return () => window.removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	const handleSelect = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="dropdown_wrapper">
			<div className={`select select_pagination ${isOpen ? 'active' : ''}`}>
				<button type="button" className="select__current" ref={selectRef} onClick={handleSelect}>
					{perPage ?? 10}
					<span className="select__current-arrow">
						<svg
							width="9"
							height="5"
							viewBox="0 0 9 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M1.5 1L4.5 4L7.5 1" stroke="#173B58" strokeLinecap="square" />
						</svg>
					</span>
				</button>
				<AnimatePresence>
					{isOpen && (
						<motion.div
							className="select__drop select_drop--pagination"
							ref={dropdownRef}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.3 } }}
							exit={{ opacity: 0, transition: { duration: 0.3 } }}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										{options.map((item) => (
											<li key={item}>
												<button type="button" onClick={() => handleSelectItem(item)}>
													{item}
												</button>
											</li>
										))}
									</ul>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<span className="dropdown-title">Rows Per Page</span>
		</div>
	);
};

export default PerPageDropdown;
