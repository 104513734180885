import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import { getSecretTokenForFileDownload } from '../../../redux/reducers/auth/selectors';

const PlatformBalance = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const key = useSelector(getSecretTokenForFileDownload);
	const dispatch = useDispatch();
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto />;
			case EPaymentMethodTab.FIAT:
				return <Fiat />;
			default:
				return <Crypto />;
		}
	};
	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};

	return (
		<div className="user-management-body user-management-body--type2">
			<div className="currencyButtonFile">
				<div className="currencyButton">
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
						onClick={setToCryptoTab}
					>
						Crypto
					</button>
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
						onClick={setToFiatTab}
					>
						Fiat
					</button>
				</div>
				<div className="fileButton" />
			</div>
			{chooseTab()}
		</div>
	);
};
export default PlatformBalance;
