import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const SuccessPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">{title || 'Successful'}</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				{!!message && (
					<div className="popup-text">
						<p>{message}</p>
					</div>
				)}
				{/* <div className="popup-footer">
					<div className="popup-submit">
						<button onClick={closeModal} type="button" className="btn btn-primary btn--full">
							Ok
						</button>
					</div>
				</div> */}
			</div>
		</Popup>
	);
};

export default SuccessPopUp;
