import { toast } from 'react-toastify';
import ToastifyComponent from 'components/ToastifyComponent';
import LowAmountError from 'components/ToastifyComponent/LowAmountError/LowAmountError';
import { toastifyConfig } from './toastifyConfig';
import UserBlocked from '../../components/ToastifyComponent/UserBlocked/UserBlocked';
import ArchivingError from '../../components/ToastifyComponent/ArchivingError/ArchivingError';
// ==========================================:
export const notificationContainer = (message: string, type: string, title?: string): void => {
	switch (type) {
		case 'success':
			toast.success(<ToastifyComponent message={message} title={title} />, toastifyConfig);
			break;
		case 'error':
			toast.error(<ToastifyComponent message={message} title={title} />, toastifyConfig);
			break;
		case 'error_archiving':
			toast.error(<ArchivingError message={message} title={title} />, toastifyConfig);
			break;
		case 'user_blocked':
			toast.error(<UserBlocked message={message} title={title} />, toastifyConfig);
			break;
		case 'info':
			toast.info(<ToastifyComponent message={message} title={title} />, toastifyConfig);
			break;
		case 'amount_error':
			toast.error(<LowAmountError message={message} title={title} />, toastifyConfig);
			break;
		default:
			break;
	}
};
