import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { ROUTES } from 'routes/constants';
import WalletBalanceTabs from './WalletBalanceTabs/WalletBalanceTabs';
import Liquidity from './Liquidity/Liquidity';
import PlatformBalance from './PlatformBalance/PlatformBalance';
import DepositWallets from './DepositWallets/DepositWallets';
import PendingFees from './PendingFees/PendingFees';
import UserBalances from './UserBalances/UserBalances';

const WalletBalance = () => {
	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Liquidity Management</p>
				</div>
			</div>
			<WalletBalanceTabs />
			<Switch>
				<Route exact path={ROUTES.walletsManagement.LIQUIDITY} component={Liquidity} />
				<Route
					exact
					path={ROUTES.walletsManagement.PLATFORM_BALANCES}
					component={PlatformBalance}
				/>
				<Route exact path={ROUTES.walletsManagement.DEPOSIT_WALLETS} component={DepositWallets} />
				<Route exact path={ROUTES.walletsManagement.PENDING_FEES} component={PendingFees} />
				<Route exact path={ROUTES.walletsManagement.USER_BALANCES} component={UserBalances} />

				<Redirect to={ROUTES.walletsManagement.LIQUIDITY} />
			</Switch>
		</>
	);
};

export default WalletBalance;
