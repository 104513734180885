import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IAdminDeletedProps } from './types';

const AdminDeleted: FC<IAdminDeletedProps> = ({ open, closeModal }) => {
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="popup popup--width-300">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Successful</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
					>
						<path
							d="M29.0052 49.8385C40.4635 49.8385 49.8385 40.4635 49.8385 29.0052C49.8385 17.5469 40.4635 8.17188 29.0052 8.17188C17.5469 8.17188 8.17188 17.5469 8.17188 29.0052C8.17188 40.4635 17.5469 49.8385 29.0052 49.8385Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-text mb-0">
					<p>Admin was deleted successfully.</p>
				</div>
				{/* <div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={closeModal}>
							Ok
						</button>
					</div>
				</div> */}
			</div>
		</Popup>
	);
};

export default AdminDeleted;
