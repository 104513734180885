/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ISidebarStatesStore } from './types';

// ==========================================:
export const initialState: ISidebarStatesStore = {
	minimizedSidebar: false,
	mobileSidebar: false,
};

// ==========================================:
const sidebarStates = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		toggleMinimized: (state) => {
			state.minimizedSidebar = !state.minimizedSidebar;
		},
		toggleMobileSidebar: (state) => {
			state.mobileSidebar = !state.mobileSidebar;
		},
		sidebarInitState: () => initialState,
	},
});
export default sidebarStates.reducer;
export const { toggleMinimized, toggleMobileSidebar, sidebarInitState } = sidebarStates.actions;
