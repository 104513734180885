import { ITwoFaRequest, ITwoFaResponse } from 'redux/reducers/twoFa/types';

// ==========================================:
export enum ECurrency {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
}

export interface IDashboardApi {
	platformBalances: (type: ECurrency) => Promise<ITwoFaResponse>;
	depositBalances: () => Promise<ITwoFaResponse>;
	profitBalances: () => Promise<ITwoFaResponse>;
	poolBalances: () => Promise<ITwoFaResponse>;
	withdrawBalances: () => Promise<ITwoFaResponse>;
	b2c2Balances: (type: ECurrency) => Promise<ITwoFaResponse>;
	pendingFee: (type: ECurrency) => Promise<ITwoFaResponse>;
	liquidity: (type: ECurrency) => Promise<ITwoFaResponse>;
	liquidity_dashboard: (type: any) => Promise<any>;
}
