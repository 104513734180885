import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import TwoFaSettings from 'components/TwoFaSettings/TwoFaSettings';
import { twoFaCodeByTokenRequest, twoFaEnableByTokenRequest } from 'redux/reducers/twoFa/reducer';
import { ICodeTwoFaByTokenRequest } from 'redux/reducers/twoFa/types';

const CreateNewTwoFaPage = () => {
	const dispatch = useDispatch();
	const location = useLocation<ICodeTwoFaByTokenRequest>();
	// const history = useHistory();
	// const [totp, setTotp] = useState('');
	const token = location.search.slice(1);

	useLayoutEffect(() => {
		if (token) {
			dispatch(twoFaCodeByTokenRequest({ token }));
		}
	}, [dispatch, token]);

	/* useLayoutEffect(() => {
		if (totp && location.search) {
			dispatch(twoFaEnableByTokenRequest({ totp, token }));
			// history.push(ROUTES.login);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totp]); */

	const submitHandler = (totp: any) => {
		dispatch(twoFaEnableByTokenRequest({ totp, token }));
	};

	return <TwoFaSettings setTotp={submitHandler} />;
};

export default CreateNewTwoFaPage;
