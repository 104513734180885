import React, { FC, useState } from 'react';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { DataLiquidity } from '../../../../../redux/reducers/walletBalance/types';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: DataLiquidity;
}

const Item: FC<Props> = ({ item }) => {
	const {
		code,
		platformBalanceValue,
		depositBalanceValue,
		poolBalanceValue,
		withdrawBalanceValue,
		pendingFeeValue,
		profitBalanceValue,
		B2C2BalanceValue,
		valueCrypto,
	} = item;
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Cryptocurrency</p>
					<div className="td__wrap">
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p>{code.toUpperCase()}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Total Liquidity</p>
					<div className="td__wrap">
						<p>{roundingNumber(valueCrypto, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Platform Balances</p>
					<div className="td__wrap">
						<p>{roundingNumber(platformBalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Deposit Wallets</p>
					<div className="td__wrap">
						<p>{roundingNumber(depositBalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Pooled Wallets</p>
					<div className="td__wrap">
						<p>{roundingNumber(poolBalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Withdrawal Wallets</p>
					<div className="td__wrap">
						<p>{roundingNumber(withdrawBalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Pending Fees</p>
					<div className="td__wrap">
						<p>{roundingNumber(pendingFeeValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Profit Wallets</p>
					<div className="td__wrap">
						<p>{roundingNumber(profitBalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">B2C2 Balances</p>
					<div className="td__wrap">
						<p>{roundingNumber(B2C2BalanceValue, code.toUpperCase())}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
