import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { toggleMobileSidebar } from 'redux/reducers/sidebarStates/reducer';
import IconSvg from 'ui/Svg/IconSvg';
import Logo from '../../../assets/img/sidebar_logo.svg';

import { IHeaderLoginProps } from './types';

const HeaderLogin: FC<IHeaderLoginProps> = ({ userName }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const handleToggleMobileSidebar = () => dispatch(toggleMobileSidebar());

	return (
		<header className="header">
			<button className="mob-nav-btn" type="button" onClick={handleToggleMobileSidebar}>
				<svg className="fill" viewBox="0 0 448 512">
					<path
						fill="currentColor"
						d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
					/>
				</svg>
			</button>
			<div className="header__logo">
				<img src={Logo} alt="" />
			</div>
			{/* <div className="admin"> */}
			{/*	{userName && <p className="admin__name">{userName}</p>} */}

			{/*	<button */}
			{/*		className="admin__logout" */}
			{/*		type="button" */}
			{/*		onClick={() => { */}
			{/*			dispatch(logoutRequest({ history })); */}
			{/*		}} */}
			{/*	> */}
			{/*		<IconSvg name="log-out" w="18" h="18" /> */}
			{/*	</button> */}
			{/* </div> */}
		</header>
	);
};
export default HeaderLogin;
