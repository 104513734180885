import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { roundingNumber } from 'services/utils/roundingNumber';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { v4 as uuidv4 } from 'uuid';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
	permission: boolean;
}

const Item: FC<Props> = ({ item, permission }) => {
	const dispatch = useDispatch();
	const { code } = item;
	const handleProfitWalletTranfer = () => {};
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Cryptocurrency</p>
					<div className="td__wrap td__wrap--column">
						{item.chains?.map((chain: any) => {
							if (chain.chain_code === 'Rinkeby') {
								return null;
							}
							return (
								<div className="coin coin--type11">
									<div className="coin__icon">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
									</div>
									<div className="coin__text-wrap">
										<p key={uuidv4()} className="coin__text">
											{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
											{`${item.code.toUpperCase()} ${
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												item?.chains.length > 1 ? `(${String(chain.chain_code)})` : ''
											}`}{' '}
										</p>
										<span className="coin__text-more">{item.name}</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Platform Balance</p>
					<div className="td__wrap">
						<p>
							{roundingNumber(item?.total_balance.valueCrypto, code)} {code.toUpperCase()}
						</p>
					</div>
				</div>

				<div className="td td--right">
					<p className="td-hidden-name" />
					<div className="buttonsChains">
						{permission &&
							item.chains?.map((chain: any) => {
								if (chain.chain_code === 'Rinkeby') {
									return null;
								}
								return (
									<button
										key={uuidv4()}
										onClick={() => {
											const data = {
												title: `Transfer to Profit Wallet`,
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												currency: `${code.toUpperCase()} (${String(chain.chain_code)})`,
												totalBalance: `${roundingNumber(
													item?.total_balance.valueCrypto,
													code,
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												)} ${code.toUpperCase()}`,
												walletName: `Profit Wallet ${
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													item.chains.length > 1 ? chain.chain_code : ''
												}`,
												payload: {
													type: 'Pending fees crypto',
													asset_id: chain.asset_id,
													chain_id: chain.chain_id,
												},
												total_balance: item?.total_balance.valueCrypto,
											};
											dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
											dispatch(setPopUpData(data));
										}}
										className="btn btn-primary btn--full"
										type="button"
									>
										Transfer to Profit Wallet {item.chains.length > 1 ? chain.chain_code : ''}
									</button>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
