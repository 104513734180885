import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ICurrencyDataItem,
	IGetCurrencyUserPayload,
	IPutCurrencyUserPayload,
} from 'redux/reducers/currency/types';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	currencyCryptoSuccess,
	currencyDataRequest,
	currencyDataSuccess,
	currencyFiatSuccess,
	currencyValueRequest,
	putCurrencyValueRequest,
	putCurrencyValueRequestSuccess,
} from './reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';

// =============================================================:

// =============================================================:

function* currencyDataRequestWorker() {
	try {
		yield put(showLoading());
		const response: ICurrencyDataItem[] = yield call(api.currency.getCurrency);

		yield put(currencyDataSuccess(response));
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* currencyValueRequestWorker({ payload }: PayloadAction<IGetCurrencyUserPayload>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.currency.getCurrencyValue, apiParams);
		if (apiParams.value === 'crypto') {
			yield put(currencyCryptoSuccess(response));
		} else {
			yield put(currencyFiatSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// put
function* putCurrencyValueRequestWorker({ payload }: PayloadAction<IPutCurrencyUserPayload>) {
	const { apiParams, action, assetName } = payload;
	try {
		yield call(api.currency.putCurrencyValue, apiParams);
		yield put(putCurrencyValueRequestSuccess());
		notificationContainer(
			notificationsMessagesInfo.putFeeCurrenciesRequest(assetName, action),
			'success',
			'Changes saved',
		);
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* currencySaga() {
	yield takeEvery(currencyDataRequest.type, currencyDataRequestWorker);
	yield takeEvery(currencyValueRequest.type, currencyValueRequestWorker);
	yield takeEvery(putCurrencyValueRequest.type, putCurrencyValueRequestWorker);
}
