import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// import TwoFaSettings from 'components/TwoFaSettings/TwoFaSettings';
import TwoFaSettingsFirst from 'components/TwoFaSettings/TwoFaSettingsFirst';
import { IResetPasswordValue } from 'redux/reducers/auth/types';
import { twoFaCodeByTokenRequest } from 'redux/reducers/twoFa/reducer';
import { ICodeTwoFaByTokenRequest } from 'redux/reducers/twoFa/types';
import { loginFirst } from 'redux/reducers/auth/reducer';

const LoginFirstTwoFaPage = () => {
	const dispatch = useDispatch();
	const location = useLocation<IResetPasswordValue & ICodeTwoFaByTokenRequest>();
	// const [totp, setTotp] = useState('');

	useLayoutEffect(() => {
		if (location.state) {
			dispatch(twoFaCodeByTokenRequest({ token: location.state.token }));
		} else if (location.search) {
			dispatch(twoFaCodeByTokenRequest({ token: location.search.slice(1) }));
		}
	}, [dispatch, location.state, location.search]);

	/* useLayoutEffect(() => {
		if (totp) {
			dispatch(loginFirst({ ...location.state, totp }));
			console.log(totp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totp]); */

	const submitHandler = (totp: any) => {
		dispatch(loginFirst({ ...location.state, totp }));
	};

	return <TwoFaSettingsFirst setTotp={submitHandler} />;
};

export default LoginFirstTwoFaPage;
