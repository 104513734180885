export interface IMessage {
	id: number;
	title: string;
	message: string;
	files: string[];
	sent: number;
	planned_on: string;
	created_at: string;
	updated_at: string;
	users_ids: number[];
	users_all: number;
}

export interface IPagination<T> {
	current_page: number;
	data: T[];
	per_page: number;
	total: number;
	last_page: number;
	from: number;
}

export type IMessageWithPagination = IPagination<IMessage>;

export interface IMessageStore {
	messages: IMessageWithPagination | null;
	loading: boolean;
	messagesFilters: IMessagesFilter[];
	oneMessage: IMessage | null;
	loadingFiles: boolean;
}

export enum EApiMessagesType {
	PLANNED = 'planned',
	SENT = 'sent',
}

export interface IMessageRequestPayload {
	type?: EApiMessagesType;
	per_page?: number;
	current_page?: number;
	user_id?: number | string;
	date_from?: string;
	date_to?: string;
	email?: string;
	company_name?: string;
	full_name?: string;
}

export enum EMessagesFilters {
	TYPE = 'type',
	USER_ID = 'user_id',
	ADMIN_ID = 'admin_id',
	EMAIL = 'email',
	COMPANY_NAME = 'company_name',
	FULL_NAME = 'full_name',
	DATE = 'date',
}

export interface IMessagesDateFilterValue {
	date_to: Date;
	date_from: Date;
}

export interface IMessagesFilter {
	type: EMessagesFilters;
	name?: string;
	value: string | IMessagesDateFilterValue;
}

export interface IApiGetOneMessageParams {
	messageId: number;
}

export interface IMessageCreateRequestPayload {
	body: FormData;
	onCloseForm: () => void;
	sent?: boolean;
	type?: EApiMessagesType;
}

export interface IMessageDeleteRequestPayload {
	id: number;
	onCloseForm?: () => void;
	type?: EApiMessagesType;
	timezone?: string;
}

export interface IMessageUpdateRequestPayload extends IMessageCreateRequestPayload {
	id: number;
}

export interface IMessageDeleteFilesRequestPayload {
	id: number;
	files: string[];
}

export interface IMessageDownloadFilesRequestPayload {
	file: string;
}
