import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import { useSelector } from 'react-redux';
import NotAuthorized from 'components/NotAuthorized/NotAuthorized';
import { getUserData } from 'redux/reducers/auth/selectors';
import HeaderLogin from 'layouts-elements/Header/HeaderLogin/HeaderLogin';
import { IAuth } from './types';
import Footer from '../../layouts-elements/Footer';
// ==========================================:
const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const login = useSelector(getUserData);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>

			<HeaderLogin userName={login?.first_name} />
			<Content>{children}</Content>
			<Footer />
		</>
	);
};

export default Auth;
