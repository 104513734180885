import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import PerPageSelect from '../../../ui/PerPageSelect/PerPageSelect';
import Pagination from '../../../ui/Pagination/Pagination';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../layouts-elements/Loading/Loading';
import { ITransferLimitsTableProps } from './types';
import TransferLimitsTableRow from './TransferLimitsTableRow';

const TransferLimitsTable: FC<ITransferLimitsTableProps> = ({
	limitsList,
	permission,
	updateHandler,
	loading,
}) => {
	const { userId } = useParams<IAccountDetailsParams>();

	return (
		<>
			<div className="table-block table-block--transactions-transfer-limits">
				<div className="table-wrapper">
					<div className="table table--type2 table--transactions-transfer-limits">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Minimum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Maximum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Hourly Limit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Daily Limit</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{limitsList?.data?.length ? (
										limitsList?.data?.map((limit) => (
											<TransferLimitsTableRow
												key={limit.id}
												limit={limit}
												userId={userId}
												updateHandler={updateHandler}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{/* {!!limitsList?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCountValue && currentPageValue && (
										<>
											{pageCountValue > 1 && (
												<Pagination
													pageCount={pageCountValue}
													currentPage={currentPageValue}
													onPageChange={setCurrentPage}
												/>
											)}
										</>
									)}
								</div>
							</div>
						)} */}
					</div>
				</div>
			</div>
		</>
	);
};

export default TransferLimitsTable;
