import React, { FC } from 'react';
import classNames from 'classnames';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IBalanceListProps } from './types';

const BalanceList: FC<IBalanceListProps> = ({ list, activeIndex, onChangeActive }) => {
	return (
		<div className="balance-info-list">
			{list?.map((item, index) => (
				<>
					{item.name && (
						<div
							key={item.name}
							className={classNames('balance-info-list__items', {
								active: index === activeIndex,
							})}
							onClick={() => onChangeActive(index)}
						>
							<div className="box-info">
								<div className="box-info_img">
									{/* <img
										src={item?.img?.replace('oneify-stage.corp.merehead.xyz', 'dev1.oneify.cybro.cz')}
										alt={item?.code}
									/> */}
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									<img src={`/img/currencies/${item?.name.toLowerCase()}.svg`} alt={item?.name} />
								</div>
								<div className="box-info_name">
									<span className="coin__text coin__text--fw-500 coin__text--fz-12">
										{item.name}
									</span>
								</div>
							</div>
							<div className="box-info-coin">
								<span>
									{item.valueCrypto
										? `${roundingNumber(item.valueCrypto, item.name)} ${String(item.name)}`
										: `${roundingNumber(0, item.name)} ${String(item.name)}`}{' '}
									/
								</span>
								<p>{roundingNumber(item.valueEuro, 'EUR')} EUR</p>
							</div>
						</div>
					)}
				</>
			))}
		</div>
	);
};

export default BalanceList;
