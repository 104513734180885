import { IStoreState } from 'redux/types';
import { IReferralsStore } from './types';

export const referralsStore = (state: IStoreState): IReferralsStore => state.referrals;

export const getCommission = (state: IStoreState) => {
	return referralsStore(state).commission;
};

export const getReferrals = (state: IStoreState) => {
	return referralsStore(state).referrals;
};
export const getReferralsLoading = (state: IStoreState) => {
	return referralsStore(state).loadingReferrals;
};
export const getReferralsOneUser = (state: IStoreState) => {
	return referralsStore(state).referralsOneUser;
};
export const getReferralsOneUserLoading = (state: IStoreState) => {
	return referralsStore(state).loadingReferralsOneUser;
};
export const getFreeReferrals = (state: IStoreState) => {
	return referralsStore(state).freeReferrals;
};
