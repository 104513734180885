import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { ITradingLimitsRequest } from 'services/api/users/types';
import {
	getUserTradingLimitsRequest,
	getUserTransferLimitsRequest,
} from 'redux/reducers/users/reducer';
import { getUserLoader, getUserTransferLimits } from 'redux/reducers/users/selectors';
import TransferLimitsTable from '../../../tables/TransferLimitsTable/TransferLimitsTable';

const TransferLimitsTabView = () => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const userTransferLimits = useSelector(getUserTransferLimits);
	const loading = useSelector(getUserLoader);
	const permissions = useSelector(getPermissions);

	const [assetType, setAssetType] = useState('crypto');

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		if (userId) {
			dispatch(
				getUserTransferLimitsRequest({
					user_id: userId,
					type: assetType,
				}),
			);
		}
	}, [assetType, dispatch, userId]);

	const updateHandler = () => {
		dispatch(
			getUserTransferLimitsRequest({
				user_id: userId,
				type: assetType,
			}),
		);
	};

	return (
		<div className="user-management">
			<div className="currencyButtonFile mt-20 mb-20">
				<div className="currencyButton">
					<button
						type="button"
						className={assetType === 'crypto' ? 'active' : ''}
						onClick={() => {
							setAssetType('crypto');
						}}
					>
						Crypto
					</button>
					<button
						type="button"
						className={assetType === 'fiat' ? 'active' : ''}
						onClick={() => {
							setAssetType('fiat');
						}}
					>
						Fiat
					</button>
				</div>
			</div>

			<TransferLimitsTable
				limitsList={userTransferLimits}
				permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
				updateHandler={updateHandler}
				loading={loading}
			/>
		</div>
	);
};

export default TransferLimitsTabView;
