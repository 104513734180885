import React from 'react';

const ReferralsByUserTableHeader = () => (
	<div className="table-header">
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Email</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Company Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Referral Fee %</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Month Trade Size, EUR</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	</div>
);

export default ReferralsByUserTableHeader;
