import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import LoadingBar from 'react-redux-loading-bar';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter as ConnectedRouterProvider } from 'connected-react-router';
import { ToastContainer, cssTransition } from 'react-toastify';
import { persistor, store } from 'redux/store';
import { history } from 'routes/history';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/App';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

// import 'assets/styles/tailwind.css';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/dist/css/main.min.css';
import 'assets/dist/css/style.min.css';
import 'assets/dist/css/date-picker.min.css';
import 'assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';
import PopUp from './layouts-elements/PopUp/PopUp';

const fade = cssTransition({
	enter: 'animate__animated animate__fadeIn',
	exit: 'animate__animated animate__fadeOut',
});

polyfillCountryFlagEmojis();

const rootElement = document.getElementById('root') as HTMLElement;

const render = (Component: ComponentType, root: HTMLElement, done?: () => void) => {
	ReactDOM.render(
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouterProvider history={history}>
					<LoadingBar className="loading-bar" />
					<BrowserRouter>
						<Component />
					</BrowserRouter>
					<PopUp />
					<ToastContainer transition={fade} />
				</ConnectedRouterProvider>
			</PersistGate>
		</ReduxProvider>,
		root,
		done,
	);
};

render(App, rootElement);

reportWebVitals();
