import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EWithdrawalLimitType } from 'services/api/transactions/types';
import TradesLimitsTable from './TradesLimitsTable/TradesLimitsTable';

const TradesLimitsTab = () => (
	<div className="user-management">
		<div className="user-management-body user-management-body--type2">
			<div className="tabs-buttons tabs-buttons-flex">
				<NavLink
					to={ROUTES.transfersAndTradeReports.tradesLimits.crypto}
					className="button button--size4 button--type3"
				>
					Crypto
				</NavLink>
				<NavLink
					to={ROUTES.transfersAndTradeReports.tradesLimits.fiat}
					className="button button--size4 button--type3"
				>
					Fiat
				</NavLink>
			</div>

			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.tradesLimits.crypto}>
					<TradesLimitsTable type={EWithdrawalLimitType.CRYPTO} />
				</Route>
				<Route exact path={ROUTES.transfersAndTradeReports.tradesLimits.fiat}>
					<TradesLimitsTable type={EWithdrawalLimitType.FIAT} />
				</Route>
				<Redirect to={ROUTES.transfersAndTradeReports.tradesLimits.crypto} />
			</Switch>
		</div>
	</div>
);

export default TradesLimitsTab;
