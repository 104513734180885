import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOneUserRequest } from 'redux/reducers/users/reducer';
import { getOneUser } from 'redux/reducers/users/selectors';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import Loading from 'layouts-elements/Loading/Loading';

import { getPendingWithdrawal } from '../../../redux/reducers/transactions/selectors';
import DetailedViewCryptoPendingForm from '../DetailedCryptoForm/DetailedCryptoPendingForm';
import {
	getCryptoDetails,
	getDetailedViewLoading,
} from '../../../redux/reducers/detailedView/selectors';
import { getCryptoDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface IDeposiCryptoDetails {
	txId: string | number;
	userId: string | number;
}

const DepositCryptoPendingDetails: FC<IDeposiCryptoDetails> = ({ txId, userId }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	const currencyList = useSelector(getCurrencyData);
	const [asset, setAsset] = useState<ICurrencyDataItem | null>(null);
	// const loading = useSelector(getPendingWithdrawalLoading);
	const loading = useSelector(getDetailedViewLoading);
	const currentItemData = useSelector(getPendingWithdrawal);
	const cryptoDetails = useSelector(getCryptoDetails);

	useEffect(() => {
		if (txId) {
			// const params: IApiGetTransactionParams = {
			// 	current_page: 1,
			// 	per_page: 1,
			// 	id: Number(txId),
			// };
			// dispatch(getPendingWithdrawalRequest({ type: 'crypto', params }));
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	useEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	useEffect(() => {
		cryptoDetails &&
			setAsset(currencyList.filter((item) => item.id === cryptoDetails?.asset_id)[0]);
	}, [cryptoDetails, currencyList, dispatch]);

	return (
		<>
			{cryptoDetails && !loading && userData ? (
				<div className="detailed-view" key={cryptoDetails.id}>
					<DetailedViewCryptoPendingForm item={cryptoDetails} userData={userData} asset={asset} />
				</div>
			) : (
				<>{loading ? <Loading /> : <div>No data</div>}</>
			)}
		</>
	);
};

export default DepositCryptoPendingDetails;
