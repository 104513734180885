import { ContentState, convertFromHTML, EditorState } from 'draft-js';

export const getPlainTextFromEditorState = (html: string | undefined) => {
	if (html) {
		const blocksFromHTML = convertFromHTML(html);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap,
		);
		const editorState = EditorState.createWithContent(state);
		return editorState.getCurrentContent().getPlainText();
	}
	return '';
};
