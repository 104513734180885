import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IUserWalletsItem } from 'services/api/users/types';
import { useParams } from 'react-router';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { v4 as uuidv4 } from 'uuid';
import { ECoinType } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const WalletTableRow: FC<IUserWalletsItem> = ({ asset, balance, balance_eur, type, address }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const permissions = useSelector(getPermissions);

	return (
		<>
			{balance === 0 &&
			!asset?.withdrawable &&
			!asset?.depositable &&
			!asset.exchangeable ? null : (
				<div className={`tr ${type === ECoinType.CRYPTO ? 'cryptoRow' : 'fiatRow'} `}>
					<div className="td">
						<div className="td-hidden-name">
							{type === ECoinType.CRYPTO ? 'Cryptocurrency' : 'Currency'}
						</div>
						{type === ECoinType.CRYPTO ? (
							<>
								<div className="crypto-flex">
									{asset?.chains?.map((chain: any) => {
										if (chain.code !== 'Rinkeby') {
											return (
												<div className="coin coin--type12">
													<div className="coin__icon">
														<img
															// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
															src={`/img/currencies/${asset.code.toLowerCase()}.svg`}
															alt={asset.code}
														/>
													</div>
													<div className="coin__text-wrap">
														<p key={uuidv4()} className="coin__text">
															{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
															{`${asset?.code?.toUpperCase()} ${
																// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																// @ts-ignore
																asset?.chains.length > 1 ? `(${String(chain.code)})` : ''
															}`}{' '}
														</p>
														<span className="coin__text-more">{asset?.name}</span>
													</div>
												</div>
											);
										}
										return null;
									})}
								</div>
							</>
						) : (
							<div className="flex">
								<div className="coin__icon">
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${asset.code.toLowerCase()}.svg`}
										alt={asset.code}
									/>
								</div>
								<div className="coin__icon___text">
									<p>{asset?.code?.toUpperCase()}</p>
									<span>{asset?.name}</span>
								</div>
							</div>
						)}
					</div>

					<div
						className={`td flex-end ${type === ECoinType.FIAT ? 'td--right' : ''} ${
							type === ECoinType.CRYPTO ? 'platform-balance' : 'total'
						}`}
					>
						<div className="td-hidden-name">
							{type === ECoinType.CRYPTO ? 'Platform Balance' : 'Total'}
						</div>
						{type === ECoinType.CRYPTO ? (
							<p>
								{roundingNumber(balance, asset?.code)}
								{/* {address.reduce((acc, item) => acc + item.fireblocks_balance, 0)} */}
								<br />
								<span> {roundingNumber(balance_eur, 'fiat')} EUR</span>
							</p>
						) : (
							<p>
								{roundingNumber(balance)}
								<br />
								<span className="span-fiat"> {roundingNumber(balance_eur, 'fiat')} EUR</span>
							</p>
						)}
					</div>
					{type === ECoinType.CRYPTO && (
						<>
							<div className="td flex-end">
								<div className="td-hidden-name">Deposit Wallet</div>
								{asset?.chains?.map((e) => {
									if (e.code !== 'Rinkeby') {
										return (
											<p key={e.id}>
												{roundingNumber(e?.balances?.balance, asset?.code)}
												<br />
												<span>{roundingNumber(e?.balances?.balance_eur, 'fiat')} EUR</span>
											</p>
										);
									}
									return null;
								})}
							</div>
							<div className="td td--right">
								<div className="buttonsChains">
									{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
										<>
											{asset?.chains?.map((chain) => {
												if (chain.code !== 'Rinkeby') {
													return (
														<button
															key={chain.id}
															onClick={() => {
																const data = {
																	title: `Send to Pool Wallet`,
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	currency: `${asset.code.toUpperCase()} (${String(chain.code)})`,
																	depositAmount: `${roundingNumber(
																		chain.balances?.balance,
																		'crypto',
																		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	)} ${asset.code.toUpperCase()}`,
																	walletName: `Pool Wallet ${
																		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																		balance > 1 ? chain.code : ''
																	}`,
																	payload: {
																		type: 'User balances deposit wallet totals user-management',
																		asset_id: asset.id,
																		chain_id: chain.id,
																		userId,
																	},

																	total_balance: chain?.balances?.balance,
																};
																dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
																dispatch(setPopUpData(data));
															}}
															type="button"
															className="btn btn-primary btn--full"
														>
															Send to Pool Wallet {chain?.code}
														</button>
													);
												}
												return null;
											})}
										</>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default WalletTableRow;
