import { RefObject, useLayoutEffect } from 'react';

const useClickOutside = <T extends HTMLElement>(ref: RefObject<T>, fn: () => void) => {
	useLayoutEffect(() => {
		const element = ref?.current;
		const handleClickOutside = (event: Event) => {
			if (element && !element.contains(event.target as Node | null)) {
				fn();
			}
		};
		if (element) {
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
};

export default useClickOutside;
