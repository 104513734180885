import React, { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Popup from 'reactjs-popup';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import {
	addReferralByUserRequest,
	getFreeReferralsRequest,
} from 'redux/reducers/referrals/reducer';
import { getFreeReferrals } from 'redux/reducers/referrals/selectors';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import IconSvg from 'ui/Svg/IconSvg';
import useInput from 'hooks/useInput';
import { useDebounce } from 'hooks/useDebounce';
import { SearchField } from './types';
import { IPopUp } from '../types';

const AddReferral: FC<IPopUp> = ({ open, closeModal }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();

	const referrals = useSelector(getFreeReferrals);

	const [openSelect, setOpenSelect] = useState<boolean>(false);

	const [searchField, setSearchField] = useState<string>(SearchField.id);
	const searchValue = useInput('');
	const debouncedSearchValue = useDebounce(searchValue.value, 500);

	const selectRef = useRef<HTMLDivElement | null>(null);

	const selectSearchField = useCallback((field) => {
		setSearchField(field);
		setOpenSelect(false);
	}, []);
	const filteredList = useMemo(() => {
		if (!referrals) return [];

		return referrals.data
			.filter((el) => String(el.id) !== userId)
			.filter((referral) => {
				const id = String(referral.id);
				const lastName = referral.data.last_name;
				const firstName = referral.data.first_name;
				const oneifyId = String(referral.oneify_id);
				if (debouncedSearchValue) {
					if (searchField === SearchField.id) {
						return id.includes(debouncedSearchValue);
					}

					if (searchField === SearchField.oneify_id) {
						return oneifyId.includes(debouncedSearchValue);
					}

					if (searchField === SearchField.name) {
						if (!!lastName && !!firstName) {
							return (
								(!!lastName && !!firstName && firstName.includes(debouncedSearchValue)) ||
								lastName.includes(debouncedSearchValue)
							);
						}
						return false;
					}
				}
				return true;
			});
	}, [debouncedSearchValue, referrals, searchField, userId]);

	const searchFieldsArray = Object.keys(SearchField) as Array<keyof typeof SearchField>;

	const handleOpenSelect = useCallback(() => {
		setOpenSelect(!openSelect);
	}, [openSelect]);

	const handlerAddReferral = useCallback(
		(id) => {
			dispatch(addReferralByUserRequest({ userId, referral_id: id }));
			closeModal();
		},
		[closeModal, dispatch, userId],
	);

	useLayoutEffect(() => {
		const params = {
			per_page: referrals?.total,
		};
		dispatch(getFreeReferralsRequest(params));
	}, [dispatch, referrals?.total]);

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setOpenSelect(false);
			}
		};
		if (openSelect) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [openSelect]);

	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Add Referral</p>
				</div>
				<div className="popup-body">
					<div className="popup-search mb-10">
						<div className={openSelect ? 'select active' : 'select'} ref={selectRef}>
							<button className="select__current" type="button" onClick={handleOpenSelect}>
								<div className="select__current-text">
									<p>{searchField}</p>
								</div>
								<span className="select__current-arrow" />
							</button>
							<AnimatePresence>
								{openSelect && (
									<motion.div
										className="select__drop"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1, transition: { duration: 0.3 } }}
										exit={{ opacity: 0, transition: { duration: 0.3 } }}
									>
										<div className="select__drop-scroll">
											<div className="select__drop-item">
												<ul>
													{searchFieldsArray.map((field) => (
														<li key={field}>
															<button
																onClick={() => selectSearchField(SearchField[field])}
																type="button"
															>
																{SearchField[field]}
															</button>
														</li>
													))}
												</ul>
											</div>
										</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
						<div className="input">
							<div className="input-wrapper">
								<input className="input-item" type="text" placeholder="Search" {...searchValue} />
								<div className="input-icon">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.66634 14.0026C11.1641 14.0026 13.9997 11.1671 13.9997 7.66927C13.9997 4.17147 11.1641 1.33594 7.66634 1.33594C4.16854 1.33594 1.33301 4.17147 1.33301 7.66927C1.33301 11.1671 4.16854 14.0026 7.66634 14.0026Z"
											stroke="#BFBFBF"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14.6663 14.6693L13.333 13.3359"
											stroke="#BFBFBF"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="add-account-search-list">
						{filteredList.length ? (
							filteredList.map((referral) => (
								<button
									type="button"
									className="add-account-search-item"
									key={referral.id}
									onClick={() => handlerAddReferral(referral.id)}
								>
									{referral.id}{' '}
									<span>
										{referral.data.first_name} {referral.data.last_name}
									</span>
								</button>
							))
						) : (
							<div className="text--center">User not found</div>
						)}
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={closeModal}>
							Confirm
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AddReferral;
