import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { updateUserArchiveStatus, updateUserBlockStatus } from 'redux/reducers/users/reducer';

import IconSvg from 'ui/Svg/IconSvg';
import { IStatusUpdatetProps } from './types';

const StatusUpdatet: FC<IStatusUpdatetProps> = ({
	open,
	closeModal,
	event = 'block',
	userId,
	query,
}) => {
	const dispatch = useDispatch();

	const handleConfirm = useCallback(() => {
		switch (event) {
			case 'block':
				dispatch(updateUserBlockStatus({ id: userId, event: 'blocked', query }));
				dispatch(setPopUpData({ title: 'blocked' }));
				break;
			case 'unblock':
				dispatch(updateUserBlockStatus({ id: userId, event: 'unblocked', query }));
				dispatch(setPopUpData({ title: 'unblocked' }));
				break;
			case 'archive':
				dispatch(updateUserArchiveStatus({ userId, event, query }));
				dispatch(setPopUpData({ title: 'archived' }));
				break;
			case 'unarchive':
				dispatch(setPopUpData({ title: 'unarchived' }));
				dispatch(updateUserArchiveStatus({ userId, event, query }));
				break;
			default:
				return;
		}

		closeModal();
		dispatch(popUpOpen('StatusUpdated'));
	}, [closeModal, dispatch, event, query, userId]);

	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">
						<span className="capitalize">{event}</span> User
					</p>
				</div>
				<div className="popup-body">
					<div className={`popup-img status-popupImg ${event || ''}`}>
						<div className="outer-circle">
							<div className="inner-circle" />
						</div>
						<IconSvg name={event} w="60" h="60" />
					</div>
					<div className="popup-text popup-text--type2 popup-text--center">
						<p>Are you sure you want to {event} the user?</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={handleConfirm}>
							Confirm
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default StatusUpdatet;
