export const handleFeeFixedChange = (
	event: React.ChangeEvent<HTMLInputElement>,
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
	fieldName: string,
	type: string,
) => {
	const inputValue = event.target.value;
	let regexFiat = /^\d+(\.\d{0,2})?/;
	let regexCrypto = /^\d+(\.\d{0,5})?/;

	if (inputValue.startsWith('.')) {
		regexFiat = /(\.\d{0,2})?/;
		regexCrypto = /(\.\d{0,5})?/;
	}

	const match = type === 'crypto' ? regexCrypto.exec(inputValue) : regexFiat.exec(inputValue);

	const fixedValue = match ? match[0] : inputValue;
	setFieldValue(fieldName, fixedValue);
};
