import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICountrySelect, ICountrySelectCustomList, ICountrySelectItem } from './types';

const CountrySelectCustomInput: FC<ICountrySelectItem> = ({ label, value }) => {
	return (
		<span className="coin coin--type8 flex-row">
			{value && value.length < 3 && (
				<span className="coin__icon">
					<img src={`/img/countries/${value.toLowerCase()}.svg`} alt={label} />
				</span>
			)}
			<span
				className={
					label
						? 'coin__text coin__text--text-black coin__text--fz-14'
						: 'coin__text coin__text--placeholder coin__text--fz-14'
				}
			>
				{label || 'Choose Country'}
			</span>
		</span>
	);
};
const CountrySelectCustomList: FC<ICountrySelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr?.length ? (
			arr.map((el) => (
				<li key={el.value}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type10 coin__text-normal">
							<span className="coin__icon">
								<img src={`/img/countries/${el.value.toLowerCase()}.svg`} alt={el.label} />
							</span>
							<span className="coin__text">{el.label}</span>
						</span>
					</button>
				</li>
			))
		) : (
			<span className="coin coin--type3 coin__text-normal">
				<span className="coin__text upper__text coin__text---pt-0">No Items</span>
			</span>
		)}
	</>
);

const CountriesSelect: FC<ICountrySelect> = ({
	name,
	title,
	touched,
	arr,
	resetCustomSelect,
	setTouched,
	setResetCustomSelect,
	onChange,
	activeValue,
	validateField,
	disabled,
	className,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			label: '',
			value: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<ICountrySelectItem>(initActiveCurrency);
	const [searchArr, setSearchArr] = useState<ICountrySelectItem[]>([]);
	useLayoutEffect(() => {
		if (arr) {
			setSearchArr(arr);
		}
	}, [arr]);

	useLayoutEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useLayoutEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: ICountrySelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: <CountrySelectCustomList arr={searchArr} onChange={onChangeHandler} />,
		activeElement: activeCurrency.label,
	};

	useLayoutEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);
	// console.log(props);
	return (
		<>
			<Select
				{...props}
				setTouched={setTouched}
				touched={touched && !activeValue?.value}
				customInput={<CountrySelectCustomInput {...activeCurrency} />}
				setSearchArr={setSearchArr}
				customList={customList}
				arr={arr}
				disabled={disabled}
				validateField={validateField}
				// name={props.field.name}
				name={name}
				className={className}
			/>
		</>
	);
};

export default CountriesSelect;
