import DepositAccountCrypto from './DepositAccountsCrypto/DepositAccountCrypto';

const DepositAccountsTab = () => {
	return (
		<div className="user-management-body user-management-body--type2">
			<DepositAccountCrypto />
		</div>
	);
};
export default DepositAccountsTab;
